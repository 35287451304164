import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useDivisionExamData = () => {
  const [examDivisionData, setExamDivisionData] = useState(null); // 문항이 예제인지 본 검사 인지 구별 변수 데이터
  const examData = useSelector((state) => state.mainExamAction.data);

  //데이터를 넣으면 문항이 예제인지 본 검사 인지 구별 함수
  const divisionExamArray = (data) => {
    if (data?.exam) {
      const mainExamData = data.exam;
      return mainExamData;
    }
    if (data?.example) {
      const exampleExamData = data.example;
      return exampleExamData;
    }
  };

  useEffect(() => {
    setExamDivisionData(divisionExamArray(examData));
  }, [examData]);

  return examDivisionData;
};
