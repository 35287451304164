/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import { DotSpinner } from "@uiball/loaders";

export const Loading = ({ size = false }) => {
  const theme = useTheme();
  return (
    <div css={totalWrapper}>
      <DotSpinner size={size ? size : 40} speed={0.9} color={theme.mainColor} />
    </div>
  );
};

const totalWrapper = css`
  padding: 10px 0 30px 0;
`;
