/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import { ModalTemplate } from "../base/ModalTemplate";
import { BasicButton } from "../system/button/BasicButton";
import { useTranslation } from "react-i18next";

export const PretestEndModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { sendToExamTopic } = useRoomConnection();
  const socketData = useSelector((state) => state.socketAction);

  const logout = () => {
    sendToExamTopic(socketData?.mobileSocket?.mobileSocketId, "COMPLETE");
    navigate(`/logout`, { replace: true });
  };

  return (
    <ModalTemplate>
      <div css={pendingText}>
        <strong>
          {t(`modal.pretestEnd.title`)}
          {window.navigator.userAgent.includes("Mac")
            ? `( Cmd+Q )`
            : `( Alt+F4 )`}
        </strong>
        <br />
        {t(`modal.pretestEnd.subTitle`)}
      </div>
      <BasicButton
        wid="s"
        hei="s"
        onClick={logout}
        label={t(`exam.button.confirm`)}
      />
    </ModalTemplate>
  );
};
const pendingText = css`
  text-align: center;
  padding: 40px 0 40px 0;
  white-space: pre;
`;
