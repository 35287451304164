/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "antd";
import i18n from "../../locales/i18n";

export const LanguageSelect = () => {
  const onChangeLang = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <div css={selectWrap}>
      <FontAwesomeIcon
        icon={faEarthAmericas}
        style={{
          marginRight: "5px",
          fontSize: "20px",
        }}
      />
      <Select
        style={{ width: "120px" }}
        defaultValue={"Language"}
        onChange={(e) => onChangeLang(e)}
        options={[
          {
            value: "KOR",
            label: "한국어(KOR)",
          },
          {
            value: "ENG",
            label: "영어(ENG)",
          },
        ]}
      />
    </div>
  );
};

const selectWrap = css`
  position: fixed;
  display: flex;
  align-items: center;
  margin: 10px 60px 0 0;
  top: 0;
  right: 0;
  color: white;
  z-index: 1000;
`;
