import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useTooltip = () => {
  const pretestData = useSelector((state) => state.pretestAction.data);

  const [tooltipArray, setTooltipArray] = useState(["navbar"]);
  const juksungTooltipArray = [
    "page",
    "question",
    "button",
    "nextButton",
    "tool",
  ];
  const insungTooltipArray = ["progressbar", "question", "button"];

  const juksung =
    pretestData?.ipsativeYN === "N" && pretestData?.likertYN === "N";
  const insung =
    pretestData?.likertYN === "Y" || pretestData?.ipsativeYN === "Y";
  const examNo = pretestData?.examNo;

  useEffect(() => {
    //첫번째 검사에 채팅 툴팁 추가
    //navbar tooltip 추가하기
    if (examNo === 1) {
      if (juksung) {
        if (pretestData.prevUseYN === "Y") {
          juksungTooltipArray.splice(3, 1);
        }
        juksungTooltipArray.unshift("navbar");
        juksungTooltipArray.push("chat");
      }
      if (insung) {
        insungTooltipArray.pop();
        insungTooltipArray.unshift("navbar");
        insungTooltipArray.push("button");
      }
    }
    if (juksung) {
      setTooltipArray(juksungTooltipArray);
    }
    if (insung) {
      setTooltipArray(insungTooltipArray);
    }
  }, [pretestData]);

  return { tooltipArray };
};
