import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

const apiClient = axios.create({
  baseURL,
});
export default apiClient;
