import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const examApi = {
  examStart: (pageNo, examSubStatus, data) => {
    return apiClient.post(`${API_URL.EXAM_START}?pageNo=${pageNo}`, {
      examStatus: data.examStatus,
      examIdx: data.examIdx,
      examNo: data.examNo,
      examSubStatus: examSubStatus,
      examSubIdx: data.examSubIdx,
      examSubNo: data.examSubNo,
    });
  },
  examRecord: (recordType, recordUrl) => {
    return apiClient.post(`${API_URL.EXAM_RECORD}`, {
      recordType,
      recordUrl,
    });
  },
  examUploadIdcard: (webcamIdFileUrl) => {
    return apiClient.patch(`${API_URL.EXAM_ID}`, {
      webcamIdFileUrl,
    });
  },
  examBan: (cheatingMemo) => {
    return apiClient.patch(`${API_URL.USER_BAN}`, {
      cheatingMemo,
    });
  },
};
