/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import { getTextColorByBackgroundColor } from "../../../utils/color";

export const ActivationToggleButton = ({ label, onClick, checked }) => {
  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.mainColor);
  return (
    <div css={buttonWrapper({ theme, fontColor })}>
      <button className={checked ? "checked" : "check"} onClick={onClick}>
        {label}
      </button>
    </div>
  );
};

const buttonWrapper = ({ theme, fontColor }) => css`
  width: 100%;
  font-size: 15px;
  display: flex;
  height: 50px;
  .checked {
    width: 100%;
    background-color: ${theme.mainColor ? theme.mainColor : "black"};
    color: ${theme.mainColor ? fontColor : "white"};
  }
  .check {
    background-color: rgba(2, 32, 71, 0.07);
    color: #131313;
    width: 100%;
  }
`;
