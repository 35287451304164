import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const updateApi = {
  updateAgreement: () => {
    return apiClient.patch(`${API_URL.AGREE_DATA}`);
  },
  updateTime: (data) => {
    return apiClient.post(`${API_URL.UPDATE_TIME}`, {
      examIdx: data.examIdx,
      examSubIdx: data.examSubIdx,
      remainingSeconds: parseInt(sessionStorage.getItem("remainingSeconds")),
    });
  },
  updateInsungMark: (data, markInfos) => {
    return apiClient.post(`${API_URL.UPDATE_MARK_INSUNG}`, {
      examIdx: data.testerStatus.examIdx,
      examSubIdx: data.testerStatus.examSubIdx,
      pageNo: data.testerStatus.savePage,
      likertYN: data.exam.likertYN,
      ipsativeYN: data.exam.ipsativeYN,
      questionSetCnt: data.exam.questionSetCnt,
      remainingSeconds: parseInt(sessionStorage.getItem("remainingSeconds")),
      markInfos: markInfos,
    });
  },

  updateJuksungMark: (data, questionMark) => {
    return apiClient.post(`${API_URL.UPDATE_MARK_JUKSUNG}`, {
      examIdx: data.testerStatus.examIdx,
      examSubIdx: data.testerStatus.examSubIdx,
      pageNo: data.testerStatus.savePage,
      likertYN: data.exam.likertYN,
      ipsativeYN: data.exam.ipsativeYN,
      markInfos: [
        {
          questionNo: data.testerStatus.savePage,
          questionMark: questionMark,
        },
      ],
    });
  },

  updateJuksungInterval: (data) => {
    return apiClient.post(`${API_URL.UPDATE_INTERVAL_JUKSUNG}`, {
      examIdx: data.testerStatus.examIdx,
      examSubIdx: data.testerStatus.examSubIdx,
      remainingSeconds: parseInt(sessionStorage.getItem("remainingSeconds")),
      pageNo: data.testerStatus.savePage,
      likertYN: data.exam.likertYN,
      ipsativeYN: data.exam.ipsativeYN,
      markInfos: [
        {
          questionNo: data.testerStatus.savePage,
          questionInterval:
            parseInt(sessionStorage.getItem("startRemainingSeconds")) -
            parseInt(sessionStorage.getItem("remainingSeconds")),
        },
      ],
    });
  },

  updateJuksungReplyAnswer: (data, questionReply) => {
    return apiClient.post(`${API_URL.UPDATE_REPLY_ANSWER_JUKSUNG}`, {
      examIdx: data.testerStatus.examIdx,
      examSubIdx: data.testerStatus.examSubIdx,
      remainingSeconds: parseInt(sessionStorage.getItem("remainingSeconds")),
      pageNo: data.testerStatus.savePage,
      likertYN: data.exam.likertYN,
      ipsativeYN: data.exam.ipsativeYN,
      markInfos: [
        {
          questionNo: data.testerStatus.savePage,
          questionReply: questionReply,
        },
      ],
    });
  },

  updateAiLog: (data) => {
    return apiClient.post(`${API_URL.AI_LOG}`, {
      aiTitle: data.aiTitle,
      aiMessage: data.aiMessage,
      captureURL: data.captureURL,
      examIdx: data.examIdx,
      examNo: data.examNo,
      examSubNo: data.examSubNo,
      examSubIdx: data.examSubIdx,
      pageNo: data.pageNo,
      remainingSeconds: parseInt(sessionStorage.getItem("remainingSeconds")),
      testerIdx: data.testerIdx,
    });
  },
};
