/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { ModalTemplate } from "../base/ModalTemplate";

export const ExampleModal = ({ setModal, exImage, exTitle }) => {
  return (
    <div css={totalWrapper}>
      <ModalTemplate setModal={setModal} closeBtn={true} modalSize="large">
        <div className="example-title">{exTitle}</div>
        <div className="example">
          <img src={exImage} alt="" />
        </div>
      </ModalTemplate>
    </div>
  );
};

const totalWrapper = css`
  width: 100%;
  .example-title {
    font-size: 25px;
    display: flex;
    font-weight: 700;
    padding-bottom: 20px;
  }
  .example {
    width: 700px;
    max-height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10px;
    overflow: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;
