/** 네트워크 속도 체크 ,출력 결과 'slow-2g', '2g', '3g', or '4g'. */
export const getNetworkSpeed = () => {
  return navigator.connection ? navigator.connection : { effectiveType: "-" };
};

//LTE속도가 아니고 다운링크가 3mb미만이면
export const checkNetworkDownlink = () => {
  const networkInfo = getNetworkSpeed();
  if (networkInfo?.effectiveType !== "4g" && networkInfo.downlink < 3) {
    return false;
  } else {
    return true;
  }
};
