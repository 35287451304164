/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useState, useRef, useEffect } from "react";
import { Drawing } from "./Drawing";
import { Memo } from "./Memo";
import { useSelector } from "react-redux";
import { useNowExamData } from "../../hooks/useNowExamData";
import { Calculator } from "./calc/Calculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
export const DrawingMemoCalc = () => {
  const { t } = useTranslation();

  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const [memo, setMemo] = useState(null);
  const [isMemoOpen, setMemoOpen] = useState(true);
  const [isCalcActive, setCalcActive] = useState(false);

  const nowExamData = useNowExamData();
  const savePage = nowExamData?.testerStatus?.savePage;

  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const { completer } = useSelector((state) => state.userAction);

  const examStatus = useSelector((state) => state?.mainExamAction.data);
  const testerStatus = examStatus?.testerStatus;
  const memoUseYN = testerStatus?.memoUseYN;

  const memoKey = `${nowExamData?.exam ? "main-memo" : "example-memo"}-${
    completer ? nowExamData?.testerStatus?.examSubNo : nowExamData?.examSubNo
  }-${
    completer
      ? nowExamData?.exam
        ? nowExamData?.testerStatus?.savePage
        : exampleIndex
      : nowExamData.pageNo
  }`;

  //드로잉 지우기
  const delDrawing = () => {
    sessionStorage.removeItem(
      `Drawing-${
        completer ? nowExamData?.testerStatus?.examNo : nowExamData?.examNo
      }-${
        completer
          ? nowExamData?.testerStatus?.examSubNo
          : nowExamData?.examSubNo
      }-${
        completer
          ? nowExamData?.example
            ? exampleIndex
            : nowExamData?.testerStatus?.savePage
          : nowExamData?.pageNo
      }`
    );
    contextRef.current.fillStyle = "#ffffff";
    contextRef.current.fillRect(0, 0, 300, 300);
  };

  //메모지우기
  const delMemo = () => {
    sessionStorage.removeItem(memoKey);
    setMemo(null);
  };

  //questionMemo가 있을 경우 보여주기
  useEffect(() => {
    setMemo(nowExamData?.exam?.questionMemo);
  }, [nowExamData]);

  //과목이 바뀔 경우 메모가 먼저 열려있게 만들기
  useEffect(() => {
    setMemoOpen(true);
  }, [nowExamData?.testerStatus?.examSubStatus]);

  useEffect(() => {
    if (sessionStorage.getItem(memoKey)) {
      setMemo(sessionStorage.getItem(memoKey));
    } else {
      setMemo("");
    }
  }, [
    isMemoOpen,
    savePage,
    exampleIndex,
    nowExamData,
    nowExamData.examSubNo,
    nowExamData.pageNo,
    memoUseYN,
  ]);
  return (
    <div css={utilsWrapper}>
      <div>
        <div css={memoDrawingWrap}>
          <div
            className={
              nowExamData?.testerStatus?.memoUseYN === "N" ||
              nowExamData?.memoUseYN === "N"
                ? "lock"
                : "use"
            }
          ></div>
          <div className="title_wrap">
            <div className="right" onClick={() => setMemoOpen((prev) => !prev)}>
              <div className={"title" + (isMemoOpen ? " now" : " default")}>
                {t(`exam.juksung.utils.memo`)}
              </div>
              <div className={"title" + (isMemoOpen ? " default" : " now")}>
                {t(`exam.juksung.utils.draw`)}
              </div>
            </div>
            <div className="clear" onClick={isMemoOpen ? delMemo : delDrawing}>
              {t(`exam.juksung.utils.remove`)}
            </div>
          </div>
          <div
            className={
              nowExamData?.testerStatus?.memoUseYN === "N" ||
              nowExamData?.memoUseYN === "N"
                ? "lockicon"
                : "unlock"
            }
          >
            <FontAwesomeIcon icon={faLock} />
          </div>
          <Memo
            isMemoOpen={isMemoOpen}
            isCalcActive={isCalcActive}
            setCalcActive={setCalcActive}
            setMemo={setMemo}
            memo={memo}
          />
          <Drawing
            canvasRef={canvasRef}
            contextRef={contextRef}
            isMemoOpen={isMemoOpen}
          />
        </div>
        <div css={calcWrap}>
          <div
            className={
              nowExamData?.testerStatus?.calculatorUseYN === "N" ||
              nowExamData?.calculatorUseYN === "N"
                ? "lock"
                : "use"
            }
          ></div>
          <div className="title_wrap">
            <div className="calc-title"> {t(`exam.juksung.utils.calc`)}</div>
          </div>
          <div
            className={
              nowExamData?.testerStatus?.calculatorUseYN === "N" ||
              nowExamData?.calculatorUseYN === "N"
                ? "lockicon"
                : "unlock"
            }
          >
            <FontAwesomeIcon icon={faLock} />
          </div>
          <Calculator
            isCalcActive={isCalcActive}
            setCalcActive={setCalcActive}
          />
        </div>
      </div>
    </div>
  );
};
const calcWrap = css`
  width: 300px;
  height: 310px;
  background-color: #ddd;
  position: relative;
  .lock {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .use {
    display: none;
  }
  .lockicon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: 120px;
    opacity: 0.15;
  }
  .unlock {
    display: none;
  }
`;
const memoDrawingWrap = css`
  min-height: 340px;
  max-height: 340px;
  background-color: #ddd;
  position: relative;
  .lock {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .use {
    display: none;
  }
  .lockicon {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: 120px;
    opacity: 0.15;
    padding-bottom: 90px;
  }
  .unlock {
    display: none;
  }
`;

const utilsWrapper = css`
  margin-top: 20px;
  border-radius: 8px;
  position: relative;
  .wrap {
    width: calc(100% - 12px);
    height: 100%;
  }
  .title_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  .right {
    display: flex;
  }
  .title {
    margin: 20px 0px 0 16px;
    font-size: large;
    cursor: pointer;
  }
  .calc-title {
    margin: 7px 0px 0 16px;
    font-size: large;
    position: absolute;
    top: -4px;
    z-index: 1;
  }

  .default {
    color: #aaaaaa;
  }

  .now {
    font-weight: medium;
    text-decoration: underline;
    text-underline-position: under;
  }

  .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 12px 0 0px;
    width: 60px;
    height: 30px;
    background-color: #eee;
    text-align: center;
    padding: 4px 8px;
    line-height: 24px;
    font-size: 14px;
    border-radius: 4px;
  }

  .memo {
    width: 100%;
    border: 1px solid gray;
    padding: 10px;
    line-height: 1.3;
    transition: height 0.3s ease-in-out;
    word-break: break-all;
    border-radius: 3px;
    resize: none;
  }

  .drawing {
    width: 100%;
    border: 1px solid gray;
    transition: height 0.3s ease-in-out;
    border-radius: 3px;
  }
`;
