/**
* 나의 접속타입(MANAGER / TESTER / OPERATOR 등...)
* const MY_PEER_TYPE = 'MANAGER';

* 룸ID = 사이트ID-룸IDX
* const MY_ROOM_ID = SITE_ID + '-' + ROOM_IDX;

* 내 화상ID = 사이트ID-룸IDX-MANAGER
* const MY_PEER_ID = SITE_ID + '-' + ROOM_IDX + '-' + MY_PEER_TYPE;

 * SOCKET EVENT CONSTANTS
 * @description Event constants, used to identify socket events.
 * @constant
 * @property {string} JOIN_ROOM - 룸 접속(전송)
 * @property {string} USER_CONNECTED - 룸 접속(수신)
 * @property {string} USER_DISCONNECT - 접속 끊기-수동(전송)
 * @property {string} USER_DISCONNECTED - 접속 끊기-자동 또는 수동(수신)
 * @property {string} SEND_ID - 나의 ID(전송)
 * @property {string} USER_ID - 나의 ID(수신)
 * @property {string} VIDEO_CALL - 상대방이 나에게(응시자가 감독관에게) 접속(전송)
 * @property {string} VIDEO_CALLED - 상대방이 나에게(응시자가 감독관에게) 접속(수신)
 * @property {string} CANDIDATE - 상대방과의 webRTC 연결루트 설정(전송)
 * @property {string} USER_CANDIDATE -상대방과의 webRTC 연결루트 설정(수신)
 * @property {string} OFFER - 내 영상 전송(전송)
 * @property {string} USER_OFFER - 내 영상 전송(수신)
 * @property {string} ANSWER - 영상 수신에 대한 내 영상 응답(전송)
 * @property {string} USER_ANSWER - 영상 수신에 대한 내 영상 응답(수신)
 * @property {string} VIDEO_QUALITY - 영상 위치/화질 변경(0~10)(전송)
 * @property {string} USER_VIDEO - 영상 위치/화질 변경(0~10)(수신)
 * @property {string} VIDEO_AVATAR - 영상 아바타 대체 요청 ON/OFF(전송)
 * @property {string} USER_AVATAR - 영상 아바타 대체 요청 ON/OFF(수신)
 * @property {string} VIDEO_SOUND - 스피커 활성화 요청ON/OFF(전송)
 * @property {string} USER_SOUND - 스피커 활성화 요청ON/OFF(수신)
 * @property {string} CHAT - 채팅 메시지(전송)
 * @property {string} USER_CHAT - 채팅 수신시
 * @property {string} CHAT_READ -채팅 메시지 읽음(전송)
 * @property {string} USER_CHAT_READ -채팅 메시지 읽음(수신)
 * @property {string} VOICE_CHAT - 음성대화 ON/OFF(쩐송)
 * @property {string} USER_VOICE_CHAT - 음성대화 ON/OFF(수신)
 * @property {string} VIDEO_REFRESH - 화상 재연결(전송)
 * @property {string} USER_REFRESH - 화상 재연결(수신)
 * @property {string} ID_CARD - 신분증 등록/승인/반려(전송)
 * @property {string} USER_ID_CARD - 신분증 등록/승인/반려(수신)
 * @property {string} EXAM - 검사 시작/중지 PLAY/PAUSE/COMPLETE/자진퇴장(전송)
 * @property {string} USER_EXAM - 검사 시작/중지(수신)
 * @property {string} WAITING - 퇴장 가능여부 Y/N (전송)
 * @property {string} USER_WAITING - 퇴장 가능여부 Y/N (수신)
 * @property {string} KICK - 강제퇴장 사유와 함께(전송)
 * @property {string} USER_KICK - 강제퇴장 사유와 함께(수신)
 */

export const SOCKET_EVENT = {
  JOIN_ROOM: "join-room", //emit
  USER_CONNECTED: "user-connected", //on

  SEND_ID: "send-id", //emit
  USER_ID: "user-id", //on

  EXAM: "exam", //emit
  USER_EXAM: "user-exam", //on

  CHAT: "chat", //emit
  USER_CHAT: "user-chat", //on

  CHAT_READ: "chat-read", //emit
  USER_CHAT_READ: "user-chat-read", //on

  VOICE_CHAT: "voice-chat", //emit
  USER_VOICE_CHAT: "user-voice-chat", //on

  CANDIDATE: "candidate", //emit
  USER_CANDIDATE: "user-candidate", //on

  OFFER: "offer", //emit
  USER_OFFER: "user-offer", //on

  ANSWER: "answer", //emit
  USER_ANSWER: "user-answer", //on

  VIDEO_REFRESH: "video-refresh", //emit
  USER_REFRESH: "user-refresh", //on

  USER_DISCONNECT: "user-disconnect", //emit
  USER_DISCONNECTED: "user-disconnected", //on

  VIDEO_CALL: "video-call", //emit
  VIDEO_CALLED: "video-called", //on

  VIDEO_QUALITY: "video-quality", //emit
  USER_VIDEO: "user-video", //on

  VIDEO_AVATAR: "video-avatar", //emit
  USER_AVATAR: "user-avatar", //on

  VIDEO_SOUND: "video-sound", //emit
  USER_SOUND: "user-sound", //on

  WAITING: "waiting", //emit
  USER_WAITING: "user-waiting", //on

  KICK: "kick", //emit
  USER_KICK: "user-kick", //on

  ID_CARD: "idcard", //emit
  USER_ID_CARD: "user-idcard",
};
