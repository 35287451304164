/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { useDispatch } from "react-redux";
import { TIME_STOP } from "../../_reducers/timeAction";
import { useLogout } from "../../hooks/useLogout";
import { chatMessage } from "../../adapters/chatMessage";
import { PendingTemplate } from "../base/PendingTemplate";
import { useRecord } from "../../hooks/useRecord";
import { useTranslation } from "react-i18next";

export const PendingDuplicateLogin = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { sendToChat } = chatMessage();
  const { stopRecordHandler } = useRecord();

  const [count, setCount] = useState(10);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    dispatch({ type: TIME_STOP, data: true });
    sendToChat(
      `<strong>[알림]\n 중복 로그인을 시도한 내용이 확인되었습니다.
    일시 정지 후 신분 확인 절차를 다시 진행해 주십시오.</strong>`,
      "chat"
    );
  }, []);

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
        logout();
        stopRecordHandler();
      }
    },
    isRunning ? 1000 : null
  );

  return (
    <>
      {isRunning && (
        <PendingTemplate
          title={t(`pending.duplicateLogin.title`)}
          loading={true}
        >
          <div style={{ whiteSpace: "pre", paddingTop: "30px" }}>
            {t(`pending.duplicateLogin.text`)}
          </div>
        </PendingTemplate>
      )}
    </>
  );
};
