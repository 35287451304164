/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { ModalTemplate } from "../../base/ModalTemplate";
import { useDispatch } from "react-redux";
import { ActivationToggleButton } from "../../system/button/ActivationToggleButton";
import { FaqListItem } from "./FaqListItem";
import { dataApi } from "../../../api/data/dataApi";
import i18n from "../../../locales/i18n";
import { useTranslation } from "react-i18next";

export const FaqModal = ({ setFaqModal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch;
  const [category, setCategory] = useState(null);
  const [checked, setChecked] = useState(null);
  const [faqData, setFaqData] = useState(null);

  const checkedFaqListItem = (i) => {
    if (checked === i) {
      setChecked(null);
    } else {
      setChecked(i);
    }
  };

  useEffect(() => {
    dataApi.faqData(i18n.language).then((response) => {
      setFaqData(response.data.data);
    });
  }, [dispatch, i18n.language]);

  return (
    <ModalTemplate
      setModal={setFaqModal}
      closeBtn={true}
      isTest={false}
      title={"FAQ"}
    >
      <div css={menu}>
        <ActivationToggleButton
          label={t(`faq.all`)}
          onClick={() => setCategory(null)}
          checked={category === null}
        />
        {faqData?.map((a, i) => (
          <ActivationToggleButton
            key={i}
            label={a.category}
            onClick={() => {
              setCategory(i);
              setChecked(null);
            }}
            checked={category === i}
          />
        ))}
      </div>

      <div css={content}>
        {category === null ? (
          <>
            {faqData?.map((data, i) => (
              <React.Fragment key={i}>
                <div className="category-title">
                  <span>{data.category}</span>
                </div>
                {data.faqList.map((item, i) => (
                  <FaqListItem
                    key={i}
                    checked={checked === item.question}
                    faqListItem={item}
                    onClick={() => checkedFaqListItem(item.question)}
                  />
                ))}
              </React.Fragment>
            ))}
          </>
        ) : (
          <div style={{ paddingTop: "20px" }}>
            {faqData[category]?.faqList.map((item, i) => (
              <React.Fragment key={i}>
                <FaqListItem
                  checked={checked === item.question}
                  faqListItem={item}
                  onClick={() => checkedFaqListItem(item.question)}
                />
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

const menu = () => css`
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
`;
const content = () => css`
  width: 700px;
  height: 400px;
  overflow: auto;
  .category-title {
    font-size: 18px;
    font-weight: 600;
    color: #131313;
    padding-left: 5px;
    padding-top: 25px;
    text-align: left;
    padding-bottom: 10px;
    span {
      background-color: rgba(2, 32, 71, 0.05);
      border-radius: 6px;
      padding: 5px 15px 5px 15px;
    }
  }
`;
