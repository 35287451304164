import produce from "immer";

export const SET_THEME = "SET_THEME";

const initialState = {
  theme: {
    layoutIdx: 1,
    ciUseYN: "N",
    ciFilePath: "",
    mainColor: "#3B3B3B",
    subColor: "#D2D3D4",
    buttonColor: "#3B3B3B",
    frameColor: "rgba(255,255,255,0.4)",
    titleText: "",
    titleColor: null,
    bgImageUseYN: "Y",
    bgColor: "",
    fontColor: "#ffffff",
  },
  setthemeLoading: false,
  setthemeDone: false,
  setthemeError: null,
  themeShadow: null,
};

const themeReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_THEME:
        draft.theme = action.data;
        break;
      default:
        break;
    }
  });
};

export default themeReducer;
