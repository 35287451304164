import { all, fork, put, takeLatest, call, delay } from "redux-saga/effects";
import {
  SET_PRETEST_REQUEST,
  SET_PRETEST_SUCCESS,
  SET_PRETEST_FAILURE,
} from "../_reducers/pretestAction";
import { API_URL } from "../data/api";
import { takeLeading } from "redux-saga/effects";
import apiClient from "../api/apiClient";

function pretestAPI(data) {
  return apiClient.post(`${API_URL.PRACTICE_DATA}`, data);
}

function* pretest(action) {
  try {
    const result = yield call(pretestAPI, action.data);
    yield put({
      type: SET_PRETEST_SUCCESS,
      data: result.data.data,
    });
  } catch (err) {
    yield put({
      type: SET_PRETEST_FAILURE,
      error: err.response.data,
    });
  }
}

function* watchPretest() {
  yield takeLeading(SET_PRETEST_REQUEST, pretest);
}

export default function* pretestSaga() {
  yield all([fork(watchPretest)]);
}
