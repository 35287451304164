import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNowExamData } from "./useNowExamData";
import { useTranslation } from "react-i18next";

export const useTitle = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const [navTitle, setNavTitle] = useState("");
  const nowExamData = useNowExamData();
  const { completer } = useSelector((state) => state.userAction); //사전 점검 완료자 인지 아니니지
  const preTestData = useSelector((state) => state.pretestAction.data);
  const url = location.pathname;

  /** 과목 타이틀을 구하는 함수*/
  const getQuestionTitle = (examData, url) => {
    //진행중
    if (url === "/test/maintest") {
      if (examData?.exam) {
        return examData?.exam?.examSubName;
      } else if (examData?.example) {
        return `${t(`title.example`)} - ${examData?.example?.examSubName}`;
      }
    } else if (url === "/test/pretest") {
      return `${t(`title.questionCheck`)} - ${preTestData?.examSubName}`;
    } else return null;
  };

  const testTitle = getQuestionTitle(nowExamData, url);

  useEffect(() => {
    if (url === "/test/info") {
      setNavTitle(t(`title.agree`));
    } else if (url === "/test/device" || url === "/test/device/mobile") {
      setNavTitle(t(`title.device`));
    } else if (url === "/test/idcard") {
      setNavTitle(t(`title.uploadId`));
    } else if (url === "/test/snapshot" || url === "/test/pretest/idcard") {
      setNavTitle(t(`title.identification`));
    } else if (url === "/test/timetable") {
      setNavTitle(t(`title.timeTable`));
    } else setNavTitle(testTitle);
  }, [completer, location, testTitle, t, preTestData]);

  return { navTitle };
};
