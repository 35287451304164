import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  userSocket: null,
  viewerSocket: null,
  mobileSocket: null,
};

export const SOCKET_USER_INFO = "SOCKET_USER_INFO";
export const SOCKET_VIEWER_INFO = "SOCKET_VIEWER_INFO";
export const SOCKET_PEER_CONNECTIONS = "SOCKET_PEER_CONNECTIONS";
export const SOCKET_MOBILE_CONNECTIONS = "SOCKET_MOBILE_CONNECTIONS";

const socketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SOCKET_USER_INFO:
        draft.userSocket = action.data;
        break;
      case SOCKET_VIEWER_INFO:
        draft.viewerSocket = action.data;
        break;
      case SOCKET_MOBILE_CONNECTIONS:
        draft.mobileSocket = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        return;
    }
  });

export default socketReducer;
