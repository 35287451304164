/** @jsxImportSource @emotion/react */
import { QuestionCircleOutlined } from "@ant-design/icons";
import { css, jsx } from "@emotion/react";
import i18n from "../../../locales/i18n";

export const QuestionTooltipIcon = ({ ...rest }) => {
  return (
    <div css={questionCircle} {...rest}>
      <QuestionCircleOutlined />
      {i18n.language === "KOR" ? "기능 설명" : "Explanation"}
    </div>
  );
};
const questionCircle = css`
  position: relative;
  width: 95px;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;
  text-decoration: underline;
  color: #777;
  svg {
    display: flex;
    margin-right: 5px;
  }
  :hover {
    color: #131313;
  }
`;
