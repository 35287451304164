/** @jsxImportSource @emotion/react */
import { jsx, ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Global } from "@emotion/react";
import { reset } from "./styles/reset";
import { useSelector } from "react-redux";
import {
  EndPage,
  LoginPage,
  MobileConnectPage,
  TotalTestPage,
} from "./pages/index";
import { MobileDisconnectPage } from "./pages/mobile/MobildDisconnectPage";
import { PendingDualMonitor } from "./components/pending/PendingDualMonitor";
import { useAxiosInterceptor } from "./hooks/useAxiosInterceptor";
import { Pending404 } from "./components/pending/Pending404";

const App = () => {
  const theme = useSelector((state) => state.themeAction.theme);

  useAxiosInterceptor();
  return (
    <BrowserRouter>
      <Global styles={reset} />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="cheat" element={<PendingDualMonitor />} />
          <Route path="/logout" element={<EndPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/mobile/device" element={<MobileConnectPage />} />
          <Route
            path="/mobile/disconnect/*"
            element={<MobileDisconnectPage />}
          />
          <Route path="/test/*" element={<TotalTestPage />} />
          <Route path="*" element={<Pending404 />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
