/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PendingTemplate } from "../base/PendingTemplate";

export const Pending404 = () => {
  return (
    <PendingTemplate>
      <img css={imageWrap} src={"/images/404.svg"} alt="" />
    </PendingTemplate>
  );
};

const imageWrap = () => css`
  @media (max-width: 640px) {
    width: 100%;
  }
`;
