import { useDispatch, useSelector } from "react-redux";
import { ALERT_MODAL_ON } from "../_reducers/modalAction";
import { useTranslation } from "react-i18next";

export const useCreateMediaStream = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //디바이스 정보 가져오기
  const { video, audio, videoStream, screenStream } = useSelector(
    (state) => state.deviceAction
  );

  const getAudio = (audio, callback) => {
    const constraints = {
      audio: {
        deviceId: audio ? { exact: audio } : true,
      },
      video: false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(callback)
      .catch((err) => {
        if (err.name === "OverconstrainedError") {
          dispatch({
            type: ALERT_MODAL_ON,
            data: "선택한 기기를 찾을 수 없습니다.",
          });
        } else {
          dispatch({
            type: ALERT_MODAL_ON,
            data: err.name,
          });
        }
      });
  };

  //웹캠로드 함수
  const getWebcam = (callback) => {
    if (videoStream?.id) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    const initialConstraints = {
      audio: false,
      video: true,
      facingMode: { exact: "user" },
    };

    const constraints = {
      audio: {
        deviceId: audio,
      },
      video: {
        deviceId: video,
        width: 320,
        height: 240,
        frameRate: { max: 4 },
      },
      facingMode: { exact: "user" },
    };
    try {
      navigator.mediaDevices
        .getUserMedia(video ? constraints : initialConstraints)
        .then(callback);
    } catch (err) {
      alert(
        "웹캠을 불러오지 못 하였습니다. 다른 장치에서 사용중이 아닌지 확인해 주세요."
      );
      console.log(err);
      return undefined;
    }
  };

  const getScreen = (callback) => {
    if (screenStream?.id) {
      screenStream?.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      video: {
        width: { max: 1280 },
        height: { max: 720 },
        frameRate: { max: 4 },
        cursor: "motion",
        displaySurface: "monitor",
      },
      audio: false,
    };
    try {
      navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then(callback)
        .catch((error) => {
          dispatch({
            type: ALERT_MODAL_ON,
            data: t(`error.device.screen.getScreenError`),
          });
        });
    } catch (err) {
      alert("화면 공유에 실패하였습니다.");
      console.log("화면 공유에 실패하였습니다." + err);
    }
  };

  return {
    getWebcam,
    getScreen,
    getAudio,
  };
};
