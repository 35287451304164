/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { BasicButton } from "../../components/system/button/BasicButton";
import { useLogout } from "../../hooks/useLogout";
import { UPDATE_USER_STATE } from "../../_reducers/examUserDataAction";
import { useRecord } from "../../hooks/useRecord";
import { useTranslation } from "react-i18next";
import { MAIN_EXAM_REQUEST } from "../../_reducers/mainExamAction";
import { examApi } from "../../api/exam/examApi";
import { EndSteps } from "../system/EndSteps";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import { Loading } from "../modal/Loading";

export const PendingExamEnd = () => {
  const { t } = useTranslation();
  const { sendToExamTopic } = useRoomConnection();
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const {
    stopRecordHandler,
    loading: recordingLoading,
    setLoading,
  } = useRecord();
  const [count, setCount] = useState(10);
  const [isRunning, setIsRunning] = useState(true);
  const examData = useSelector((state) => state.mainExamAction.data);
  const [isExamEndModal, setExamEndModal] = useState(false);
  const [isRecordingCompleteModal, setRecordingCompleteModal] = useState(false);
  const socketData = useSelector((state) => state.socketAction);
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      label: t(`pending.examEnd.steps.1`),
      step: 1,
      status: "waiting",
    },
    {
      label: t(`pending.examEnd.steps.2`),
      step: 2,
      status: "waiting",
    },
    {
      label: t(`pending.examEnd.steps.3`),
      step: 3,
      status: "waiting",
    },
  ]);

  const updateStepsStatus = (index, status) => {
    setLoading(true);
    let tmpArray = [...steps];
    tmpArray[index].status = status;
    setSteps(tmpArray);
  };
  const sendExamEndApi = async () => {
    setExamEndModal(true);

    await examApi
      .examStart(examData?.testerStatus?.savePage, 2, examData?.testerStatus)
      .then((response) => {
        setTimeout(() => {
          setExamEndModal(false);
          setActiveStep(2);
          updateStepsStatus(0, "complete");
          sendRecordApi();
        }, [3000]);
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: response.data.data,
        });
        //검사 종료 시 응시자 상태 업데이트
        dispatch({
          type: UPDATE_USER_STATE,
          data: 2,
        });
      })
      .catch(() => {
        setTimeout(() => {
          setExamEndModal(false);
          setActiveStep(2);
          updateStepsStatus(0, "complete");
          sendRecordApi();
        }, [3000]);
        dispatch({
          type: UPDATE_USER_STATE,
          data: 2,
        });
      });
  };
  const sendRecordApi = async () => {
    setRecordingCompleteModal(true);
    stopRecordHandler(true);
  };

  useEffect(() => {
    const handleRecordingComplete = (status) => {
      setTimeout(() => {
        setRecordingCompleteModal(false);
        setActiveStep(3);
        updateStepsStatus(1, status);
      }, 3000);
    };

    if (!isExamEndModal && isRecordingCompleteModal) {
      if (!recordingLoading || recordingLoading === "error") {
        handleRecordingComplete("complete");
      }
    }
  }, [isExamEndModal, isRecordingCompleteModal, recordingLoading]);

  useEffect(() => {
    sendExamEndApi();
  }, []);

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
      }
    },
    isRunning ? 1000 : null
  );

  const renderEndPending = () => {
    if (!isExamEndModal && !isRecordingCompleteModal) {
      return t(`pending.examEnd.endText`);
    } else {
      return (
        t(`pending.examEnd.title`) + "\n" + t(`pending.examEnd.waitingText`)
      );
    }
  };

  return (
    <div css={totalWrapper}>
      <div className="text">{renderEndPending()}</div>
      <EndSteps activeStep={activeStep} steps={steps} />
      <div className="loading-wrap">
        {!isExamEndModal && !isRecordingCompleteModal ? (
          <BasicButton
            wid="s"
            hei="s"
            onClick={() => {
              sendToExamTopic(
                socketData?.viewerSocket?.viewerSocketId,
                "COMPLETE"
              );
              logout();
            }}
            label={t(`exam.button.end`)}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const totalWrapper = () => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.4;
  user-select: none;
  z-index: 999;
  position: absolute;
  background-color: #fff;
  white-space: pre-line;
  text-align: center;
  .loading-wrap {
    height: 80px;
  }
  .text {
    padding-top: 80px;
    font-weight: bold;
    font-size: 32px;
  }
  .loading-wrapper {
    padding-top: 60px;
  }
`;
