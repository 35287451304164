const API_URL = {
  FAQ_DATA: `/ask/faq`,
  QNA_DATA: `/ask/qna/testers`,
  LAYOUT_DATA: `/testers/layout`,
  LOGIN_PHONE_NUM: `/testers/login/send-code`,
  COMPANIES_LIST: `/testers/companies`,
  LOGIN_CONFIRM_NUM: `/testers/login`,
  AGREE_DATA: `/testers/agreement`,
  PRACTICE_DATA: `/practice`,
  PRACTICE_ID: `/practice/id`,
  PRACTICE_END_DATA: `/practice/end`,
  PRACTICE_SCREEN: `/practice/screen`,
  EXAM_USER_DATA: `/testers/exams`,
  EXAM_ID: `/testers/exams/id`,
  EXAM_START: `/testers/exams/start`,
  EXAM_CURRENT_TIME: `/testers/exams/time`,
  USER_BAN: `/testers/exams/ban`,
  UPDATE_MARK_JUKSUNG: `/testers/exams/update?updateType=mark`,
  UPDATE_REPLY_ANSWER_JUKSUNG: `/testers/exams/update?updateType=reply`,
  UPDATE_REPLY_INTERVAL_JUKSUNG: `/testers/exams/update?updateType=replyInterval`,
  UPDATE_INTERVAL_JUKSUNG: `/testers/exams/update?updateType=interval`,
  UPDATE_MARK_INSUNG: `/testers/exams/update`,
  UPDATE_TIME: `/testers/exams/update?updateType=time`,
  CHAT_TESTER: `/chat/tester/`,
  EXAM_RECORD: `/testers/exams/records`,
  AI_LOG: `/ai-log`,
  AWS_PRESIGNED: `/aws/pre-signed-url`,
};

export { API_URL };
