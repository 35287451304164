/** @jsxImportSource @emotion/react */
import { css, useTheme, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import { getTextColorByBackgroundColor } from "../../../utils/color";
import { getInsungPart } from "../../../utils/getInsungPart";
import { useTranslation } from "react-i18next";

export const InsungHeader = ({ examData }) => {
  const { t } = useTranslation();

  const part3 = examData?.likertYN === "N" && examData?.ipsativeYN === "Y";
  const [likertExArray, setLikertExArray] = useState([]);

  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.subColor);

  /** 스페이스바를 기준으로 줄바꿈 추가 */
  const getLikertExArray = () => {
    const tmp = [];
    examData?.likertEx?.map((value, i) => {
      let replaceBr = value?.questionExText.replace(/ /g, `\n`);
      tmp.push(replaceBr);
    });
    return tmp;
  };

  const insungPart = getInsungPart(examData);

  useEffect(() => {
    const array = getLikertExArray();
    setLikertExArray(array);
  }, [examData]);

  return (
    <div css={totalWrapper({ part3, theme, fontColor })}>
      <div className="insung-header-question">
        {t(`exam.insung.header.문항`)}
      </div>
      {examData?.likertYN === "Y" && (
        <div className="insung-header-likertEx">
          {insungPart === "part1" && (
            <div className="insung-header-contents-div-top">
              {t(`exam.insung.header.응답1`)}
            </div>
          )}
          <div className="insung-header-contents">
            {likertExArray?.map((value, i) => (
              <div key={i} className="insung-header-contents-div">
                {value}
              </div>
            ))}
          </div>
        </div>
      )}

      {examData?.ipsativeYN === "Y" ? (
        <div className="insung-header-ipsativeYN">
          {insungPart === "part1" && (
            <div className="insung-header-contents-div-top">
              {t(`exam.insung.header.응답2`)}
            </div>
          )}
          <div className="insung-header-contents">
            <div className="insung-header-contents-div">
              {t(`exam.insung.header.멀다`)}
            </div>
            <div className="insung-header-contents-div">
              {t(`exam.insung.header.가깝다`)}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const totalWrapper = ({ part3 }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  border-bottom: 2px solid #3b3b3b;
  font-weight: 500;
  font-size: 18px;
  .insung-header-question {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #ececec;
    font-weight: 500;
  }
  .insung-header-likertEx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    border-left: 1px solid #3b3b3b;
    &:not():last-child {
      border-right: 1px solid #3b3b3b;
    }
  }
  .insung-header-ipsativeYN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${part3 ? "30%" : "100px"};
    border-left: 1px solid #3b3b3b;
  }
  .insung-header-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    /* border-top: 2px solid black; */
    background-color: #ececec;
    font-size: 14px;
    white-space: pre-line;
  }
  .insung-header-contents-div {
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .insung-header-contents-div-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 100%;
    background-color: #d2d3d4;
    font-size: 18px;
    font-weight: 500;
  }
`;
