import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  /** 감독관이 확인 중 입니다 모달*/
  pendingModal: false,
  pendingMainExamEnd: false,
  pendingPretestExamEnd: false,

  /** 강제 퇴장 모달 */
  kickModal: false,
  kickReason: null,

  /** 알림 모달*/
  alertModal: false,

  /** 에러 모달 */
  errorModal: false,

  /** 에러/알림 시 모달에 보여줄 메세지 */
  validationMessage: null,
  alertMessage: null,

  /** 중복 로그인 발생 시 모달 */
  duplicateLoginModal: false,

  //퇴장 대기 모달
  waitingModal: false,

  //모바일 연결이 끊겼을 때
  disconnectMobileModal: false,

  //네트워크 연결이 끊겼을 때 모달
  disconnectNetworkModal: false,

  //웹캠 연결이 끊겼을 때 모달
  disconnectWebcamModal: false,
};

export const MAIN_EXAM_END_MODAL_ON = "MAIN_EXAM_END_MODAL_ON";
export const PRETEST_EXAM_END_MODAL_ON = "PRETEST_EXAM_END_MODAL_ON";

export const PENDING_MODAL_ON = "PENDING_MODAL_ON";
export const PENDING_MODAL_OFF = "PENDING_MODAL_OFF";

export const KICK_MODAL_ON = "KICK_MODAL_ON";

export const ERROR_MODAL_ON = "ERROR_MODAL_ON";
export const ERROR_MODAL_OFF = "ERROR_MODAL_OFF";

export const ALERT_MODAL_ON = "ALERT_MODAL_ON";
export const ALERT_MODAL_OFF = "ALERT_MODAL_OFF";

export const WAITING_MODAL = "WAITING_MODAL";
export const DUPLICATE_MODAL = "DUPLICATE_MODAL";

export const DISCONNECT_MOBILE_MODAL = "DISCONNECT_MOBILE_MODAL";
export const DISCONNECT_NETWORK_MODAL = "DISCONNECT_NETWORK_MODAL";
export const DISCONNECT_WEBCAM_MODAL = "DISCONNECT_WEBCAM_MODAL";

export const SEND_CAM_RECORD_MODAL = "SEND_CAM_RECORD_MODAL";

const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PENDING_MODAL_ON:
        draft.pendingModal = true;
        break;
      case PENDING_MODAL_OFF:
        draft.pendingModal = false;
        break;
      case KICK_MODAL_ON:
        draft.kickModal = true;
        draft.kickReason = action.msg;
        break;
      case ERROR_MODAL_ON:
        draft.errorModal = true;
        draft.validationMessage = action.data;
        break;
      case ALERT_MODAL_ON:
        draft.alertModal = true;
        draft.alertMessage = action.data;
        break;
      case ALERT_MODAL_OFF:
        draft.alertModal = false;
        break;
      case ERROR_MODAL_OFF:
        draft.errorModal = false;
        break;
      case MAIN_EXAM_END_MODAL_ON:
        draft.pendingMainExamEnd = true;
        break;
      case PRETEST_EXAM_END_MODAL_ON:
        draft.pendingPretestExamEnd = true;
        break;
      case WAITING_MODAL:
        draft.waitingModal = action.data;
        break;
      case DUPLICATE_MODAL:
        draft.duplicateLoginModal = true;
        break;
      case DISCONNECT_MOBILE_MODAL:
        draft.disconnectMobileModal = action.data;
        break;
      case DISCONNECT_NETWORK_MODAL:
        draft.disconnectNetworkModal = action.data;
        break;
      case DISCONNECT_WEBCAM_MODAL:
        draft.disconnectWebcamModal = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        return;
    }
  });

export default userReducer;
