/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { examApi } from "../../../api/exam/examApi";
import { updateApi } from "../../../api/update/updateApi";
import { useNowExamData } from "../../../hooks/useNowExamData";
import {
  MAIN_EXAM_REQUEST,
  SET_EXAMPLE_PAGE,
} from "../../../_reducers/mainExamAction";
import { TIME_CHANGE, TIME_STOP } from "../../../_reducers/timeAction";
import { ModalTemplate } from "../../base/ModalTemplate";
import { BasicButton } from "./BasicButton";
import { testCompletedModal } from "../../../utils/testCompletedModal";
import { useTranslation } from "react-i18next";

export const MaintestJuksungNextPrevButton = ({
  setModal,
  examAnswer,
  isLoading,
  setLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const nowExamData = useNowExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const examDataTestStatus = nowExamData?.testerStatus;
  const totalPage = examDataTestStatus?.totalPage;
  const [isNullConfirmModal, setNullConfirmModal] = useState(false);
  const [isNextConfirmModal, setIsNextConfirmModal] = useState(false);
  const [isExamEndConfirmModal, setIsExamEndConfirmModal] = useState(false);
  const { waitingModal } = useSelector((state) => state.modalAction);
  const initialState = useSelector((state) => state.mainExamAction.data);
  const isReply =
    nowExamData?.exam?.questionInfoData?.questionList[0]?.questionEx.length ===
    0;

  useEffect(() => {
    setIsNextConfirmModal(false);
    setNullConfirmModal(false);
    setIsExamEndConfirmModal(false);
  }, [examDataTestStatus?.examSubStatus]);

  const updateRemainingSecondsTime = async () => {
    //전체 시간 업데이트
    try {
      await updateApi.updateTime(nowExamData.testerStatus);
    } catch (err) {
      console.log(err);
    }
  };

  const prevPage = async () => {
    setLoading(true);

    if (examDataTestStatus?.savePage !== 1) {
      await examApi
        .examStart(
          examDataTestStatus?.savePage - 1,
          examDataTestStatus?.examSubStatus,
          examDataTestStatus
        )
        .then((response) => {
          dispatch({
            type: MAIN_EXAM_REQUEST,
            data: response.data.data,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (!isReply) {
      await updateRemainingSecondsTime();
    }
  };

  const nextPage = async () => {
    setLoading(true);

    //시간 업데이트
    if (examDataTestStatus?.prevUseYN === "N") {
      try {
        await updateApi.updateJuksungInterval(nowExamData);
      } catch {
        setLoading(false);
      }
    } else {
      if (!isReply) {
        await updateRemainingSecondsTime();
      }
    }

    // 검사 종료
    if (
      examDataTestStatus?.savePage === totalPage &&
      examDataTestStatus?.maxExamSubNo === examDataTestStatus?.examSubNo &&
      examDataTestStatus?.maxExamNo === examDataTestStatus?.examNo
    ) {
      await testCompletedModal(dispatch, waitingModal);
      setLoading(false);
    }

    //다음 과목으로 이동
    if (
      (examDataTestStatus?.savePage === totalPage &&
        examDataTestStatus?.maxExamSubNo > examDataTestStatus?.examSubNo) ||
      (examDataTestStatus?.savePage === totalPage &&
        examDataTestStatus?.maxExamNo > examDataTestStatus?.examNo)
    ) {
      await nextExamPage();
      setLoading(false);
    }

    if (examDataTestStatus?.savePage < totalPage) {
      //다음페이지 이동
      await examApi
        .examStart(
          examDataTestStatus?.savePage + 1,
          examDataTestStatus?.examSubStatus,
          examDataTestStatus
        )
        .then((response) => {
          dispatch({
            type: MAIN_EXAM_REQUEST,
            data: response.data.data,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const prevButton = () => {
    if (nowExamData?.example) {
      if (exampleIndex === 0) {
        return <div></div>;
      } else {
        return (
          <BasicButton
            wid="s"
            hei="s"
            disabled={exampleIndex === 0}
            onClick={() =>
              exampleIndex > 0 &&
              dispatch({
                type: SET_EXAMPLE_PAGE,
                data: exampleIndex - 1,
              })
            }
            label={t(`exam.button.prevLabel`)}
          />
        );
      }
    } else {
      if (examDataTestStatus?.savePage === 1) {
        return <div></div>;
      } else {
        if (examDataTestStatus?.prevUseYN === "Y") {
          return (
            <BasicButton
              wid="s"
              hei="s"
              onClick={prevPage}
              label={t(`exam.button.prevLabel`)}
            />
          );
        }
        return <div></div>;
      }
    }
  };

  const modalButton = () => {
    if (nowExamData?.example) {
      return (
        <BasicButton
          hei="s"
          onClick={() => setModal(true)}
          label={
            nowExamData?.example?.questionInfoData?.questionList[exampleIndex]
              ?.buttonNamePopUp
          }
        />
      );
    } else {
      return <div></div>;
    }
  };

  const nextButton = () => {
    //예제 일 경우
    if (nowExamData?.example) {
      if (nowExamData?.example?.questionInfoData?.lastNo === exampleIndex + 1) {
        return <div></div>;
      } else {
        return (
          <BasicButton
            wid="s"
            hei="s"
            onClick={() => {
              dispatch({
                type: SET_EXAMPLE_PAGE,
                data: exampleIndex + 1,
              });
            }}
            label={t(`exam.button.nextLabel`)}
          />
        );
      }
    }
    //본검사
    else if (examDataTestStatus?.savePage < totalPage) {
      return (
        <BasicButton
          wid="s"
          hei="s"
          label={t(`exam.button.nextLabel`)}
          onClick={async () => {
            if (isLoading) return;
            if (
              examDataTestStatus?.prevUseYN === "N" &&
              examAnswer.answer === null
            ) {
              setNullConfirmModal(true);
              return;
            }
            await nextPage();
          }}
        />
      );
    }
    //한 과목을 다 푼 경우
    else if (
      (examDataTestStatus?.savePage === totalPage &&
        examDataTestStatus?.maxExamSubNo > examDataTestStatus?.examSubNo) ||
      (examDataTestStatus?.savePage === totalPage &&
        examDataTestStatus?.maxExamNo > examDataTestStatus?.examNo)
    ) {
      if (examDataTestStatus?.prevUseYN === "Y") {
        return <div></div>;
      } else {
        return (
          <BasicButton
            wid="s"
            hei="s"
            label={t(`exam.button.complete`)}
            onClick={() => {
              if (isLoading) return;
              setIsNextConfirmModal(true);
            }}
          />
        );
      }
    }
    // 종료 일 경우
    else if (
      examDataTestStatus?.savePage === totalPage &&
      examDataTestStatus?.maxExamNo === examDataTestStatus?.examNo &&
      examDataTestStatus?.maxExamSubNo === examDataTestStatus?.examSubNo &&
      examDataTestStatus?.prevUseYN === "N"
    ) {
      return (
        <BasicButton
          wid="s"
          hei="s"
          label={t(`exam.button.end`)}
          onClick={() => {
            if (isLoading) return;
            setIsExamEndConfirmModal(true);
          }}
        />
      );
    }
  };

  const nextExamPage = async () => {
    dispatch({ type: TIME_STOP, data: true });
    await examApi
      .examStart(1, examDataTestStatus?.examSubStatus + 1, examDataTestStatus)
      .then((result) => {
        //다음 문항 세팅
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: result.data.data,
        });
        dispatch({
          type: TIME_CHANGE,
          data: result.data.data.example.practiceMinutes * 60,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div css={buttonWrapper}>
        <div className="prev-button-wrap">{prevButton()}</div>
        <div className="modal-button-wrap">{modalButton()}</div>
        <div className="next-button-wrap">{nextButton()}</div>
      </div>

      {isNextConfirmModal && (
        <ModalTemplate closeBtn={true} setModal={setIsNextConfirmModal}>
          <div css={confirmModalMessage}>
            {examAnswer.answer !== null && (
              <span>
                {t(`exam.juksung.prevNConfirmModal.examEnd`)}
                <br />
              </span>
            )}
            {examAnswer.answer === null && (
              <span>
                {t(`exam.juksung.prevNConfirmModal.answerNull`)}
                <br />
              </span>
            )}
            <span>{t(`exam.juksung.prevNConfirmModal.nextExam`)}</span>
          </div>
          <div css={buttonGroup}>
            <BasicButton
              wid="s"
              hei="s"
              onClick={async () => {
                if (!isLoading) {
                  setLoading(true);
                  setIsNextConfirmModal(false);
                  if (examAnswer.answer === null) {
                    await updateApi
                      .updateJuksungMark(initialState, null)
                      .then(() => {
                        setLoading(false);
                        nextPage();
                      })
                      .catch(() => {
                        setLoading(false);
                      });
                  } else {
                    await nextPage();
                  }
                }
              }}
              label={t(`exam.button.confirm`)}
            />
          </div>
        </ModalTemplate>
      )}

      {isNullConfirmModal && (
        <ModalTemplate
          setModal={setNullConfirmModal}
          closeBtn={true}
          isTest={false}
        >
          <div css={nullAnswerConfirmModalBox}>
            <div className="text">
              {t(`exam.juksung.prevNConfirmModal.answerNullText`)}
            </div>
            <div className="button-group-box">
              <BasicButton
                wid="s"
                hei="s"
                label={t(`exam.button.confirm`)}
                onClick={async () => {
                  if (!isLoading) {
                    setLoading(true);
                    setNullConfirmModal(false);
                    await updateApi
                      .updateJuksungMark(initialState, null)
                      .then(() => {
                        setLoading(false);
                        nextPage();
                      })
                      .catch(() => {
                        setLoading(false);
                      });
                  }
                }}
              />
            </div>
          </div>
        </ModalTemplate>
      )}

      {isExamEndConfirmModal && (
        <ModalTemplate
          setModal={setIsExamEndConfirmModal}
          closeBtn={true}
          isTest={false}
        >
          <div css={nullAnswerConfirmModalBox}>
            <div className="text">
              {t(`exam.juksung.prevNConfirmModal.juksungExamEnd`)}
              <br />
              {examAnswer.answer === null && (
                <span>
                  {t(`exam.juksung.prevNConfirmModal.answerNull`)} <br />
                </span>
              )}
              {t(`exam.juksung.prevNConfirmModal.examEndQuestion`)}
            </div>
            <div className="button-group-box">
              <BasicButton
                wid="s"
                hei="s"
                label={t(`exam.button.confirm`)}
                onClick={async () => {
                  if (!isLoading) {
                    setLoading(true);
                    setIsExamEndConfirmModal(false);
                    if (examAnswer.answer === null) {
                      await updateApi
                        .updateJuksungMark(initialState, null)
                        .then(() => {
                          setLoading(false);
                          nextPage();
                        })
                        .catch(() => {
                          setLoading(false);
                        });
                    } else {
                      await nextPage();
                    }
                  }
                }}
              />
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

const nullAnswerConfirmModalBox = css`
  .text {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    white-space: pre;
  }
  .button-group-box {
    gap: 10px;
    display: flex;
    justify-content: center;
  }
`;
const buttonWrapper = css`
  height: 78px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .modal-button-wrap {
    width: 90px;
    position: absolute;
    right: 50%;
  }
`;

const confirmModalMessage = css`
  margin: 40px 20px 10px;
  white-space: pre;
  text-align: center;
  font-size: 1rem;
`;

const buttonGroup = css`
  padding: 20px;
  left: 30%;
`;
