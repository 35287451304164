import { LoginPage } from "./login/LoginPage";
import { EndPage } from "./end/EndPage";
import { AgreementPage } from "./agreement/AgreementPage";
import { DeviceCheckPage } from "./device/DeviceCheckPage";
import { DeviceConnectQrPage } from "./device/DeviceConnectQrPage";
import { MaintestIdcardPage } from "./idcard/MaintestIdcardPage";
import { PretestIdcardPage } from "./idcard/PretestIdcardPage";
import { MainTestPage } from "./maintest/MainTestPage";
import { MobileConnectPage } from "./mobile/MobileConnectPage";
import { PreTestPage } from "./pretest/PreTestPage";
import { TimeTablePage } from "./timetable/TimeTablePage";
import { TotalTestPage } from "./TotalTestPage";

export {
  LoginPage,
  EndPage,
  AgreementPage,
  DeviceCheckPage,
  DeviceConnectQrPage,
  MaintestIdcardPage,
  PretestIdcardPage,
  MainTestPage,
  MobileConnectPage,
  PreTestPage,
  TimeTablePage,
  TotalTestPage,
};
