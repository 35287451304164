import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const practiceApi = {
  practiceData: (data) => {
    return apiClient.post(`${API_URL.PRACTICE_DATA}`, {
      examNo: data.examNo,
      examSubNo: data.examSubNo,
      pageNo: data.pageNo,
      mode: data.mode,
    });
  },
  uploadIdcard: (preIdFileUrl, idCardType) => {
    return apiClient.patch(`${API_URL.PRACTICE_ID}`, {
      idCardType: idCardType,
      uploadIdFileUrl: preIdFileUrl,
    });
  },
};
