/** @jsxImportSource @emotion/react */
import { css, jsx, keyframes, useTheme } from "@emotion/react";
import { CloseIcon } from "../system/icon/CloseIcon";

export const ModalTemplate = ({
  children,
  setModal,
  closeBtn,
  title = false,
  isTest = true, //테스트 여부에 따라 모달 위치 조절
  modalSize, //모달 사이즈가 클 경우 개별적 사이즈 조절
  zIndex,
}) => {
  const theme = useTheme();

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const getPositionTop = () => {
    if (modalSize === "large") return "55%";
    if (!isTest) return "50%";
    if (isTest) return "47%";
  };

  const positionTop = getPositionTop();

  return (
    <div
      css={modalBackground({ isTest, zIndex })}
      onClick={() => {
        closeBtn && setModal(false);
      }}
    >
      <div css={modalWrapper({ theme, positionTop })} onClick={stopPropagation}>
        <div className="modal-line" />
        <div>
          {closeBtn && (
            <CloseIcon onClick={() => setModal(false)} fontSize="25px" />
          )}
        </div>
        <div css={modalInner}>
          {title && <div className="modal-title">{title}</div>}
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

const boxFade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const modalBackground = ({ isTest, zIndex }) => css`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${zIndex ? zIndex : 888};
  top: ${isTest ? "90px" : 0};
  height: ${isTest ? "calc(100% - 90px)" : "100%"};
`;

const modalWrapper = ({ theme, positionTop }) => css`
  animation: ${boxFade} 0.5s linear alternate;
  position: fixed;
  top: ${positionTop};
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: white;
  .modal-line {
    position: absolute;
    top: 0;
    border: 4px solid ${theme.mainColor};
    background-color: ${theme.mainColor};
    width: 20%;
    min-width: 100px;
  }
  .close-btn {
    position: absolute;
    right: 10px;
    padding: 10px;
    font-size: 25px;
    z-index: 10;
    cursor: pointer;
  }
`;

const modalInner = css`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  padding: 15px 50px 15px 50px;
  .modal-title {
    display: flex;
    text-align: center;
    font-size: 24px;
    padding-top: 30px;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    font-size: 18px;
    border: none;
    align-items: center;
  }
  span {
    text-align: center;
  }
`;
