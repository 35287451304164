/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { PendingTemplate } from "../base/PendingTemplate";
import { useTranslation } from "react-i18next";

export const PendingWaiting = () => {
  const { t } = useTranslation();

  const [count, setCount] = useState(10);
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
      }
    },
    isRunning ? 1000 : null
  );

  return isRunning ? (
    <PendingTemplate title={t(`pending.examEnd.title`)} loading={true}>
      <div css={checkText}>{t(`pending.examEnd.waitingText`)}</div>
    </PendingTemplate>
  ) : (
    <PendingTemplate title={t(`pending.waiting.title`)}>
      <div css={endText}>{t(`pending.waiting.endText`)}</div>
      <div>
        <FontAwesomeIcon icon={faExclamationCircle} css={icon} />
      </div>
    </PendingTemplate>
  );
};

const endText = css`
  padding-bottom: 60px;
  white-space: pre;
`;
const icon = css`
  font-size: 36px;
  color: #aa0000;
`;

const checkText = css`
  padding: 20px 0 10px;
`;
