/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { updateApi } from "../../../../api/update/updateApi";
import { useDivisionExamData } from "../../../../hooks/useDivisionExamData";
import { MAIN_EXAM_JUKSUNG_MARK_UPDATE } from "../../../../_reducers/mainExamAction";

export const MaintestJuksungQuestionAnswer = ({
  examAnswer,
  setExampleAnswer,
  setExamAnswer,
  setMarkInfosArray,
  markInfosArray,
  setAnswerLoading,
  isAnswerLoading,
}) => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.mainExamAction.data);
  const examData = useDivisionExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const questionListIndex = initialState?.exam ? 0 : exampleIndex;

  //(예제) 답안 클릭 함수
  const clickHandler = (examNo, examSubNo, pageNo, data) => {
    //세션 스토리지에서 값을 가져옴
    const storage = JSON.parse(
      sessionStorage.getItem(`${examNo}-${examSubNo}-${pageNo}`)
    );
    //만약 값이 같으면 sessionStorage.removeItem로 지워줌
    if (storage && storage === data) {
      sessionStorage.removeItem(`${examNo}-${examSubNo}-${pageNo}`);
      setExampleAnswer({
        examSubNo: examSubNo,
        pageNo: pageNo,
        answer: null,
      });
      setMarkInfosArray(
        markInfosArray?.map((data, index) =>
          index === exampleIndex ? false : data
        )
      );
    } else {
      //같지 않을 경우 sessionStorage.setItem로 값을 할당 해 줌
      sessionStorage.setItem(
        `${examNo}-${examSubNo}-${pageNo}`,
        JSON.stringify(data)
      );
      setExampleAnswer({
        examSubNo: examSubNo,
        pageNo: pageNo,
        answer: JSON.parse(
          sessionStorage.getItem(`${examNo}-${examSubNo}-${pageNo}`)
        ),
      });
      setMarkInfosArray(
        markInfosArray?.map((data, index) =>
          index === exampleIndex ? true : data
        )
      );
    }
  };

  //답안클릭 시 업데이트
  const onClickQuestionAnswer = (choose) => {
    if (isAnswerLoading) return;
    setAnswerLoading(true);
    if (initialState?.exam) {
      //답 취소
      if (examAnswer?.answer === choose) {
        dispatch({ type: MAIN_EXAM_JUKSUNG_MARK_UPDATE, data: null });
        updateApi
          .updateJuksungMark(initialState, null)
          .then(() => {
            setAnswerLoading(false);
            setExamAnswer({
              examNo: initialState.testerStatus.examNo,
              examSubNo: initialState.testerStatus.examSubNo,
              pageNo: initialState.testerStatus.savePage,
              answer: null,
            });
          })
          .catch(() => {
            setAnswerLoading(false);
            setExamAnswer({
              examNo: initialState.testerStatus.examNo,
              examSubNo: initialState.testerStatus.examSubNo,
              pageNo: initialState.testerStatus.savePage,
              answer: choose,
            });
          });
      }
      //답 체크
      else {
        dispatch({ type: MAIN_EXAM_JUKSUNG_MARK_UPDATE, data: choose });
        updateApi
          .updateJuksungMark(
            initialState,
            initialState.exam.questionInfoData.questionList[0].questionEx[
              choose - 1
            ].questionExNo
          )
          .then(() => {
            setAnswerLoading(false);
            setExamAnswer({
              examNo: initialState.testerStatus.examNo,
              examSubNo: initialState.testerStatus.examSubNo,
              pageNo: initialState.testerStatus.savePage,
              answer: choose,
            });
          })
          .catch(() => {
            setAnswerLoading(false);
            setExamAnswer({
              examNo: initialState.testerStatus.examNo,
              examSubNo: initialState.testerStatus.examSubNo,
              pageNo: initialState.testerStatus.savePage,
              answer: null,
            });
          });
      }
    }
  };

  //페이지 네이션 답 체크 확인 함수
  //문항을 클릭 시 해당 페이지의 인덱스는 true로 바꾼다.
  //문항에 null이 들어가면 해당 인덱스는 false로 바꾼다.
  const onClickQuestionMarkAnswer = (choose) => {
    if (examAnswer?.answer === choose) {
      setMarkInfosArray(
        markInfosArray?.map((data, index) =>
          index + 1 === initialState.testerStatus.savePage ? false : data
        )
      );
    } else {
      setMarkInfosArray(
        markInfosArray?.map((data, index) =>
          index + 1 === initialState.testerStatus.savePage ? true : data
        )
      );
    }
  };

  return (
    <div css={AnswerWrapper}>
      <div className="ans_wrap">
        {examData?.questionInfoData?.questionList[
          questionListIndex
        ]?.questionEx?.map((data, i) => (
          <div
            className="ans_container"
            key={i}
            onClick={() => {
              // 예제 문항일 경우
              if (initialState?.testerStatus?.examSubStatus === 0) {
                clickHandler(
                  initialState?.testerStatus?.examNo,
                  initialState?.testerStatus?.examSubNo,
                  exampleIndex + 1,
                  i + 1
                );
              } else {
                //본검사 문항 일 경우
                onClickQuestionAnswer(i + 1);
                onClickQuestionMarkAnswer(i + 1);
              }
            }}
          >
            <div
              className={
                //본검사 예제 문항 일 경우
                initialState?.testerStatus?.examSubStatus === 0
                  ? JSON.parse(
                      sessionStorage.getItem(
                        `${initialState?.testerStatus?.examNo}-${
                          initialState?.testerStatus?.examSubNo
                        }-${exampleIndex + 1}`
                      )
                    ) ===
                    i + 1
                    ? "ans_check"
                    : "ans_num"
                  : //본검사 본 문항 일 경우
                  examAnswer?.answer === i + 1
                  ? "ans_check"
                  : "ans_num"
              }
            >
              {i + 1}
            </div>
            <div
              className="ans_qus"
              dangerouslySetInnerHTML={{
                __html: `${data.questionExText}`, //String형태의 html 렌더링하기
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const AnswerWrapper = () => css`
  margin-top: 20px;
  margin-left: 4px;
  .katex {
    font: unset;
    font-size: 18px;
  }
  .ans_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ans_container {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;
    &:hover {
      .ans_num {
        box-shadow: 0px 0 0 0.2rem rgb(92 92 92/50%);
        cursor: pointer;
      }
    }
    .ans_num {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      border-radius: 99px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #131313;
      font-weight: medium;
      flex: 1 0 auto;
    }
    .ans_check {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      border-radius: 99px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #131313;
      font-weight: medium;
      flex: 1 0 auto;
      background-color: #131313;
      color: #fefefe;
    }
  }
  .ans_qus {
    word-break: keep-all;
    line-height: 24px;
    white-space: pre-wrap;
    p,
    span {
      font-size: 18px !important;
      margin: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;
