/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";

export const DeviceCheckBox = React.memo(
  ({ title, testSuccess, children, loading, isVolCheck }) => {
    const { t } = useTranslation();

    return (
      <div css={totalSetting({ testSuccess, loading })}>
        <p className="settingTitle">{title}</p>
        <div>
          {(function () {
            if (loading)
              return (
                <p css={loadingText}>
                  {t(`device.deviceCheckBox.connectionLoading`)}
                </p>
              );
            if (title !== t(`device.deviceCheckBox.speaker.text`)) {
              if (testSuccess) {
                if (
                  (title === "마이크" || title === "Microphone") &&
                  !isVolCheck
                ) {
                  return (
                    <p css={VolumeTest}>
                      {t(`device.deviceCheckBox.connectionMicLoading`)}
                    </p>
                  );
                } else {
                  return (
                    <p css={SuccessText}>
                      {t(`device.deviceCheckBox.connectionComplete`)}
                    </p>
                  );
                }
              } else {
                return (
                  <p css={FailText}>
                    {t(`device.deviceCheckBox.connectionError`)}
                  </p>
                );
              }
            }
          })()}
          {children}
        </div>
      </div>
    );
  }
);

const totalSetting = (props) => css`
  width: 260px;
  height: 280px;
  margin: 0 4px;
  white-space: pre-line;
  text-align: center;
  .settingTitle {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  padding-top: 20px;

  border: 3px solid ${!props.loading && !props.testSuccess ? "red" : "none"};
  background-color: ${props.loading && "#efefef"};
  background-color: ${!props.loading &&
  (props.testSuccess ? "#707070" : "#efefef")};
  color: ${!props.loading && (props.testSuccess ? "#efefef" : "#131313")};
`;

const loadingText = css`
  font-size: 0.725rem;
  color: #131313;
`;
const SuccessText = css`
  font-size: 0.725rem;
  color: #00dca8;
`;

const VolumeTest = css`
  font-size: 0.725rem;
  color: #f5c342;
`;
const FailText = css`
  font-size: 0.725rem;
  color: #aa0000;
  white-space: pre-line;
`;
