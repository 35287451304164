import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  chatWarningMessage: null,
};
export const CHAT_WARNING_MESSAGE = "CHAT_WARNING_MESSAGE";

const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHAT_WARNING_MESSAGE:
        draft.chatWarningMessage = action.data;
        break;
      default:
        return;
      case PURGE: {
        return initialState;
      }
    }
  });

export default userReducer;
