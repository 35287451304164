// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calc_wrap__oa06M {
  position: relative;
  width: calc(100% - 24px);
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  bottom: 12px;
}

.Calc_title__ObjBR {
  font-size: large;
  font-weight: medium;
  margin-bottom: 10px;
  cursor: pointer;
}

.Calc_result_sub__pd71v {
  height: 10px;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.7);
  text-align: right;
}

.Calc_btn_wrap__pC-2C {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Calc_single__c88ws {
  width: calc(25% - 3px);
  text-align: center;
  line-height: 30px;
  margin: 2px 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: 0.3s;
  border-radius: 3px;
  background: #ffffff;
}

.Calc_double__F6Snl {
  width: calc(50% - 4px);
}

.Calc_black__eREYX {
  background: #555;
  color: #ffffff;
}

.Calc_gray__hFsh3 {
  background-color: #eee;
  color: #131313;
}

.Calc_error_msg__n3ThT {
  color: #ff004e;
  font-weight: bold;
  float: left;
  margin-top: -0.3rem;
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/tool/calc/Calc.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;EACd,QAAQ;EACR,OAAO;EACP,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".wrap {\n  position: relative;\n  width: calc(100% - 24px);\n  position: absolute;\n  margin: 0 auto;\n  right: 0;\n  left: 0;\n  bottom: 12px;\n}\n\n.title {\n  font-size: large;\n  font-weight: medium;\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n\n.result_sub {\n  height: 10px;\n  font-size: 12px;\n  line-height: 12px;\n  color: rgba(0, 0, 0, 0.7);\n  text-align: right;\n}\n\n.btn_wrap {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.single {\n  width: calc(25% - 3px);\n  text-align: center;\n  line-height: 30px;\n  margin: 2px 0;\n  cursor: pointer;\n  user-select: none;\n  transition: 0.3s;\n  border-radius: 3px;\n  background: #ffffff;\n}\n\n.double {\n  width: calc(50% - 4px);\n}\n\n.black {\n  background: #555;\n  color: #ffffff;\n}\n\n.gray {\n  background-color: #eee;\n  color: #131313;\n}\n\n.error_msg {\n  color: #ff004e;\n  font-weight: bold;\n  float: left;\n  margin-top: -0.3rem;\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Calc_wrap__oa06M`,
	"title": `Calc_title__ObjBR`,
	"result_sub": `Calc_result_sub__pd71v`,
	"btn_wrap": `Calc_btn_wrap__pC-2C`,
	"single": `Calc_single__c88ws`,
	"double": `Calc_double__F6Snl`,
	"black": `Calc_black__eREYX`,
	"gray": `Calc_gray__hFsh3`,
	"error_msg": `Calc_error_msg__n3ThT`
};
export default ___CSS_LOADER_EXPORT___;
