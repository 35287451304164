/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useRef, useState, useEffect } from "react";
import { useInterval } from "../../hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { Capture } from "../../components/idcard/Capture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { PendingIdCheck } from "../../components/pending/PendingIdCheck";
import { CheckMaskingGuide } from "../../components/idcard/CheckMaskingGuide";
import { RecButton } from "../../components/system/button/RecButton";
import { SubRectangleButton } from "../../components/system/button/SubRectangleButton";
import { examApi } from "../../api/exam/examApi";
import { sendStatus } from "../../adapters/sendStatus";
import { useRecord } from "../../hooks/useRecord";
import { ALERT_MODAL_ON } from "../../_reducers/modalAction";
import { useTranslation } from "react-i18next";
import { getFormattedTimestamp } from "../../utils/getDateFormat";
import { awsApi } from "../../api/aws/awsApi";
import axios from "axios";
import { convertURLtoFile } from "../../utils/convert";

export const MaintestIdcardPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [hasPhoto, setHasPhoto] = useState(false);
  const { testerIdx, groupIdx } = useSelector((state) => state.userAction.data);
  const { sendIdCardMessage } = sendStatus();
  const { stopRecordHandler } = useRecord();
  const socketData = useSelector((state) => state.socketAction);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [maskingTimerCount, setMaskingTimerCount] = useState(5);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isIdcardWaitingPendingModal, setIdcardWaitingPendingModal] =
    useState(false);
  const [captureImage, setCaptureImage] = useState(null);
  const [isSentBack, setSentBack] = useState(false);
  const [isIdcardConfirmedPendingModal, setIdcardConfirmedPendingModal] =
    useState(false);
  const [maskingGuideSelect, setMaskingGuideSelected] = useState();
  const [isViewerStandby, setViewerStandby] = useState(false);
  const { videoStream } = useSelector((state) => state.deviceAction);

  useEffect(() => {
    if (videoStream.id && videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }, [hasPhoto, videoStream]);

  //응시자가 신분증 페이지 진입 시 소켓 메세지 전송, 화면 녹화 중지(감독관에게 펜딩 띄위기 용도)
  useEffect(() => {
    if (!isIdcardConfirmedPendingModal && !isIdcardWaitingPendingModal) {
      sendIdCardMessage("UPLOADING");
      stopRecordHandler();
    }
  }, [socketData, isIdcardConfirmedPendingModal, isIdcardWaitingPendingModal]);

  useEffect(() => {
    //신분증 확인 모달이 띄워져 있는 상태에서 감독관이 튕겼다가 다시 들어왔을 경우 업로드 뱃지 다시 보내기
    if (isIdcardWaitingPendingModal) {
      sendIdCardMessage("UPLOAD");
    }
  }, [socketData.viewerSocket]);

  useEffect(() => {
    if (isViewerStandby && socketData?.viewerSocket?.viewerSocketId) {
      uploadIdcard();
    }
  }, [isViewerStandby, socketData]);

  useInterval(
    () => {
      setMaskingTimerCount(maskingTimerCount - 1);
      if (maskingTimerCount === 1) {
        setIsTimerRunning(false);
        setMaskingTimerCount(5);
        takePhoto();
      }
    },
    isTimerRunning ? 1000 : null
  );

  //마스킹 지우기
  const erase = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(captureImage, 0, 0, 440, 280);
  };

  //캡쳐
  const takePhoto = () => {
    const canvas = canvasRef.current;
    canvas.width = 440;
    canvas.height = 280;
    const ctx = canvas.getContext("2d");
    //캡쳐된 화면 자르기
    ctx.drawImage(videoRef.current, 0, 0, 440, 280);
    setCaptureImage(videoRef.current);
    setSentBack(false);
    setHasPhoto(true);
  };

  //캔버스 위에 그리기
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 440;
    canvas.height = 280;
    const context = canvas.getContext("2d");
    context.globalCompositeOperation = "source-over";
    context.strokeStyle = "black";
    contextRef.current = context;
  }, [canvasRef]);

  //snapShot 신분증 사진 등록
  const checkViewerStandbyIdcardUpload = () => {
    if (socketData?.viewerSocket?.viewerPeerId) {
      uploadIdcard();
    } else {
      //감독관 대기
      setIdcardWaitingPendingModal(true);
      setViewerStandby(true);
    }
  };

  const uploadIdcard = async () => {
    setViewerStandby(false);

    const fileName = `[본검사]ID_${getFormattedTimestamp()}.jpg`;
    const idCardImageFile = await convertURLtoFile(
      canvasRef.current.toDataURL()
    );

    await awsApi.preSignedUrl(fileName, "ID").then((res) => {
      if (res.data.data.preSignedUrl) {
        axios
          .put(res.data.data.preSignedUrl, idCardImageFile)
          .then(() => {
            setIdcardWaitingPendingModal(true);
            setLoadingButton(true);
            examApi
              .examUploadIdcard(res.data.data.uploadUrl)
              .then(() => {
                sendIdCardMessage("UPLOAD");
                setLoadingButton(false);
              })
              .catch(() => {
                setLoadingButton(true);
              });
          })
          .catch(() => {
            dispatch({
              type: ALERT_MODAL_ON,
              data: t(`error.system`),
            });
            setLoadingButton(false);
            idCardRejectHandler();
          });
      }
    });
  };

  const idCardRejectHandler = () => {
    setSentBack(true);
    setHasPhoto(false);
  };

  return (
    <div css={totalWrapper}>
      <div css={title}>
        <div>{t(`idCard.text.title`)}</div>
        <div className="sub-text">{t(`idCard.text.describe`)}</div>
      </div>
      <div css={container}>
        {/* 본 검사 마스킹 가이드 */}
        <div css={guideWrapper}>
          <CheckMaskingGuide
            setSelected={setMaskingGuideSelected}
            select={maskingGuideSelect}
          />
        </div>
        <div css={contentWrapper}>
          {!hasPhoto && (
            <div css={camWrapper} style={{ transform: "rotateY(180deg)" }}>
              <video ref={videoRef} autoPlay playsInline muted />
            </div>
          )}
          <Capture
            ref={canvasRef}
            contextRef={contextRef}
            hasPhoto={hasPhoto}
          />
        </div>
      </div>
      <div css={btnWrapper}>
        {!hasPhoto ? (
          <RecButton
            onClick={() => {
              setIsTimerRunning(true);
            }}
          >
            {!isTimerRunning && <FontAwesomeIcon icon={faCamera} />}
            {isTimerRunning
              ? `${maskingTimerCount} ${t(`idCard.button.seconds`)}`
              : t(`idCard.button.takePhoto`)}
          </RecButton>
        ) : (
          <>
            <div className="canvas-btn">
              <SubRectangleButton
                onClick={() => erase()}
                label={t(`idCard.button.eraseMasking`)}
              />
              <SubRectangleButton
                onClick={() => setHasPhoto(false)}
                label={t(`idCard.pretest.button.reSelect`)}
              />
            </div>
            {loadingButton ? (
              <RecButton>
                <FontAwesomeIcon icon={faSpinner} />
                {t(`idCard.button.registering`)}
              </RecButton>
            ) : (
              <RecButton onClick={() => checkViewerStandbyIdcardUpload()}>
                <FontAwesomeIcon icon={faArrowUpFromBracket} />
                {t(`idCard.button.registration`)}
              </RecButton>
            )}
          </>
        )}
      </div>
      {/* 신분증 펜딩 */}
      {isIdcardWaitingPendingModal && (
        <PendingIdCheck
          isSentBack={isSentBack}
          setSendIdCard={setIdcardWaitingPendingModal}
          idCardRejectHandler={idCardRejectHandler}
          isModal={isIdcardConfirmedPendingModal}
          setIsModal={setIdcardConfirmedPendingModal}
        />
      )}
    </div>
  );
};

const totalWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`;
const title = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  min-height: 60px;
  max-height: 150px;
  span {
    font-weight: 700;
  }
  .sub-text {
    font-size: 16px;
    color: #ff004e;
  }
`;

const container = css`
  display: flex;
  flex-direction: row;
  gap: 1%;
`;

const guideWrapper = css`
  padding: 29px;
  height: 520px;
  min-height: 489px;
  background-color: #f0f0f1;
  overflow: hidden;
`;

const contentWrapper = css`
  position: relative;
  width: 660px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f0f0f1;
  video {
    width: 440px;
    height: 280px;
  }
`;

const btnWrapper = css`
  display: flex;
  position: relative;
  left: 212px;
  justify-content: center;
  align-items: center;
  height: 80px;
  .canvas-btn {
    width: 440px;
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 150px;
    justify-content: space-between;
  }
`;

const camWrapper = css`
  display: flex;
  justify-content: center;
  height: "600px";
  @media (max-width: 1200px) {
    height: "520px";
  }
  display: flex;
  .snapshot-area {
    transform: rotateY(180deg);
    font-size: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 440px;
    height: 280px;
    z-index: 3;
    border: 3px dashed #fff;
    border-radius: 5px;
    color: #fff;
    margin: auto;
    text-align: center;
  }

  .snapshot {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 440px;
    height: 280px;
    z-index: 4;
  }
`;
