export const getTextColorByBackgroundColor = (color) => {
  try {
    if (color.charAt(0) === "#") {
      const c = color.substring(1); // 색상 앞의 # 제거
      const rgb = parseInt(c, 16); // rrggbb를 10진수로 변환
      const r = (rgb >> 16) & 0xff; // red 추출
      const g = (rgb >> 8) & 0xff; // green 추출
      const b = (rgb >> 0) & 0xff; // blue 추출
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      return luma < 127.5 ? "white" : "black"; // 글자색
    } else {
      var reg = /[\{\}\[\]\/?;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"rgba]/gi;
      const c = color.replace(reg, "");
      const rgb = c.split(",");
      const r = rgb[0];
      const g = rgb[1];
      const b = rgb[2];
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luma < 127.5 ? "white" : "black";
    }
  } catch (e) {
    console.log(e);
  }
};

export const getOpacityColor = (color) => {
  try {
    const a = color.substring(1); // # 제거
    const rgb = parseInt(a, 16); // rrggbb를 10진수로 변환
    const r = (rgb >> 16) & 0xff; // red 추출
    const g = (rgb >> 8) & 0xff; // green 추출
    const b = (rgb >> 0) & 0xff; // blue 추출

    const tableColor = "rgba" + "(" + r + "," + g + "," + b + "," + "0.2" + ")";
    return tableColor;
  } catch (e) {
    console.log(e);
  }
};

export const getIsRedColor = (color) => {
  const rgbColor = color.match(/\d+/g).map(Number);
  return rgbColor[0] > rgbColor[1] && rgbColor[0] > rgbColor[2];
};
