/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import { useRoomConnection } from "./useRoomConnection";
import { SOCKET_EVENT } from "./socket.enum";

export const chatMessage = () => {
  const { socket } = useRoomConnection();
  const socketData = useSelector((state) => state.socketAction);
  const { testerName } = useSelector((state) => state.userAction.data);

  const consumerChat = (consumer) => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_CHAT, consumer);
  };
  const consumerChatRead = (consumer) => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_CHAT_READ, consumer);
  };

  const consumerUserAvatar = (consumer) => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_AVATAR, consumer);
  };

  const consumerUserVoiceChat = (consumer) => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_SOUND, consumer);
  };

  //type이 warning으로 보낼 경우 감독관에서 감독관 채팅 로그로 저장
  //type이 chat으로 보낼 경우 응시자 채팅 로그로 저장
  const sendToChat = (chatText, type) => {
    if (Object.keys(socket).length === 0) return;
    socket?.emit(SOCKET_EVENT.CHAT, "to", {
      targetSocketId: socketData?.viewerSocket?.viewerSocketId,
      peerId: socketData?.userSocket?.myPeerId,
      userName: testerName,
      message: {
        text: chatText,
        type: type,
      },
    });
  };

  return {
    sendToChat,
    consumerChat,
    consumerChatRead,
    consumerUserAvatar,
    consumerUserVoiceChat,
  };
};
