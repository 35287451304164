import { useEffect, useState } from "react";
import { Loading } from "../modal/Loading";

export const PendingLoading = ({ loading }) => {
  const [timeLoading, setTimeLoading] = useState(false);
  useEffect(() => {
    let time;

    time =
      loading &&
      setTimeout(() => {
        setTimeLoading(loading);
      }, 300);

    return () => {
      setTimeLoading(false);
      clearTimeout(time);
    };
  }, [loading]);

  return (
    <>
      {timeLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <div style={{ paddingBottom: "100px" }}>
            <Loading size={60} />
          </div>
        </div>
      )}
    </>
  );
};
