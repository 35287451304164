export const getQueryObject = (url) => {
  try {
    const queryArray = url
      .replace("?", "")
      .split("&")
      .map((param) => param.split("="));

    const queryObject = queryArray.reduce((values, [key, value]) => {
      values[key] = value;
      return values;
    }, {});

    return queryObject;
  } catch (e) {
    console.log(e);
  }
};
