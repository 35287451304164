import styled from "@emotion/styled/macro";
import React from "react";
import { useTitle } from "../../hooks/useTitle";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export const Step = React.memo(() => {
  const { navTitle } = useTitle();

  return (
    <StepName>
      <SwitchTransition>
        <CSSTransition
          key={navTitle}
          timeout={300}
          classNames="page"
          unmountOnExit
        >
          <div className="page">{navTitle}</div>
        </CSSTransition>
      </SwitchTransition>
    </StepName>
  );
});

const StepName = styled.div`
  font-size: 24px;
  padding-left: 30px;
  position: absolute;
  top: 25px;
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .page-exit {
    opacity: 1;
    transform: scale(1);
  }

  .page-exit-active {
    opacity: 0;
    transform: scaleY(-1);
    transition: opacity 300ms, transform 300ms;
  }
`;
