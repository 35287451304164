/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { JuksungQuestionText } from "../JuksungQuestionText";
import { MaintestJuksungQuestionAnswer } from "./MaintestJuksungQuestionAnswer";
import { useDispatch, useSelector } from "react-redux";
import { useDivisionExamData } from "../../../../hooks/useDivisionExamData";
import { MaintestJuksungNextPrevButton } from "../../../system/button/MaintestJuksungNextPrevButton";
import { DrawingMemoCalc } from "../../../tool/DrawingMemoCalc";
import { UPDATE_MARK_ALL } from "../../../../_reducers/examUserDataAction";
import { ModalTemplate } from "../../../base/ModalTemplate";
import { DotSpinner } from "@uiball/loaders";
import { PagiNationButton } from "../../../system/button/PagiNationButton";
import { ProgressBar } from "../../../system/ProgressBar";
import { useNowExamData } from "../../../../hooks/useNowExamData";
import { ReplyAnswerForm } from "../../../system/form/ReplyAnswerForm";
import { PendingLoading } from "../../../../components/pending/PendingLoading";

export const MainTestJuksung = () => {
  const dispatch = useDispatch();

  const [isExampleModalLoad, setIsExampleModalLoad] = useState(true);
  const exampleList = useSelector(
    (state) => state.mainExamAction.data?.example?.questionInfoData.questionList
  );
  const examData = useDivisionExamData();
  const [isExampleModal, setExampleModal] = useState(false);
  const nowExamData = useNowExamData();
  const examDataTestStatus = nowExamData?.testerStatus;
  const totalPage = examDataTestStatus?.totalPage;
  const savePage = examDataTestStatus?.savePage;
  const examSubStatus = examDataTestStatus?.examSubStatus;
  const examNo = examDataTestStatus?.examNo;
  const examSubNo = examDataTestStatus?.examSubNo;
  const serverAnswer =
    examData?.questionInfoData?.questionList[0]?.questionMark;
  const initialState = useSelector((state) => state.mainExamAction.data);
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const userData = useSelector((state) => state.examUserDataAction.status);
  const [_, setExampleAnswer] = useState(null);
  const [progressbarCompleted, setProgressbarCompleted] = useState(0);
  const scrollRef = useRef(null);

  const [isAnswerLoading, setAnswerLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);

  const isReply =
    examData?.questionInfoData?.questionList[0]?.questionEx.length === 0;

  const [examAnswer, setExamAnswer] = useState({
    examNo: examNo,
    examSubNo: examSubNo,
    pageNo: savePage,
    answer: serverAnswer ? serverAnswer : null,
  });

  const [markInfosArray, setMarkInfosArray] = useState(null);

  const initialMaxArray = (num) => {
    const maxPageArray = new Array(num).fill(false);
    return maxPageArray;
  };

  useEffect(() => {
    if (examSubStatus === 1) {
      setProgressbarCompleted(Math.ceil((100 / totalPage) * savePage));
    } else if (examSubStatus === 0) {
      setProgressbarCompleted(
        Math.ceil((100 / exampleList?.length) * (exampleIndex + 1))
      );
    }
  }, [examSubStatus, exampleIndex, exampleList?.length, savePage, totalPage]);

  useEffect(() => {
    let initialMarkInfoArray;
    //본검사에서 재 접속시
    if (
      userData?.markAll &&
      userData?.examSubNo === examSubNo &&
      userData?.examNo === examNo
    ) {
      setMarkInfosArray(userData?.markAll);
      dispatch({ type: UPDATE_MARK_ALL, data: userData?.markAll });
    }
    //본검사 처음 접속 시
    else {
      // 예제 일 경우
      if (examSubStatus === 0) {
        initialMarkInfoArray = initialMaxArray(
          nowExamData?.example?.questionInfoData?.questionList.length
        );
      }
      // 본 문항 일 경우
      else {
        initialMarkInfoArray = initialMaxArray(totalPage);
      }
      setMarkInfosArray(initialMarkInfoArray);
      dispatch({ type: UPDATE_MARK_ALL, data: initialMarkInfoArray });
    }
  }, [examNo, examSubNo, examSubStatus]);

  useEffect(() => {
    if (examSubStatus === 0) {
      setExampleModal(true);
    } else {
      setExampleModal(false);
    }
  }, [examSubStatus]);

  //페이지 이동 시 스크롤 처음으로
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    sessionStorage.setItem(
      "startRemainingSeconds",
      sessionStorage.getItem("remainingSeconds")
    );
    setAnswerLoading(false);
  }, [savePage]);

  useEffect(() => {
    //답 체크 유지 상태 관리
    if (initialState.exam) {
      let serverAnswer = null;
      if (
        examData?.questionInfoData?.questionList[0]?.questionMark !==
        (null | 0)
      ) {
        serverAnswer =
          examData?.questionInfoData?.questionList[0]?.questionMark;
        setExamAnswer({
          examNo: examNo,
          examSubNo: examSubNo,
          pageNo: savePage,
          answer: serverAnswer,
        });
      }
      if (serverAnswer === null) {
        setExamAnswer({
          examNo: examNo,
          examSubNo: examSubNo,
          pageNo: examDataTestStatus?.savePage,
          answer: null,
        });
      }
    }
  }, [examData]);

  return (
    <>
      <PendingLoading loading={isAnswerLoading || isPageLoading} />
      <div css={totalWrapper}>
        <div css={contentsWrapper}>
          <div className="progress-wrap">
            {examDataTestStatus?.prevUseYN === "Y" ? (
              <div css={PaginationWrapper}>
                <div className="pagination">
                  <PagiNationButton
                    markInfosArray={markInfosArray}
                    isLoading={isPageLoading}
                    setLoading={setPageLoading}
                  />
                </div>
              </div>
            ) : (
              <ProgressBar
                completed={progressbarCompleted}
                nowPage={examSubStatus === 0 ? exampleIndex + 1 : savePage}
                totalPage={examSubStatus === 0 ? exampleList.length : totalPage}
              />
            )}
          </div>
          <div css={contentsBoxWrapper}>
            <div css={QuestionWrapper} ref={scrollRef}>
              <JuksungQuestionText examData={examData} />
              {isReply ? (
                <ReplyAnswerForm
                  setMarkInfosArray={setMarkInfosArray}
                  markInfosArray={markInfosArray}
                />
              ) : (
                <MaintestJuksungQuestionAnswer
                  setExampleAnswer={setExampleAnswer}
                  examAnswer={examAnswer}
                  setExamAnswer={setExamAnswer}
                  setMarkInfosArray={setMarkInfosArray}
                  markInfosArray={markInfosArray}
                  setAnswerLoading={setAnswerLoading}
                  isAnswerLoading={isAnswerLoading}
                />
              )}
            </div>
            <div>
              <MaintestJuksungNextPrevButton
                setModal={setExampleModal}
                examAnswer={examAnswer}
                setLoading={setPageLoading}
                isLoading={isPageLoading}
              />
            </div>
          </div>
        </div>
        <div css={sideWrapper}>
          <DrawingMemoCalc />
        </div>
      </div>
      {isExampleModal && (
        <ModalTemplate
          setModal={setExampleModal}
          closeBtn={true}
          isTest={false}
        >
          <div css={exampleImageBox}>
            <div className="loading-wrap">
              {isExampleModalLoad && <DotSpinner />}
            </div>
            <div className="example-title">
              {!isExampleModalLoad &&
                examData?.questionInfoData?.questionList[exampleIndex]
                  ?.buttonNamePopUp}
            </div>
            <img
              onLoad={() => setIsExampleModalLoad(false)}
              src={
                examData?.questionInfoData?.questionList[exampleIndex]?.popUpImg
              }
              alt=""
              style={{
                width: "100%",
                visibility: isExampleModalLoad ? "hidden" : "visible",
              }}
            />
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

const totalWrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const exampleImageBox = css`
  display: flex;
  width: 700px;
  height: 60vh;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  .loading-wrap {
    position: relative;
    top: 45%;
  }
  .example-title {
    font-size: 25px;
    display: flex;
    font-weight: 700;
    padding-bottom: 20px;
  }
`;

const contentsWrapper = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .progress-bar-wrap {
    padding-top: 10px;
  }
`;

const contentsBoxWrapper = css`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
  width: 70vw;
  min-width: 850px;
  max-width: 1060px;
`;

const QuestionWrapper = css`
  overflow-y: auto;
  overflow-x: hidden;
`;

const sideWrapper = css`
  height: 100%;
  justify-content: center;
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: flex-start;
`;

const PaginationWrapper = css`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  .pagination {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
`;
