/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PretestJuksungQuestionAnswer = ({ setRender }) => {
  const examData = useSelector((state) => state.pretestAction.data);
  //사전점검 State
  const { examNo, examSubNo, pageNo } = useSelector(
    (state) => state.pretestAction.data
  );
  //세션스토리지 관리 State
  const [storageState, setStorageState] = useState();
  //저장된 스토리지 값 가져오기
  const storage = JSON.parse(
    sessionStorage.getItem(`${examNo}-${examSubNo}-${pageNo}`)
  );
  useEffect(() => {
    setStorageState(storage);
  }, [storage]);

  //사전 점검 세션 스토리지 관리  값을 클릭 했을 때
  const clickhandler = (examSubNo, pageNo, data) => {
    //값이 같으면 세션 스토리지 값  제거
    if (storageState && storageState === data) {
      sessionStorage.removeItem(`${examNo}-${examSubNo}-${pageNo}`);
      setRender(`${examNo}-${examSubNo}-${pageNo}-${null}`);
    } else {
      //클릭한 데이터 값이 저장됨
      sessionStorage.setItem(
        `${examNo}-${examSubNo}-${pageNo}`,
        JSON.stringify(data)
      );
      setRender(`${examNo}-${examSubNo}-${pageNo}-${data}`);
    }
  };

  return (
    <div css={answerWrapper}>
      <div className="ans_wrap">
        {examData?.questionInfoData?.questionList[0]?.questionEx.map(
          (data, idx) => (
            <div
              className="ans_container"
              key={idx}
              onClick={() => {
                clickhandler(examSubNo, pageNo, idx + 1);
              }}
            >
              <div
                //세션 스토리지에 있는 답과 인덱스 답과 같으면 체크표시
                className={storageState === idx + 1 ? "ans_check" : "ans_num"}
              >
                {data.questionExNo}
              </div>
              <div
                className="ans_qus"
                dangerouslySetInnerHTML={{
                  __html: `${data.questionExText}`,
                }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

const breakpoints = [480, 768, 1024, 1200];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const answerWrapper = (theme) => css`
  margin-top: 20px;
  margin-left: 4px;
  ${mq[0]} {
    width: 400px;
  }
  ${mq[1]} {
    width: 600px;
  }
  ${mq[2]} {
    width: 800px;
  }
  .katex {
    font-size: 18px;
    font: unset;
  }
  .ans_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .ans_container {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;
    &:hover {
      .ans_num {
        box-shadow: 0px 0 0 0.2rem rgb(92 92 92/50%);
        cursor: pointer;
      }
    }
    .ans_num {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      border-radius: 99px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #131313;
      font-weight: medium;
      flex: 1 0 auto;
    }
    .ans_check {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      border-radius: 99px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #131313;
      font-weight: medium;
      flex: 1 0 auto;
      background-color: #131313;
      color: #fefefe;
    }
  }
  .ans_qus {
    word-break: keep-all;
    line-height: 24px;
    white-space: pre-wrap;
    p,
    span {
      font-size: 18px !important;
      margin: 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
