/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useDivisionExamData } from "../../../../hooks/useDivisionExamData";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

export const MaintestColumPart3 = ({
  questionList,
  examAnswerPart3,
  setExamAnswerPart3,
  numSetCheck,
  getNotCheckSetInfo,
  changeNull,
  index,
}) => {
  const { t } = useTranslation();

  const examData = useDivisionExamData();
  const initialState = useSelector((state) => state.mainExamAction.data);
  const nowPageSetNum = examData?.questionInfoData?.questionList?.length;

  const arrayQuestionSetNo = index + 1;

  //한 세트의 문항 수
  const questionSetCount =
    examData?.questionInfoData?.questionList[index]?.questions?.length;

  //FIXME: part3 해당 세트가 체크가 완료되어있는지 검사한다. 체크가 완료된 경우 api를 호출한다.
  const checkIfTargetQuestionSetIsFinish = (setNumber) => {
    const check = getNotCheckSetInfo(examAnswerPart3, setNumber);
    if (check.QsetNum === null) {
      changeNull(examAnswerPart3, setNumber);
    }
  };

  //FIXME: 멀다 가깝다를 클릭 했을 경우
  const updateAnswerIpsative = (Qset, Qnum, Qvalue) => {
    const data = {
      questionNo: Qnum,
      questionMarkIpsative: Qvalue,
    };
    const newArr = [...examAnswerPart3];
    if (
      newArr[arrayQuestionSetNo - 1][(Qnum - 1) % questionSetCount]
        ?.questionMarkIpsative === Qvalue
    ) {
      return;
    }
    const index = newArr[arrayQuestionSetNo - 1]?.findIndex(
      (element) => element.questionMarkIpsative === Qvalue
    );

    if (
      newArr[arrayQuestionSetNo - 1][(Qnum - 1) % questionSetCount]?.questionNo
    ) {
      if (index === -1) {
        newArr[arrayQuestionSetNo - 1][
          (Qnum - 1) % questionSetCount
        ].questionMarkIpsative = Qvalue;
      } else {
        newArr[arrayQuestionSetNo - 1][index].questionMarkIpsative = null;
        newArr[arrayQuestionSetNo - 1][
          (Qnum - 1) % questionSetCount
        ].questionMarkIpsative = Qvalue;
      }
    } else {
      if (index === -1) {
        newArr[arrayQuestionSetNo - 1][(Qnum - 1) % questionSetCount] = data;
      } else {
        newArr[arrayQuestionSetNo - 1][index].questionMarkIpsative = null;
        newArr[arrayQuestionSetNo - 1][(Qnum - 1) % questionSetCount] = data;
      }
    }
    setExamAnswerPart3(newArr);

    // 체크를 할 때마다 해당 세트 문항이 모두 체크됐는지 확인하는 함수를 추가로 호출한다.
    initialState.exam &&
      checkIfTargetQuestionSetIsFinish(arrayQuestionSetNo - 1);
  };

  const language = i18n.language;

  return (
    <div css={totalWrapper({ nowPageSetNum })}>
      <div css={tableLeft}>
        <div className="table-num" css={tableNum}>
          {questionList.questionSetNo}
        </div>
      </div>
      <div css={tableRightWrapper}>
        {questionList?.questions?.map((question, i) => (
          <div
            key={question?.questionNo}
            css={[
              tableRight({ nowPageSetNum }),
              css`
                &:hover {
                  background-color: #e9e9e9;
                }
              `,
            ]}
          >
            <div className="table-question-num">
              {question?.questionSubText}
            </div>
            <div className="table-question">{question?.questionText}</div>
            <Tooltip
              color={"red"}
              placement="left"
              title={t(`exam.insung.tooltip.questionSet`)}
              trigger={"none"}
              open={arrayQuestionSetNo === numSetCheck?.QsetNum && i === 0}
              css={css`
                background-color: ${arrayQuestionSetNo ===
                  numSetCheck?.QsetNum && "#FFE5ED"};
              `}
            >
              {examData?.ipsativeYN === "Y" ? (
                <div className="table-question-far-near">
                  <div
                    className="far"
                    css={
                      examAnswerPart3 &&
                      examAnswerPart3[arrayQuestionSetNo - 1] &&
                      examAnswerPart3[arrayQuestionSetNo - 1][
                        (question?.questionNo - 1) % questionSetCount
                      ]?.questionMarkIpsative === 0
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      updateAnswerIpsative(
                        arrayQuestionSetNo,
                        question.questionNo,
                        0
                      );
                    }}
                  >
                    {language === "KOR" ? "멀" : "L"}
                  </div>
                  <div
                    className="near"
                    css={
                      examAnswerPart3 &&
                      examAnswerPart3[arrayQuestionSetNo - 1] &&
                      examAnswerPart3[arrayQuestionSetNo - 1][
                        (question.questionNo - 1) % questionSetCount
                      ]?.questionMarkIpsative === 2
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      updateAnswerIpsative(
                        arrayQuestionSetNo,
                        question.questionNo,
                        2
                      );
                    }}
                  >
                    {language === "KOR" ? "가" : "M"}
                  </div>
                </div>
              ) : null}
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

const totalWrapper = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: ${nowPageSetNum === 1 ? "16px" : "14px"};
  border-bottom: 2px solid #5b5b5b;
`;

const tableLeft = css`
  display: flex;
  flex-direction: row;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
`;
const tableRightWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const tableRight = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  height: ${nowPageSetNum === 1 ? "80px" : "50px"};

  &:not(:last-child) {
    border-bottom: 1px solid #5b5b5b;
  }
  .table-question-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
  }
  .table-question {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .table-question-standard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 380px;
    border-left: 1px solid #3b3b3b;
  }
  .table-question-far-near {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(30% + 11.5px);
    border-left: 1px solid #3b3b3b;
    overflow: hidden;
    height: 100%;
    .far {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
    .near {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
  }
`;

const tableNum = () => css`
  width: 100%;
  height: 100%;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
`;

const checkBtn = () => css`
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 50%;
  height: 100%;

  &:active {
    box-shadow: 0 0 0 3px #fefefe inset;
  }
  &:hover {
    cursor: pointer;
    background-color: #d2d3d4;
  }
`;

const checkedBtn = (theme) => css`
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  background-color: #3b3b3b;
  color: #fefefe;

  &:active {
    box-shadow: 0 0 0 3px #fefefe inset;
  }
  &:hover {
    background-color: #3b3b3b;
    color: #fff;
    cursor: pointer;
  }
`;
