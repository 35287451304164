/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { QRCodeCanvas } from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RecButton } from "../../components/system/button/RecButton";
import { PENDING_MODAL_ON } from "../../_reducers/modalAction";
import { MobileFaq } from "../../components/inquire/MobileFaq";
import { useState } from "react";
import { Inquire } from "../../components/inquire/Inquire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { baseURL } from "../../api/apiClient";

export const DeviceConnectQrPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { testerIdx, roomIdx } = useSelector((state) => state.userAction.data);
  const { showMobile } = useSelector((state) => state.showUtilsAction);
  const { attendanceYN } = useSelector((state) => state.examUserDataAction);
  const userData = useSelector((state) => state.examUserDataAction);
  const { completer } = useSelector((state) => state.userAction);
  const [faqModal, setFaqModal] = useState(false);
  const encodeURL = `roomIdx=${roomIdx}&testerIdx=${testerIdx}&completer=${completer}`;

  const nextPage = () => {
    if (completer) {
      if (attendanceYN === "N") {
        navigate("/test/idcard", { replace: true });
      } else {
        //신분증 등록이 된 상태이면서 대기 시간이 남은 경우(타임 테이블)
        if (userData?.status?.examStatus === 0) {
          navigate(`/test/timetable`, { replace: true });
        } else {
          //응시 도중 풀다가 튕겼을 경우 , 모바일 디바이스 연결 후 -> 감독관 확인중입니다 펜딩 -> 감독관 확인 시 검사 재시작
          dispatch({ type: PENDING_MODAL_ON });
          navigate("/test/maintest", { replace: true });
        }
      }
    } else {
      navigate(`/test/snapshot`, { replace: true });
    }
  };

  return (
    <div css={totalWrapper}>
      <div css={ContentBox}>
        <div css={item}>
          <div className="title">
            휴대전화를 와이파이로 연결 후, <br />
            비행기 모드로 설정해주세요.
          </div>
          <div className="content">
            <img
              src={`/images/wifi.png`}
              alt=""
              style={{ width: "180px", height: "128px" }}
            />
          </div>
        </div>
        <div css={item}>
          <div className="title">
            휴대전화를 사용하여 QR코드를 연결해주세요.
          </div>
          <div className="content">
            <QRCodeCanvas value={`${baseURL}mobile/device?` + encodeURL} />
          </div>
        </div>
        <div css={item}>
          <div className="title">
            휴대전화를 아래 그림을 참고하여 <br />
            충전이 가능한 상태로 고정하여 위치 시켜주세요.
          </div>
          <div className="img1" />
        </div>
        <div css={item}>
          <div className="title">
            연결된 휴대전화 화면에 아래 그림처럼 <br />
            PC와 응시자 상반신이 보여야 합니다.
          </div>
          <div className="img2" />
        </div>
        <div css={item}>
          <div className="title">
            화면 우측 상단에서 <br />
            휴대전화 화면 연결 상태를 확인해 주세요.
          </div>
          <div className="content">
            <img src="/images/mobile_example.svg" alt=""></img>
          </div>
        </div>
        <div css={item}>
          <div className="title">
            휴대전화 화면 연결이 실패할 경우 <br />
            아래 버튼을 눌러 조치사항을 확인해주세요.
          </div>
          <div onClick={() => setFaqModal(true)} className="content">
            <div className="title" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faCircleQuestion} className="icon" />
              모바일 연결 실패 안내
            </div>
            {faqModal && <MobileFaq setModal={setFaqModal} />}
          </div>
        </div>
      </div>

      <div css={buttonWrapper}>
        {showMobile && <RecButton onClick={nextPage}>다음</RecButton>}
      </div>
      {completer ? null : <Inquire />}
    </div>
  );
};

const totalWrapper = css`
  display: grid;
  grid-template-rows: 1fr 130px;
  flex-direction: column;
  height: 100%;
`;

const ContentBox = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  overflow: auto;
  flex-wrap: wrap;
  padding-top: 10px;
  gap: 10px;
  height: 100%;
  margin: 0 auto;
  margin-top: 30px;
  max-width: 1500px;
`;

const buttonWrapper = css`
  display: flex;
  bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
`;

const item = css`
  display: flex;
  flex-direction: column;
  background-color: #e9e9e9;
  width: 30%;
  height: 45%;
  min-height: 200px;
  min-width: 400px;
  max-width: 500px;
  img {
    width: 100%;
    height: 100%;
  }
  .icon {
    font-size: 20px;
    padding-right: 10px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
    padding-top: 10px;
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .img1 {
    width: 100%;
    height: 100%;
    background-image: url("/images/모바일감독-수정1.svg");
    background-position: center;
    background-size: cover;
  }
  .img2 {
    width: 100%;
    height: 100%;
    background-image: url("/images/모바일감독-수정2.svg");
    background-position: center;
    background-size: cover;
  }
  .img3 {
    width: 100%;
    height: 100%;
    background-image: url("/images/mobile_example.svg");
    background-position: center;
    background-size: cover;
  }
`;
