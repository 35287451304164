export const ID_CARD_EVENT = {
  registrationCard: {
    label: "주민등록증",
    type: "registrationCard",
  },
  passPort: {
    label: "여권",
    type: "passPort",
  },
  driverLicense: {
    label: "운전면허증",
    type: "driverLicense",
  },
  foreignRegistrationCard: {
    label: "외국인등록증",
    type: "foreignRegistrationCard",
  },
  etc: {
    label: "기타",
    type: "etc",
  },
};
