/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { NavBar } from "../components/system/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { KickModal } from "../components/modal/KickModal";
import { Route, Routes, useLocation } from "react-router-dom";
import { ConfirmModal } from "../components/modal/ConfirmModal";
import { PendingExamEnd } from "../components/pending/PendingExamEnd";
import { PendingDuplicateLogin } from "../components/pending/PendingDuplicateLogin";
import {
  AgreementPage,
  DeviceCheckPage,
  DeviceConnectQrPage,
  MaintestIdcardPage,
  PretestIdcardPage,
  MainTestPage,
  TimeTablePage,
  PreTestPage,
} from "../pages/index";
import { PendingTemplate } from "../components/base/PendingTemplate";
import { useEffect, useState } from "react";
import {
  checkDeviceHandler,
  checkNetworkHandler,
  sendSomeKeysHandler,
  sendSomeKeysUpHandler,
  touchHandler,
} from "../utils/alertChat";
import { socketId } from "../utils/socketId";
import { PendingWaiting } from "../components/pending/PendingWaiting";
import { PendingNetworkError } from "../components/pending/PendingNetworkError";
import { TIME_STOP } from "../_reducers/timeAction";
import { CloseButton } from "../components/system/button/CloseButton";
import { useRecord } from "../hooks/useRecord";
import { PretestSnapshotPage } from "./idcard/PretestSnapshotPage";
import { chatMessage } from "../adapters/chatMessage";
import { receiveStatus } from "../adapters/receiveStatus";
import { PendingDisconnectMobile } from "../components/pending/PendingDisconnectMobile";
import {
  DISCONNECT_NETWORK_MODAL,
  PENDING_MODAL_ON,
} from "../_reducers/modalAction";
import { useRoomConnection } from "../adapters/useRoomConnection";
import { PretestEndModal } from "../components/modal/PretestEndModal";
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";
import { PendingWebCamError } from "../components/pending/PendingWebCamError";

export const TotalTestPage = () => {
  const { t } = useTranslation();

  const {
    pendingModal,
    pendingMainExamEnd,
    kickModal,
    validationMessage,
    errorModal,
    alertModal,
    alertMessage,
    duplicateLoginModal,
    waitingModal,
    disconnectMobileModal,
    pendingPretestExamEnd,
    disconnectNetworkModal,
    disconnectWebcamModal,
  } = useSelector((state) => state.modalAction);
  const dispatch = useDispatch();

  const { sendToChat } = chatMessage();
  const { testerIdx, roomIdx } = useSelector((state) => state.userAction.data);
  const socketData = useSelector((state) => state.socketAction);
  const { myRoomId } = socketId(
    roomIdx,
    testerIdx,
    process.env.REACT_APP_CON_SITEID
  );
  const { stopRecordHandler } = useRecord();
  const { completer } = useSelector((state) => state.userAction);
  const [reconnectLoading, setReconnectLoading] = useState(false);
  const { sendToExamTopic } = useRoomConnection();
  const location = useLocation();
  const userData = useSelector((state) => state.examUserDataAction);
  const { isStop } = useSelector((state) => state.timeAction);
  const { mobileShareUseYN } = useSelector((state) => state.userAction.data);

  sessionStorage.setItem("warningCount", 0);

  receiveStatus();

  useEffect(() => {
    //맥인지 터치 가능한 PC인지
    checkDeviceHandler(sendToChat);
  }, [socketData?.viewerSocket?.viewerSocketId]);

  useEffect(() => {
    if (
      mobileShareUseYN === "Y" &&
      disconnectMobileModal &&
      completer &&
      userData?.status?.examStatus === 1
    ) {
      sendToChat(`<strong>[경고]\n 모바일 화면 공유 끊김 </strong>`, "chat");
      dispatch({ type: PENDING_MODAL_ON });
      dispatch({ type: TIME_STOP, data: true });
      sendToExamTopic(socketData?.viewerSocket?.viewerSocketId, "PAUSE");
    }
  }, [disconnectMobileModal, userData?.status?.examStatus]);

  useEffect(() => {
    let connectionTry;
    connectionTry = window.addEventListener("online", () => {
      dispatch({ type: DISCONNECT_NETWORK_MODAL, data: true });
      setTimeout(() => {
        dispatch({ type: DISCONNECT_NETWORK_MODAL, data: false });
        setReconnectLoading(false);
        if (
          userData?.status?.examStatus === 1 &&
          (location.pathname === "/test/device/mobile" ||
            location.pathname === "/test/device" ||
            pendingModal)
        ) {
          dispatch({ type: TIME_STOP, data: true });
        } else {
          dispatch({ type: TIME_STOP, data: false });
        }
      }, 3000);
    });

    return () => {
      setTimeout(connectionTry);
    };
  }, [dispatch, location, pendingModal, userData?.status?.examStatus]);

  useEffect(() => {
    /*연결->끊김으로 변경될 때 호출, 타이머 정지*/
    window.addEventListener("offline", () => {
      dispatch({ type: DISCONNECT_NETWORK_MODAL, data: true });
      //시험시작 전 (대기실) 에 있는 사람일 경우 status === 0 일 경우 타이머 멈추기 않기
      //타이머가 진행중일때 타이머 멈춤
      if (userData?.status?.examStatus === 1) {
        dispatch({ type: TIME_STOP, data: true });
      }
    });
  }, [dispatch, isStop, userData?.status?.examStatus]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = async (event) => {
    event.preventDefault();
    event.returnValue = "";

    // API 호출 코드
    await stopRecordHandler();
    delete event["returnValue"];
  };

  useEffect(() => {
    if (navigator.connection) {
      navigator.connection.onchange = () =>
        checkNetworkHandler(dispatch, sendToChat, i18n.language);
    }
    return () => {
      window.removeEventListener("onchange", () =>
        checkNetworkHandler(dispatch, sendToChat, i18n.language)
      );
    };
  }, [socketData?.viewerSocket?.viewerSocketId]);

  useEffect(() => {
    //우클릭 방지
    window.document.oncontextmenu = () => {
      return false;
    };

    //불필요한 입력 키 감지 시
    window.addEventListener("keydown", (e) => {
      sendSomeKeysHandler(
        e,
        dispatch,
        myRoomId,
        testerIdx,
        completer,
        i18n.language
      );
    });
    window.addEventListener("keyup", (e) => {
      sendSomeKeysUpHandler(
        e,
        dispatch,
        myRoomId,
        testerIdx,
        completer,
        i18n.language
      );
    });
    //터치 스크린 감지 시
    window.addEventListener("touchend", () => {
      touchHandler(dispatch, myRoomId, testerIdx);
    });

    window.addEventListener("touchmove", () => {
      touchHandler(dispatch, myRoomId, testerIdx);
    });

    return () => {
      window.removeEventListener("keydown", (e) =>
        sendSomeKeysHandler(e, dispatch, myRoomId, testerIdx, i18n.language)
      );
      window.removeEventListener("keyup", (e) =>
        sendSomeKeysUpHandler(e, dispatch, myRoomId, testerIdx, i18n.language)
      );
      window.removeEventListener("touchend", () =>
        touchHandler(dispatch, myRoomId, testerIdx)
      );
      window.removeEventListener("touchmove", () =>
        touchHandler(dispatch, myRoomId, testerIdx)
      );
    };
  }, []);

  return (
    <>
      <NavBar />
      <CloseButton />
      <div style={{ overflowY: "auto" }}>
        {mobileShareUseYN === "Y" && disconnectMobileModal && (
          <PendingDisconnectMobile />
        )}
        {kickModal && <KickModal />}
        {pendingModal && (
          <PendingTemplate
            zIndex={999}
            title={t(`pending.pause.title`)}
            loading={true}
          >
            <div css={text}>{t(`pending.pause.text`)}</div>
          </PendingTemplate>
        )}
        {alertModal ? <ConfirmModal>{alertMessage}</ConfirmModal> : null}
        {errorModal ? <ConfirmModal>{validationMessage}</ConfirmModal> : null}
        {pendingPretestExamEnd && <PretestEndModal />}
        {pendingMainExamEnd ? <PendingExamEnd /> : null}
        {duplicateLoginModal ? <PendingDuplicateLogin /> : null}
        {waitingModal === true && <PendingWaiting />}
        {disconnectNetworkModal && (
          <PendingNetworkError loading={reconnectLoading} />
        )}
        {disconnectWebcamModal && <PendingWebCamError />}
        <div css={testWrapper}>
          <Routes>
            <Route path="info" element={<AgreementPage />} />
            <Route path="device" element={<DeviceCheckPage />} />
            <Route path="device/mobile" element={<DeviceConnectQrPage />} />
            <Route path="idcard" element={<MaintestIdcardPage />} />
            <Route path="snapshot" element={<PretestSnapshotPage />} />
            <Route path="pretest/idcard" element={<PretestIdcardPage />} />
            <Route path="timetable" element={<TimeTablePage />} />
            <Route path="maintest" element={<MainTestPage />} />
            <Route path="pretest" element={<PreTestPage />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const testWrapper = css`
  position: absolute;
  top: 90px;
  width: 100%;
  height: calc(100% - 90px);
  overflow-x: hidden;
`;
const text = css`
  padding: 15px 0;
  line-height: 24px;
  white-space: pre;
  margin-top: 20px;
`;
