/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

export const MobileDisconnectPage = () => {
  const location = useLocation();
  const end = location.pathname === "/mobile/disconnect/end";
  return (
    <div css={totalWrapper}>
      <div css={textWrapper({ end })}>
        {end ? (
          <FontAwesomeIcon icon={faCircleCheck} css={checkIcon} />
        ) : (
          <FontAwesomeIcon icon={faCircleExclamation} css={warningIcon} />
        )}
        {end ? (
          <>
            <div className="text-title">연결 종료</div>
            <div className="sub-text">
              검사가 정상적으로 완료되었습니다.
              <br />
              종료 버튼을 눌러주세요.
            </div>
            <div className="button-wrapper">
              <button
                css={closeButton}
                onClick={() => window.open("about:blank", "_self").self.close()}
              >
                종료
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="text-title">연결 오류</div>
            <div className="sub-text">
              휴대전화 화면 연결이 끊겼습니다.
              <br />
              QR코드를 통해 다시 연결해 주세요.
            </div>
            <div className="button-wrapper">
              <button
                css={closeButton}
                onClick={() => window.open("about:blank", "_self").self.close()}
              >
                종료
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const totalWrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const textWrapper = ({ end }) => css`
  width: 80%;
  height: 80%;
  background-color: ${end ? "rgba(0, 97, 24, 10%)" : "#e9e9e9"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;

  .text-title {
    font-weight: 700;
    font-size: 18px;
  }

  .sub-text {
    padding-top: 30px;
    font-size: 13px;
  }

  .button-wrapper {
    padding-top: 30px;
  }
`;

const checkIcon = css`
  font-size: 40px;
  padding: 20px;
  color: green;
`;

const warningIcon = css`
  font-size: 40px;
  padding: 20px;
  color: #aa0000;
`;

const closeButton = css`
  width: 80px;
  background-color: #9999;
  border-radius: 90px;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
`;
