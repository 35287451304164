/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNowExamData } from "../../hooks/useNowExamData";

export const Memo = ({ isMemoOpen, memo, setMemo, setCalcActive }) => {
  const nowExamData = useNowExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const { completer } = useSelector((state) => state.userAction);
  const memoKey = `${nowExamData?.exam ? "main-memo" : "example-memo"}-${
    completer ? nowExamData?.testerStatus?.examSubNo : nowExamData?.examSubNo
  }-${
    completer
      ? nowExamData?.exam
        ? nowExamData?.testerStatus?.savePage
        : exampleIndex
      : nowExamData.pageNo
  }`;

  const saveMemo = (memo) => {
    sessionStorage.setItem(memoKey, memo);
  };

  const onMemolHandler = useCallback(
    (event) => {
      setMemo(event.target.value);
    },
    [nowExamData]
  );

  return (
    <div>
      <textarea
        tabIndex={-1}
        css={memoWrapper}
        maxLength={1000}
        style={{
          height: 270,
          display: isMemoOpen ? "" : "none",
        }}
        onChange={onMemolHandler}
        value={memo || ""}
        onFocus={() => setCalcActive(false)}
        onBlur={() => {
          saveMemo(memo);
        }}
      />
    </div>
  );
};

const memoWrapper = css`
  width: calc(100% - 24px);
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  padding: 10px;
  line-height: 1.3;
  transition: height 0.3s ease-in-out;
  word-break: break-all;
  border-radius: 3px;
  resize: none;
  :hover {
    border: 1px solid #777;
  }
  :focus {
    border: 1px solid #777;
  }
`;
