import produce from "immer";
import { PURGE } from "redux-persist";

//따로 관리
export const MAIN_EXAM_REQUEST = "MAIN_EXAM_REQUEST";

export const SET_EXAMPLE_PAGE = "SET_EXAMPLE_PAGE";
export const MAIN_EXAM_JUKSUNG_MARK_UPDATE = "MAIN_EXAM_JUKSUNG_MARK_UPDATE";
export const MAIN_EXAM_LOADING = "MAIN_EXAM_LOADING";

const initialState = {
  data: {},
  exampleIndex: 0,
  loading: false,
};

const mainExamReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      //데이터 관리 만 하는 기능
      case MAIN_EXAM_REQUEST:
        draft.data = action.data;
        break;
      case SET_EXAMPLE_PAGE:
        draft.exampleIndex = action.data;
        break;
      case MAIN_EXAM_LOADING:
        draft.loading = action.data;
        break;
      case MAIN_EXAM_JUKSUNG_MARK_UPDATE:
        draft.data.exam.questionInfoData.questionList[0].questionMark =
          action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        break;
    }
  });
};

export default mainExamReducer;
