//전각 -> 반각
export const toHalfWidth = (str) => {
  return str.replace(/[\uFF01-\uFF5E]/g, function (char) {
    const c = char.charCodeAt(0) - 0xff00 + 0x20;
    return String.fromCharCode(c);
  });
};

//전각 확인
export const isFullWidth = (char) => {
  const fullWidthRegex = /[\uFF01-\uFF5E]/; // 전각 문자 범위 정규식
  return fullWidthRegex.test(char);
};

export const validationPhoneCheck = (number) => {
  try {
    //전각 일 경우
    if (isFullWidth(number)) {
      let result = toHalfWidth(number);
      number = result;
    }
    return number
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  } catch (err) {
    console.log(err);
    return number;
  }
};
