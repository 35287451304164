import {
  MAIN_EXAM_END_MODAL_ON,
  WAITING_MODAL,
} from "../_reducers/modalAction";
import { TIME_STOP } from "../_reducers/timeAction";

export const testCompletedModal = async (dispatch, waitingModal) => {
  dispatch({ type: TIME_STOP, data: true });
  //퇴장 대기 상태인 경우
  if (waitingModal === "waiting") {
    //퇴장 대기 모달 띄우기
    await dispatch({ type: WAITING_MODAL, data: true });
  }
  //퇴장 대기가 아닐 경우 정상 종료
  else {
    await dispatch({ type: TIME_STOP, data: true });
    await dispatch({ type: MAIN_EXAM_END_MODAL_ON });
  }
};
