/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CloseIcon = ({ onClick, fontSize }) => {
  return (
    <div onClick={onClick} css={closeWrap({ fontSize })}>
      <FontAwesomeIcon icon={faClose} />
    </div>
  );
};

const closeWrap = ({ fontSize }) => css`
  position: absolute;
  z-index: 10000;
  cursor: pointer;
  font-size: ${fontSize};
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  padding-right: 10px;
  :hover {
    svg {
      color: #ddd;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 1));
    }
  }
`;
