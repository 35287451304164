/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { PendingTemplate } from "../base/PendingTemplate";
import { BasicButton } from "../system/button/BasicButton";
import { useLogout } from "../../hooks/useLogout";
import { useTranslation } from "react-i18next";

export const KickModal = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const { kickReason } = useSelector((state) => state.modalAction);

  const nextLogoutPage = () => {
    logout();
  };

  return (
    <PendingTemplate title={t(`modal.kick.title`)}>
      <p css={reason}>{kickReason}</p>
      <BasicButton
        onClick={nextLogoutPage}
        label={t(`exam.button.confirm`)}
        wid="s"
        hei="s"
      />
    </PendingTemplate>
  );
};

const reason = css`
  font-weight: bold;
  max-width: 380px;
  padding: 10px 0 20px;
`;
