/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const Volume = ({ vol }) => {
  return (
    <div css={soundMeter({ vol })}>
      <div className="volume"></div>
    </div>
  );
};

const soundMeter = ({ vol }) => css`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 5px;
  z-index: 2;
  height: ${vol}%;
  transition: 0.3s ease-out;
  max-height: 100px;
  background: rgb(255, 125, 0);
  opacity: 0.6;
  .volume {
    position: absolute;
    bottom: 0px;
    background: #ff7d00;
    width: 100%;
    z-index: 3;
  }
`;
