/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

export const Dropzone = ({ setDropzoneImg }) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setDropzoneImg(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: false,
  });

  return (
    <section css={fileUploadContents}>
      <div {...getRootProps()} css={uploadZone}>
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faFolderOpen} />
        {t(`idCard.pretest.button.uploadIdCard`)}
      </div>
    </section>
  );
};

const fileUploadContents = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  width: 400px;
  height: 300px;
  border: 1px solid #131313;
`;

const uploadZone = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 48px;
    margin-top: 6px;
    cursor: pointer;
  }
  p {
    font-size: 16px;
  }
`;
