/** @jsxImportSource @emotion/react */
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";

export const FaqListItem = ({ faqListItem, checked, onClick }) => {
  return (
    <div css={faqItemWrapper({ checked })} onClick={onClick}>
      <div className="item-contents">
        <div className="item-question">
          <div
            className="q-icon"
            style={{
              backgroundColor: checked ? "#222" : "rgba(2, 32, 71, 0.07)",
              color: checked ? "white" : "black",
            }}
          >
            Q
          </div>
          &nbsp; {faqListItem.question}
        </div>
        <span>{checked ? <UpOutlined /> : <DownOutlined />}</span>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: `${faqListItem.answer}`,
        }}
        className={checked ? "faq-show" : "faq-hide"}
      ></div>
    </div>
  );
};

const faqItemWrapper = ({ checked }) => css`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  border-bottom: 1px rgba(2, 32, 71, 0.2) solid;
  background-color: ${checked && "rgba(2, 32, 71, 0.05)"};
  &:hover {
    background-color: rgba(2, 32, 71, 0.05);
  }
  cursor: pointer;
  .item-title {
    display: flex;
    font-size: 20px;
    padding-left: 5px;
  }
  .item-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0 15px 0;
    padding: 0 10px;
    font-weight: 700;
    .item-question {
      display: flex;
      align-items: baseline;
      word-break: keep-all;
    }
    .q-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 100%;
      min-width: 30px;
      height: 30px;
      padding-bottom: 3px;
    }
    span {
      padding: 0 10px;
    }
  }
  .faq-hide {
    display: none;
    height: 0px;
    transition: all 0.5s;
    padding-left: 20px;
    padding-right: 20px;
  }
  .faq-show {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 14px;
    padding-left: 50px;
    padding-bottom: 10px;
    word-break: keep-all;
  }
`;
