/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNowExamData } from "../../../hooks/useNowExamData";
import { updateApi } from "../../../api/update/updateApi";

export const ReplyAnswerForm = ({
  markInfosArray,
  setMarkInfosArray,
  explanation,
  maxLength = 100000000,
  limit = false,
  tempStorage = true,
}) => {
  const [text, setText] = useState(null);
  const { completer } = useSelector((state) => state.userAction);
  const nowExamData = useNowExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const [nowSaveTime, setNowSaveTime] = useState("");
  const textRef = useRef();
  const { testerIdx } = useSelector((state) => state.userAction.data);
  const { pendingModal } = useSelector((state) => state.modalAction);

  const replySessionDataKey = `${
    completer ? nowExamData?.testerStatus?.examNo : nowExamData?.examNo
  }-${
    completer ? nowExamData?.testerStatus?.examSubNo : nowExamData?.examSubNo
  }-${nowExamData?.example ? exampleIndex + 1 : nowExamData.pageNo}`;

  const getReplySessionData = () => {
    return sessionStorage.getItem(replySessionDataKey);
  };

  const saveReplySessionData = (text) => {
    sessionStorage.setItem(replySessionDataKey, text);
  };

  const removeReplySessionData = () => {
    sessionStorage.removeItem(replySessionDataKey);
  };

  useEffect(() => {
    let tmpSaveInterval;
    let tmpSaveTimeOutInterval;
    if (
      !pendingModal &&
      nowExamData?.exam &&
      nowExamData?.exam?.questionInfoData?.questionList[0]?.questionEx
        .length === 0
    ) {
      setNowSaveTime("");
      setTimeout(() => {
        //본검사 일때
        tmpSaveTimeOutInterval = clearInterval(tmpSaveInterval);
        tmpSaveInterval = setInterval(() => {
          updateApi
            .updateJuksungReplyAnswer(
              nowExamData,
              textRef.current.value.replaceAll(/\p{Emoji_Presentation}/gu, "")
            )
            .then((response) => {
              setNowSaveTime(
                "자동 저장 완료" + new Date().toLocaleTimeString()
              );
            });
        }, 60000);
      }, [(testerIdx % 59) * 1000]);
    } else {
      clearTimeout(tmpSaveTimeOutInterval);
      clearInterval(tmpSaveInterval);
    }
    setNowSaveTime("");
    return () => {
      clearInterval(tmpSaveInterval);
    };
  }, [nowExamData?.testerStatus?.examSubStatus, pendingModal]);

  const onBlurHandler = () => {
    saveReplySessionData(text);
    setNowSaveTime("답안 저장 완료 " + new Date().toLocaleTimeString());
  };
  //본검사 본 문항에 questionMemo가 있을 경우 보여주기
  useEffect(() => {
    if (nowExamData?.exam) {
      const saveReplyText =
        nowExamData?.exam?.questionInfoData?.questionList[0]?.questionReply;
      if (!saveReplyText) {
        setText("");
      } else {
        setText(
          nowExamData?.exam?.questionInfoData?.questionList[0]?.questionReply
        );
      }
    } else {
      if (getReplySessionData) {
        setText(getReplySessionData);
        checkMarkInfosArrayAndUpdateHandler(getReplySessionData, exampleIndex);
      } else {
        setText("");
      }
    }
  }, [nowExamData, exampleIndex]);

  const updateSetMarkInfoArrayTrueOrFalse = (updateIndex, boolean) => {
    setMarkInfosArray(
      markInfosArray?.map((data, index) =>
        index === updateIndex ? boolean : data
      )
    );
  };

  const checkMarkInfosArrayAndUpdateHandler = (text, updateIndex) => {
    if (text == null || text === "") {
      updateSetMarkInfoArrayTrueOrFalse(updateIndex, false);
      if (!completer || nowExamData?.example) {
        removeReplySessionData();
      }
    } else {
      updateSetMarkInfoArrayTrueOrFalse(updateIndex, true);
    }
  };
  const onChangeQuestionReplyAnswer = (text) => {
    //본 검사 본 문항 일 경우
    if (completer && nowExamData?.testerStatus?.examSubStatus === 1) {
      checkMarkInfosArrayAndUpdateHandler(
        text,
        nowExamData?.testerStatus?.savePage - 1
      );
    }
    //사전점검, 본검사 예제 일 경우
    else {
      saveReplySessionData(text);
      checkMarkInfosArrayAndUpdateHandler(text, nowExamData?.pageNo - 1);
    }
  };
  return (
    <>
      <div css={buttonBox}>
        <span>작성한 내용은 1분 간격으로 자동 저장됩니다.</span>
        <button
          className="save-button"
          onClick={() => {
            if (nowExamData?.exam) {
              updateApi
                .updateJuksungReplyAnswer(
                  nowExamData,
                  text.replaceAll(/\p{Emoji_Presentation}/gu, "")
                )
                .then((response) => {
                  setNowSaveTime(
                    "답안 저장 완료 " + new Date().toLocaleTimeString()
                  );
                });
            } else {
              onBlurHandler();
            }
          }}
        >
          저장
        </button>
      </div>
      <div css={textAreaBox}>
        {explanation && <span className="textarea-title">{explanation}</span>}
        <textarea
          onChange={(e) => {
            setText(e.target.value);
            onChangeQuestionReplyAnswer(e.target.value);
          }}
          autoFocus
          ref={textRef}
          value={text || ""}
          maxLength={maxLength}
        />
        {tempStorage && <div css={tmpBox}>{nowSaveTime}</div>}
        {limit && (
          <div css={tmpBox}>
            {text?.length} / {maxLength}
          </div>
        )}
      </div>
    </>
  );
};

const textAreaBox = css`
  display: flex;
  z-index: 2;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  .textarea-title {
    font-weight: 600;
    font-size: 17px;
    padding: 10px 0 10px 0;
  }
  textarea {
    padding: 10px;
    border: 1px solid rgba(149, 149, 149, 0.49);
    border-radius: 5px;
    width: 100%;
    height: 150px;
    resize: none;
    &:focus {
      border: 2px solid rgba(149, 149, 149, 0.49);
    }
  }
  .textarea-submit-button {
    position: absolute;
    margin-top: 20px;
    right: 0;
    top: 135px;
  }
`;

const tmpBox = css`
  color: rgba(61, 61, 61, 0.49);
  display: flex;
  justify-content: flex-end;
`;

const buttonBox = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 5px;
  span {
    font-size: 12px;
    color: #999;
    padding-right: 10px;
  }
  .save-button {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 30px;
    border-radius: 6px;
    border: 2px solid #999;
    background-color: white;
    text-align: center;
  }
`;
