/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const CheckToggleButton = ({ label, onClick, checked }) => {
  return (
    <div css={totalWrapper({ checked })} onClick={onClick}>
      <div className="button-wrapper">
        <span>{label}</span>
        <div css={containerStyles}>
          <label css={checkboxContainerStyles}>
            <span css={checkboxMarkStyles({ checked })} />
          </label>
        </div>
      </div>
    </div>
  );
};

const totalWrapper = ({ checked }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  padding: 0 40px 0 80px;
  background-color: ${checked ? "#676666" : " #ddd"};
  color: ${checked ? "white" : " black"};
  cursor: pointer;

  & .button-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
`;

const containerStyles = css`
  font-size: 15px;
  display: flex;
  max-height: 47px;
`;

const checkboxContainerStyles = css`
  font-size: 20px;
  height: 40px;
  margin-top: 7px;
  margin-bottom: 10px;
  padding-left: 40px;
  cursor: pointer;
  position: relative;
  user-select: none;
`;

const checkboxMarkStyles = ({ checked }) => css`
  width: 27px;
  height: 27px;
  top: 2px;
  left: 0;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;

  &:after {
    display: none;
    content: "";
    position: relative;
  }

  ${checked &&
  `
    &:after {
      display: block;
    }
  `}

  &:after {
    width: 10px;
    height: 19px;
    top: 1px;
    left: 8px;
    border-style: solid;
    border-color: #145bff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;
