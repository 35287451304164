/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useState } from "react";
import { useInterval } from "../../../hooks/useInterval";

export const AuthTimer = ({ inputReset, isPhoneSended }) => {
  const [minute, setMinute] = useState(2);
  const [second, setSecond] = useState(59);

  useInterval(
    () => {
      if (second > 0) {
        setSecond((sec) => sec - 1);
      }
      if (second === 0) {
        if (minute === 0) {
          inputReset();
          setMinute(2);
          setSecond(59);
        } else {
          setMinute((min) => min - 1);
          setSecond(59);
        }
      }
    },
    isPhoneSended ? 1000 : null
  );

  return (
    <div css={countWrapper}>
      {isPhoneSended && (minute < 10 ? `0${minute}:` : `${minute} :`)}
      {isPhoneSended && (second < 10 ? `0${second}` : second)}
    </div>
  );
};

const countWrapper = css`
  position: absolute;
  top: 30px;
  right: 24px;
  font-size: 15px;
`;
