import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  aiModel: null,
};
export const SET_AI_MODEL = "SET_AI_MODEL";

const aiReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_AI_MODEL:
        draft.aiModel = action.data;
        break;
      default:
        return;
      case PURGE: {
        return initialState;
      }
    }
  });

export default aiReducer;
