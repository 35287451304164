/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useRef, useEffect, useState } from "react";
import { socketId } from "../../utils/socketId";
import VideoStreamMerger from "video-stream-merger";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_CHAT, SHOW_MOBILE_CAM } from "../../_reducers/showUtilsAction";
import { useCreateMediaStream } from "../../hooks/useCreateMediaStream";
import { RTCConfig } from "../../utils/rtcConfig";
import {
  SOCKET_MOBILE_CONNECTIONS,
  SOCKET_VIEWER_INFO,
} from "../../_reducers/socketAction";
import { audioContext, audioFrequency } from "../../utils/audioContext";
import { useRecord } from "../../hooks/useRecord";
import { QRCodeCanvas } from "qrcode.react";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import {
  DISCONNECT_MOBILE_MODAL,
  DISCONNECT_WEBCAM_MODAL,
  PENDING_MODAL_ON,
} from "../../_reducers/modalAction";
import { Step } from "../system/Step";
import { Cam } from "./cam/Cam";
import { Chat } from "./Chat";
import MainTimer from "./timer/MainTimer";
import {
  SET_DEVICE_CAM_STREAM,
  SET_DEVICE_SCREEN_STREAM,
} from "../../_reducers/deviceAction";
import { useNowExamData } from "../../hooks/useNowExamData";
import { ConnectBadge } from "./cam/badge/ConnectBadge";
import { TIME_STOP } from "../../_reducers/timeAction";
import { SOCKET_EVENT } from "../../adapters/socket.enum";
import { useLocation, useNavigate } from "react-router-dom";
import "@tensorflow/tfjs";
import { updateApi } from "../../api/update/updateApi";
import { chatMessage } from "../../adapters/chatMessage";
import { useTranslation } from "react-i18next";
import { baseURL } from "../../api/apiClient";
import { awsApi } from "../../api/aws/awsApi";
import axios from "axios";
import { getFormattedTimestamp } from "../../utils/getDateFormat";
import { convertURLtoFile } from "../../utils/convert";

let my_video_stream = null;
let resize_video_stream = null;

// 합성 된 stream을 담을 변수
let my_mix_stream;

// main_display 초기설정
let main_display = "CAMERA";

// 현재 비디오 화질(해상도 계산)
let my_video_quality = 2;

// 비디오 크기를 담을 변수
let video_width;
let video_height;

// 스크린 크기를 담을 변수
let screen_width;
let screen_height;

let peerConnections = [];
let peerSocketId = [];

export const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const { sendToChat } = chatMessage();
  const { t } = useTranslation();
  const { isStop } = useSelector((state) => state.timeAction);

  const { aiModel } = useSelector((state) => state.aiAction);
  const { videoStream, screenStream } = useSelector(
    (state) => state.deviceAction
  );
  const [vol, setVol] = useState(0); //마이크 볼륨
  const [mobileQr, setMobileQr] = useState(false);
  const videoRef = useRef(null);
  const screenRef = useRef(null);
  const mobileRef = useRef(null);
  const viewerRef = useRef(null);
  const isBadQuality = useRef(null);

  const canvasRef = useRef(null);
  const [initDevice, setInitDevice] = useState([]);

  const { changeDeviceStartRecord } = useRecord();
  const { getWebcam, getScreen } = useCreateMediaStream();
  const { socket, joinRoom, sendToExamTopic } = useRoomConnection();
  const { showCam, showScreen, showMobile } = useSelector(
    (state) => state.showUtilsAction
  );
  const {
    testerIdx,
    roomIdx,
    mobileShareUseYN,
    screenShareUseYN,
    roomUseYN,
    groupIdx,
  } = useSelector((state) => state.userAction.data);
  const { completer, networkSpeed } = useSelector((state) => state.userAction);
  const socketData = useSelector((state) => state.socketAction);
  const loginStatusData = useSelector((state) => state.examUserDataAction);
  const nowExamData = useNowExamData();
  const encodeURL = `roomIdx=${roomIdx}&testerIdx=${testerIdx}&completer=${completer}`;
  const location = useLocation();
  const { myPeerId, con_my_peer_type } = socketId(
    roomIdx,
    testerIdx,
    process.env.REACT_APP_CON_SITEID
  );
  const timeOutDisconnected = useRef();

  let aiTimer;
  const url = location.pathname;
  const [videoReady, setVideoReady] = useState(false);
  const [isCamStatus, setIsCamStatus] = useState(true);

  const examDataRef = useRef();

  const conditionAiLogChat = useRef({
    noPersonCount: 0,
    manyPersonCount: 0,
    highDecibelCount: 0,
    rowDecibelCount: 0,
    objectCount: 0,
    warningKeyCount: 0,
  });

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      setInitDevice(devices);
    });
  }, []);

  //감독관 채팅만 적용하는 걸로
  //적발 1회는 바로 채팅 전송
  //적발 2회부터는 20회 쌓였을 때 한번 보냄
  const sendToChatAndAILogAndSnapShot = (count, message, aiTitle, object) => {
    if (
      examDataRef?.current.isExample ||
      !examDataRef?.current.examStatus ||
      loginStatusData?.status?.examStatus === 2 ||
      url !== "/test/maintest"
    )
      return;
    if (aiTitle === "특수키") {
      if (count % 10 === 0) {
        sendToAiLogWithSnapShot(aiTitle, object ? object : "");
      }
    } else {
      if (count === 1 || count % 20 === 0) {
        if (roomUseYN === "Y") {
          sendToChat(message, "chat");
        }
        sendToAiLogWithSnapShot(aiTitle, object ? object : "");
      } else {
        sendaiLog(aiTitle, object ? object : "", "");
      }
    }
  };

  const captureHandler = async (canvasRef, videoRef) => {
    if (!canvasRef?.current || !videoRef?.current) return null;

    const canvas = canvasRef.current;
    canvas.width = 400;
    canvas.height = 300;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, 400, 300);

    const fileName = `[AI]CAPTURE_${getFormattedTimestamp()}.jpg`;
    const captureImageFile = await convertURLtoFile(canvas.toDataURL());

    try {
      const res = await awsApi.preSignedUrl(fileName, "CAPTURE");
      const preSignedUrl = res.data.data.preSignedUrl;
      const uploadUrl = res.data.data.uploadUrl;

      if (preSignedUrl) {
        await axios.put(preSignedUrl, captureImageFile);
        return uploadUrl;
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }

    return null;
  };

  const sendToAiLogWithSnapShot = async (aiTitle, object) => {
    const captureUrl = await captureHandler(canvasRef, videoRef);
    sendaiLog(aiTitle, object ? object : "", captureUrl);
  };

  useEffect(() => {
    examDataRef.current = {
      isExample: !!nowExamData?.example,
      examStatus: nowExamData?.testerStatus?.examStatus,
      examIdx: nowExamData?.testerStatus?.examIdx,
      examNo: nowExamData?.testerStatus?.examNo,
      examSubNo: nowExamData?.testerStatus?.examSubNo,
      examSubIdx: nowExamData?.testerStatus?.examSubIdx,
      savePage: nowExamData?.testerStatus?.savePage,
    };
  }, [nowExamData]);

  //응시자 본인 사운드 미터
  const soundMeter = (stream) => {
    let myInterval;
    const { analyser, bufferLength, dataArray } = audioContext(stream);
    myInterval = setInterval(() => {
      analyser.getByteFrequencyData(dataArray);
      const vol = audioFrequency(dataArray, bufferLength);
      setVol(Math.floor((vol / 256) * 300));
    }, 30);

    return () => {
      clearInterval(myInterval);
    };
  };

  const noPersonCount = useRef(0);
  const manyPersonCount = useRef(0);
  const highDecibelCount = useRef(0);
  const rowDecibelCount = useRef(0);
  const objectCount = useRef(0);

  const sendaiLog = (aiTitle, aiMessage, captureURL) => {
    if (
      examDataRef?.current.isExample ||
      !examDataRef?.current.examStatus ||
      loginStatusData?.status?.examStatus === 2 ||
      url !== "/test/maintest"
    )
      return;

    if (examDataRef.current) {
      updateApi.updateAiLog({
        aiTitle,
        aiMessage,
        captureURL,
        examIdx: examDataRef?.current.examIdx,
        examNo: examDataRef?.current.examNo,
        examSubNo: examDataRef?.current.examSubNo,
        examSubIdx: examDataRef?.current.examSubIdx,
        pageNo: examDataRef?.current.savePage,
        testerIdx,
      });
    }
  };

  useEffect(() => {
    //소음 볼륨 설정
    highDecibelCount.current = vol >= 50 ? highDecibelCount.current + 1 : 0;
    rowDecibelCount.current = vol <= 10 ? rowDecibelCount.current + 1 : 0;

    //무소음 감지 (166 -> 5초)
    if (rowDecibelCount.current >= 166 * 12) {
      if (!examDataRef?.current.isExample && examDataRef?.current.examStatus) {
        conditionAiLogChat.current.rowDecibelCount += 1;
      }

      sendToChatAndAILogAndSnapShot(
        conditionAiLogChat.current.rowDecibelCount,
        `<strong>[AI감독] </strong><br/>응시자의 마이크가 작동하지 않습니다.`,
        "무소음 감지"
      );
      rowDecibelCount.current = 0;
    }
    //소음 감지
    if (highDecibelCount.current >= 166) {
      if (!examDataRef?.current.isExample && examDataRef?.current.examStatus) {
        conditionAiLogChat.current.highDecibelCount += 1;
      }
      sendToChatAndAILogAndSnapShot(
        conditionAiLogChat.current.highDecibelCount,
        `<strong>[AI감독] </strong><br/>일정 시간 이상 소음이 확인되었습니다.`,
        "소음 감지"
      );
      highDecibelCount.current = 0;
    }
  }, [
    vol,
    socketData?.viewerSocket?.viewerSocketId,
    loginStatusData?.status?.examStatus,
    nowExamData?.testerStatus?.examSubStatus,
  ]);

  const warningCount = parseInt(sessionStorage.getItem("warningCount"));

  useEffect(() => {
    if (warningCount >= 10) {
      sendToChatAndAILogAndSnapShot(
        sessionStorage.getItem("warningCount"),
        `<strong>[AI감독] </strong><br/>특수키 입력`,
        "특수키"
      );
      sessionStorage.setItem("warningCount", 0);
    }
  }, [warningCount]);

  useEffect(() => {
    if (videoReady && aiModel && showCam) {
      detectAiModel();
    }

    return () => {
      clearTimeout(aiTimer);
    };
  }, [
    aiModel,
    showCam,
    videoReady,
    socketData?.viewerSocket?.viewerSocketId,
    nowExamData?.testerStatus?.examSubStatus,
  ]);

  const detectAiModel = async () => {
    if (aiModel && videoRef.current) {
      const predictions = await aiModel.detect(videoRef.current);
      const personArray = predictions.filter(
        (data) => data.class === "person" && data.score >= 0.3
      );

      const objectArray = predictions.filter(
        (data) =>
          data.class !== "person" &&
          data.score >= 0.3 &&
          (data.class === "bottle" ||
            data.class === "cup" ||
            data.class === "tv" ||
            data.class === "laptop" ||
            data.class === "cell phone" ||
            data.class === "keyboard")
      );

      objectCount.current =
        objectArray.length >= 1 ? objectCount.current + 1 : 0;
      noPersonCount.current =
        personArray.length === 0 ? noPersonCount.current + 1 : 0;
      manyPersonCount.current =
        personArray.length > 1 ? manyPersonCount.current + 1 : 0;

      //응시자 미감지
      if (noPersonCount.current >= 5) {
        if (
          !examDataRef?.current.isExample &&
          examDataRef?.current.examStatus
        ) {
          conditionAiLogChat.current.noPersonCount += 1;
        }
        console.log(
          "응시자 미감지 카운트",
          conditionAiLogChat.current.noPersonCount
        );
        sendToChatAndAILogAndSnapShot(
          conditionAiLogChat.current.noPersonCount,
          `<strong>[AI감독] </strong><br/>응시자가 화면에서 확인되지 않습니다.`,
          "응시자 미감지"
        );
        noPersonCount.current = 0;
      }
      //타인 감지 조건
      if (manyPersonCount.current >= 1) {
        if (
          !examDataRef?.current.isExample &&
          examDataRef?.current.examStatus
        ) {
          conditionAiLogChat.current.manyPersonCount += 1;
        }
        console.log(
          "타인 감지 카운트",
          conditionAiLogChat.current.manyPersonCount
        );
        sendToChatAndAILogAndSnapShot(
          conditionAiLogChat.current.manyPersonCount,
          `<strong>[AI감독] </strong><br/>응시자 외 타인이 확인되었습니다.`,
          "타인 감지"
        );

        manyPersonCount.current = 0;
      }
      //의심 사물 감지
      if (objectCount.current >= 2) {
        if (
          !examDataRef?.current.isExample &&
          examDataRef?.current.examStatus
        ) {
          conditionAiLogChat.current.objectCount += 1;
        }
        console.log(
          "의심행동감지 카운트",
          conditionAiLogChat.current.objectCount
        );
        let object = "";
        objectArray.map((data) => (object += data.class + " "));

        sendToChatAndAILogAndSnapShot(
          conditionAiLogChat.current.objectCount,
          `<strong>[AI감독] </strong><br/>응시자의 의심 행동 내용이 확인되었습니다.`,
          "의심 행동 감지",
          object
        );
        objectCount.current = 0;
      }

      aiTimer = setTimeout(() => {
        detectAiModel();
      }, [1000]);
    }
  };

  const mergeVideoStream = async (videoStream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = videoStream;

      my_video_stream = videoStream;
      await new Promise(
        (resolve) => (videoRef.current.onloadedmetadata = resolve)
      );
      video_width = videoRef.current.videoWidth;
      video_height = videoRef.current.videoHeight;

      //사운드 미터
      soundMeter(videoStream);
      if (roomUseYN === "Y") {
        joinRoom();
      }
      if (!completer) return;
      //Create a new video merger.
      if (main_display) {
        //CAMERA
        resize_video_stream = new VideoStreamMerger({
          width: 160,
          height: 120,
          fps: 4,
        });
        // Start the merging.
        resize_video_stream.start();
        my_mix_stream = resize_video_stream.result;

        resizeVideoStream(main_display, my_video_quality);
      }
    }
  };

  const getMyWebcam = () => {
    getWebcam(async (stream) => {
      dispatch({ type: SET_DEVICE_CAM_STREAM, data: stream });
    });
  };

  useEffect(() => {
    if (showCam) {
      if (videoStream?.id) {
        mergeVideoStream(videoStream);
      } else {
        getMyWebcam();
      }
    }
    return () => {
      setVol(false);
      clearTimeout(aiTimer);
    };
  }, [videoStream, showCam]);

  navigator.mediaDevices.ondevicechange = async function (event) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameraDevices = devices?.filter(
      (device) => device.kind === "videoinput" && !device.label.includes("OBS")
    );
    console.log("camera Devices", cameraDevices);
    console.log("event 감지", event);

    const handleCameraDisconnect = () => {
      console.log("카메라 연결이 끊어졌습니다.");
      setIsCamStatus(false);
      dispatch({ type: DISCONNECT_WEBCAM_MODAL, data: true });
      dispatch({ type: TIME_STOP, data: true });
    };

    const handleCameraConnect = () => {
      console.log("카메라 연결이 다시 붙었습니다");
      setIsCamStatus(true);
      dispatch({ type: DISCONNECT_WEBCAM_MODAL, data: false });
      dispatch({ type: TIME_STOP, data: false });
    };
    const track = videoStream?.getTracks()[0];

    if (location.pathname === "/test/maintest") {
      if (cameraDevices.length === 0) {
        handleCameraDisconnect();
      }

      if (track.enabled && !isCamStatus) {
        handleCameraConnect();
      }
    }
    if (location.pathname !== "/test/device") {
      try {
        if (
          cameraDevices.length !== 0 &&
          loginStatusData?.status?.examStatus === 1
        ) {
          changeDeviceStartRecord();
          mergeVideoStream();
        }
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    }

    if (initDevice.length === 0 || devices.length <= initDevice.length) return;

    // 변경된 디바이스 추출 함수
    const getChangedDevices = (initialDevices, updatedDevices) => {
      const changedDevices = [];
      for (const updatedDevice of updatedDevices) {
        const matchingInitialDevice = initialDevices.find(
          (device) => device.label === updatedDevice.label
        );
        if (!matchingInitialDevice) {
          changedDevices.push(updatedDevice);
        }
      }
      return changedDevices;
    };

    const changedDevices = getChangedDevices(initDevice, devices);

    //듀얼 모니터 감지 코드
    const dualMonitorArray = changedDevices.filter(
      (device) =>
        device.kind === "audiooutput" &&
        device.id !== "default" &&
        (device.label.includes("HDMI") ||
          device.label.includes("Type-C") ||
          device.label.includes("DP") ||
          device.label.includes("Display") ||
          device.label.includes("DVI") ||
          device.label.includes("VGA") ||
          device.label.includes("디스플레이"))
    );

    if (dualMonitorArray.length > 0) {
      let object = "";
      changedDevices.map((data) => (object += data.label + ","));
      navigate("/cheat");
    }
  };

  useEffect(() => {
    if (screenShareUseYN === "Y" && showScreen) {
      if (screenStream?.id) {
        screenRef.current.srcObject = screenStream;
        new Promise(
          (resolve) => (screenRef.current.onloadedmetadata = resolve)
        );
        screen_width = screenRef.current.clientWidth;
        screen_height = screenRef.current.clientHeight;
      } else {
        getScreen((stream) => {
          screenRef.current.srcObject = stream;
          new Promise(
            (resolve) => (screenRef.current.onloadedmetadata = resolve)
          );
          screen_width = screenRef.current.clientWidth;
          screen_height = screenRef.current.clientHeight;
          dispatch({ type: SET_DEVICE_SCREEN_STREAM, data: stream });
        });
      }
    }
  }, [screenStream, screenShareUseYN, showScreen]);

  useEffect(() => {
    //재 접속 일 때
    if (
      showCam &&
      isStop &&
      (loginStatusData?.status?.examStatus === 1 ||
        parseInt(sessionStorage.getItem("remainingSeconds")) === 0) &&
      loginStatusData.attendanceYN === "Y"
    ) {
      //재 접속 시 펜딩 일시정지로 만들기 위한 소켓 전송
      sendToExamTopic(socketData?.viewerSocket?.viewerSocketId, "PAUSE");
    }
    return () => {
      socket.off(SOCKET_EVENT.EXAM);
    };
  }, [socketData?.viewerSocket?.viewerSocketId]);

  const deletePeerConnection = () => {
    if (Object.keys(peerConnections).length > 0) {
      Object.keys(peerConnections).forEach((peerId) => {
        peerConnections[peerId].close();
        delete peerConnections[peerId];
      });
    }
  };

  // 비디오 사이즈 변경 함수
  const resizeVideoStream = async (mainStream, quality) => {
    let resize_width;
    let resize_height;

    let resize_video_width;
    let resize_video_height;
    let resize_screen_width;
    let resize_screen_height;

    if (mainStream === "SCREEN") {
      quality = quality + 2;
    }

    resize_width = 80 * quality;
    resize_height = 60 * quality;

    resize_video_width = 80 * quality;
    resize_video_height = 45 * quality;

    resize_screen_width = 80 * quality;
    resize_screen_height = 45 * quality;

    resize_video_stream.setOutputSize(resize_width, resize_height);
    resize_video_stream._frameCount = 0;

    if (mainStream === "CAMERA" || mainStream === "CAMERA-ONLY") {
      resize_video_stream.addStream(videoRef.current.srcObject, {
        x: 0,
        y: resize_height - resize_video_height,
        width: resize_video_width,
        height: resize_video_height,
        mute: false,
      });

      if (
        screenShareUseYN === "Y" &&
        mainStream === "CAMERA" &&
        screenRef.current.srcObject
      ) {
        resize_video_stream.addStream(screenRef.current.srcObject, {
          x: 0,
          y: 0,
          width: resize_screen_width * 0.45,
          height: resize_screen_height * 0.45,
          mute: true,
        });
      }
    } else if (
      screenShareUseYN === "Y" &&
      (mainStream === "SCREEN" || mainStream === "SCREEN-ONLY")
    ) {
      resize_video_stream.addStream(screenRef.current.srcObject, {
        x: 0,
        y: resize_height - resize_screen_height + resize_screen_height * 0.1,
        width: resize_screen_width,
        height: resize_screen_height - resize_screen_height * 0.1,
        mute: true,
      });

      if (mainStream === "SCREEN-ONLY") {
        resize_video_width = 1;
        resize_video_height = 1;
      }
      resize_video_stream.addStream(videoRef.current.srcObject, {
        x: 0,
        y: 0,
        width: resize_video_width * 0.45,
        height: resize_video_height * 0.45,
        mute: false,
      });
    }
    if (resize_video_stream._streams.length > 2) {
      resize_video_stream._streams.splice(0, 2);
    }
  };

  useEffect(() => {
    if (Object.keys(socket).length === 0) return;

    //영상 위치/화질 변경
    socket?.on(SOCKET_EVENT.USER_VIDEO, async (message) => {
      let videoView = message.videoView;
      let videoQuality = message.videoQuality;

      //네트워크 속도가 안좋으면 계속 3으로
      if (isBadQuality?.current) {
        if (videoQuality >= 3) {
          videoQuality = 3;
        }
      }

      //네트워크 속도가 안좋으면 강제로 화질 3으로 조정
      if (networkSpeed === false) {
        isBadQuality.current = true;
        if (videoQuality >= 3) {
          videoQuality = 3;
        }
      }

      if (videoQuality > 0 && my_mix_stream) {
        if (my_mix_stream.getVideoTracks()[0].enabled === false) {
          my_mix_stream.getVideoTracks()[0].enabled = true;
        }
        await resizeVideoStream(videoView, videoQuality);
      } else {
        my_mix_stream.getVideoTracks()[0].enabled = false;
      }
    });

    //사용자 ID 수신
    socket?.on(SOCKET_EVENT.USER_ID, (message) => {
      const conPeerId = message.peerId;
      const conPeerSocketId = message.socketId;
      const conPeerType = message.peerType;

      if (conPeerType === "MANAGER") {
        //감독관의 peerId, socketId 저장
        dispatch({
          type: SOCKET_VIEWER_INFO,
          data: {
            viewerPeerId: conPeerId,
            viewerSocketId: conPeerSocketId,
            viewerPeerType: conPeerType,
          },
        });
      }
    });

    socket?.on(
      SOCKET_EVENT.USER_OFFER,
      async (peerId, socketId, videoAnswer, description) => {
        const viewMode = "CAMERA";
        const videoQuality = 2;

        if (main_display !== viewMode || my_video_quality !== videoQuality) {
          //메인 디스플레이 저장(CAMERA / SCREEN / CAMERA-ONLY / SCREEN-ONLY)
          main_display = viewMode;
          my_video_quality = videoQuality; //현재 화질을 저장
          //전송될 영상 사이즈 변경
          resizeVideoStream(viewMode, videoQuality);
        }

        //연결점을 만든다.
        peerConnections[peerId] = new RTCPeerConnection(RTCConfig);

        //영상 교환일 경우에만
        if (videoAnswer === "Y") {
          //내 영상과 오디오에서 오는 stream을 가져다가 연결을 만든다.
          //peer to peer 연결 안에다가 영상과 오디오를 넣는다.
          my_mix_stream
            .getTracks()
            .forEach((track) =>
              peerConnections[peerId].addTrack(track, my_mix_stream)
            );
        }

        peerConnections[peerId].oniceconnectionstatechange = function () {
          console.log(
            "ICE state...: ",
            peerConnections[peerId].iceConnectionState
          );
        };

        peerConnections[peerId]
          .setRemoteDescription(description)
          .then(() => peerConnections[peerId].createAnswer())
          .then((sdp) => peerConnections[peerId].setLocalDescription(sdp))
          .then(() => {
            socket.emit(
              SOCKET_EVENT.ANSWER,
              myPeerId,
              socketId,
              peerConnections[peerId].localDescription
            );
          });

        peerConnections[peerId].ontrack = (event) => {
          let stream = event.streams[0];
          if (stream) {
            if (event.track.kind === "video") {
              if (
                mobileShareUseYN === "Y" &&
                peerId ===
                  process.env.REACT_APP_CON_SITEID +
                    "-" +
                    roomIdx +
                    "-" +
                    testerIdx +
                    "-MOBILE"
              ) {
                //내 핸드폰 영상 출력
                mobileRef.current.srcObject = stream;
                setMobileQr(false);
                dispatch({ type: DISCONNECT_MOBILE_MODAL, data: false });
              } else {
                //감독관 영상 출력
                viewerRef.current.srcObject = stream;
              }
            }
          }
        };

        peerConnections[peerId].onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit(
              SOCKET_EVENT.CANDIDATE,
              myPeerId,
              socketId,
              event.candidate
            );
          }
        };
      }
    );

    socket?.on(SOCKET_EVENT.USER_CANDIDATE, (peerId, candidate) => {
      peerConnections[peerId].addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket?.on(SOCKET_EVENT.VIDEO_CALLED, (peerId, socketId, message) => {
      peerSocketId[peerId] = socketId;

      const sendVideo = message.sendVideo;
      const getVideo = message.getVideo;

      if (peerConnections[peerId]) {
        peerConnections[peerId].close();
        delete peerConnections[peerId];
      }

      peerConnections[peerId] = new RTCPeerConnection(RTCConfig);

      if (getVideo === "Y") {
        my_mix_stream
          .getTracks()
          .forEach((track) =>
            peerConnections[peerId].addTrack(track, my_mix_stream)
          );
      } else {
        my_mix_stream
          .getTracks()
          .forEach((track) => peerConnections[peerId].addTrack(track));
      }

      peerConnections[peerId].onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit(
            SOCKET_EVENT.CANDIDATE,
            myPeerId,
            socketId,
            event.candidate
          );
        }
      };

      peerConnections[peerId]
        .createOffer()
        .then((sdp) => peerConnections[peerId].setLocalDescription(sdp))
        .then(() => {
          socket.emit(
            SOCKET_EVENT.OFFER,
            myPeerId,
            socketId,
            sendVideo,
            peerConnections[peerId].localDescription
          );
        });
      peerConnections[peerId].oniceconnectionstatechange = function () {
        console.log("ICE state: ", peerConnections[peerId].iceConnectionState);
      };
    });

    socket?.on(SOCKET_EVENT.USER_ANSWER, (peerId, description) => {
      peerConnections[peerId].setRemoteDescription(description);
      peerConnections[peerId].ontrack = (event) => {
        let stream = event.streams[0];

        if (stream) {
          if (event.track.kind === "video") {
            //모바일이면
            if (
              mobileShareUseYN === "Y" &&
              peerId ===
                process.env.REACT_APP_CON_SITEID +
                  "-" +
                  roomIdx +
                  "-" +
                  testerIdx +
                  "-MOBILE"
            ) {
              mobileRef.current.srcObject = stream;
              setMobileQr(false);
              //모바일 캠 연결 상태를 디스패치
              dispatch({ type: SHOW_MOBILE_CAM, data: true });
              dispatch({ type: DISCONNECT_MOBILE_MODAL, data: false });
            }
            //감독관이면
            else {
              viewerRef.current.srcObject = stream;
            }
          }
        }
      };
    });

    socket?.on(SOCKET_EVENT.USER_REFRESH, () => {
      socket.close();
      //소켓 접속
      socket.connect();
      //룸 접속
      joinRoom();
    });

    //소켓 재연결 이벤트시 (끊겼다가 다시 접속)
    socket.io.on("reconnect", () => {
      socket.close();
      socket.connect(); //소켓 접속
      deletePeerConnection();
      joinRoom(); // 룸 접속
    });

    //사용자 룸 입장
    socket?.on(SOCKET_EVENT.USER_CONNECTED, (message) => {
      clearTimeout(timeOutDisconnected.current);

      const conPeerId = message.peerId;
      const conPeerType = message.peerType;
      const conPeerSocketId = message.socketId;
      const getVideo = "Y";

      if (
        conPeerId ===
        process.env.REACT_APP_CON_SITEID + "-" + roomIdx + "-MANAGER"
      ) {
        //응시자 접속이 아니라면(감독관 또는 운영자)
        socket?.emit(SOCKET_EVENT.SEND_ID, {
          peerId: myPeerId,
          peerType: con_my_peer_type,
          targetSocketId: conPeerSocketId,
        });

        dispatch({
          type: SOCKET_VIEWER_INFO,
          data: {
            viewerPeerId: conPeerId,
            viewerSocketId: conPeerSocketId,
            viewerPeerType: conPeerType,
          },
        });
      }
      //모바일 접속 시
      else if (
        mobileShareUseYN === "Y" &&
        conPeerId ===
          process.env.REACT_APP_CON_SITEID +
            "-" +
            roomIdx +
            "-" +
            testerIdx +
            "-MOBILE"
      ) {
        //모바일 데이터
        dispatch({
          type: SOCKET_MOBILE_CONNECTIONS,
          data: {
            mobilePeerId: conPeerId,
            mobileSocketId: conPeerSocketId,
            mobilePeerType: conPeerType,
          },
        });

        peerSocketId[conPeerId] = conPeerSocketId;
        peerConnections[conPeerId] = new RTCPeerConnection(RTCConfig);
        my_video_stream
          .getTracks()
          .forEach((track) => peerConnections[conPeerId].addTrack(track));
        peerConnections[conPeerId].onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit(
              SOCKET_EVENT.CANDIDATE,
              myPeerId,
              conPeerSocketId,
              event.candidate
            );
          }
        };
        //내 영상전송 및 상대방 영상전송 요청
        peerConnections[conPeerId]
          .createOffer()
          .then((sdp) => peerConnections[conPeerId].setLocalDescription(sdp))
          .then(() => {
            socket.emit(
              SOCKET_EVENT.OFFER,
              myPeerId,
              conPeerSocketId,
              getVideo,
              peerConnections[conPeerId].localDescription
            );
          });
        peerConnections[conPeerId].oniceconnectionstatechange = function () {
          console.log(
            "ICE state: ",
            peerConnections[conPeerId].iceConnectionState
          );
        };
      }
    });

    //소켓 재연결 이벤트시
    socket?.on(SOCKET_EVENT.VIDEO_REFRESH, () => {
      deletePeerConnection();
      joinRoom();
    });

    return () => {
      socket.off(SOCKET_EVENT.USER_CONNECTED);
      socket.off(SOCKET_EVENT.USER_REFRESH);
      socket.off(SOCKET_EVENT.USER_VIDEO);
      socket.off(SOCKET_EVENT.USER_ID);
      socket.off(SOCKET_EVENT.USER_OFFER);
      socket.off(SOCKET_EVENT.VIDEO_CALLED);
      socket.off(SOCKET_EVENT.USER_CANDIDATE);
      socket.off(SOCKET_EVENT.USER_SOUND);
      socket.off(SOCKET_EVENT.VIDEO_REFRESH);
      socket.off("reconnect");
    };
  }, [networkSpeed]);

  useEffect(() => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_DISCONNECTED, (peerId, peerType, socketId) => {
      //응시자가 아니라면(감독관 또는 운영자)
      if (peerType !== "TESTER") {
        if (peerSocketId[peerId] === socketId) {
          if (peerConnections[peerId]) {
            //커넥션 ID 삭제
            peerConnections[peerId].close();
            delete peerConnections[peerId];
            //감독관이 끊겼을 경우
            if (peerType === "MANAGER") {
              //감독관 웹캠 OFF
              viewerRef.current.srcObject = null;
              if (loginStatusData?.status?.examStatus === 1) {
                timeOutDisconnected.current = setTimeout(() => {
                  dispatch({ type: PENDING_MODAL_ON });
                  dispatch({ type: TIME_STOP, data: true });
                  sendToExamTopic(
                    socketData?.viewerSocket?.viewerSocketId,
                    "PAUSE"
                  );
                }, 180000);
              }
              dispatch({
                type: SOCKET_VIEWER_INFO,
                data: {
                  ...socketData.viewerSocket,
                  viewerSocketId: null,
                },
              });
            } else if (mobileShareUseYN === "Y" && peerType === "MOBILE") {
              // 모바일이 끊겼을 경우
              setMobileQr(true);
              dispatch({ type: DISCONNECT_MOBILE_MODAL, data: true });
            }
          }
        }
      }
    });
    return () => {
      socket.off(SOCKET_EVENT.EXAM);
      socket.off(SOCKET_EVENT.USER_DISCONNECTED);
    };
  }, [dispatch, loginStatusData, mobileShareUseYN, socket]);

  return (
    <div css={totalWrapper({ location, theme })}>
      <div css={navWrapper}>
        <canvas
          ref={canvasRef}
          width={400}
          height={300}
          style={{ zIndex: -1, width: "5px", height: "5px" }}
        />
        <Step />
        {/* 감독관이 없을 경우 시험대기까지 display : none */}
        <div
          css={timerWrapper}
          style={{
            display:
              roomUseYN === "N" &&
              url !== "/test/maintest" &&
              nowExamData.examStatus !== 1 &&
              url !== "/test/pretest"
                ? "none"
                : "",
          }}
        >
          <MainTimer />
        </div>
        <div css={camWrapper}>
          {/* 모바일 캠 */}
          {mobileShareUseYN === "Y" && showScreen && (
            //mobileQr 여부에 따라 display 시키기
            <div css={mobileQrWrap({ mobileQr, showMobile })}>
              <QRCodeCanvas
                value={`${baseURL}mobile/device?` + encodeURL}
                size={50}
              />
              <Cam camRef={mobileRef} type="mobile" showRec={false} />
              <ConnectBadge connect={!mobileQr} />
              <div className="pre-img">
                <img src="/images/cellphone.png" alt=""></img>
              </div>
              <div className="pre-mobile-text">휴대전화 화면 연결</div>
            </div>
          )}
          {/* 웹 캠 */}
          {(showCam || showCam) && (
            <Cam
              camRef={videoRef}
              type="cam"
              vol={vol}
              showRefresh={true}
              onLoadedMetadata={() => setVideoReady(true)}
            />
          )}
          {/* 스크린 캠 */}
          {screenShareUseYN === "Y" && (showScreen || showScreen) && (
            <Cam camRef={screenRef} type="screen" />
          )}
        </div>
        {/* 소켓 연결 됐을 경우 채팅 창 보여주기 */}
        {showCam && (
          <Chat viewerRef={viewerRef} socket={socket} myPeerId={myPeerId} />
        )}
      </div>
    </div>
  );
};

const totalWrapper = ({ theme }) => css`
  color: ${theme.fontColor};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;
const navWrapper = (theme) => css`
  position: relative;
  background-color: ${theme.mainColor};
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const timerWrapper = css`
  width: 280px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
`;

const camWrapper = css`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 90px;
  position: absolute;
  right: 0;
`;

const mobileQrWrap = ({ mobileQr, showMobile }) => css`
  background-color: ${mobileQr ? "white" : ""};
  z-index: ${mobileQr && 100};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 90px;
  .pre-img {
    position: relative;
    justify-content: center;
    display: ${showMobile ? "none" : "flex"};
    position: absolute;
    height: 90px;
    width: 140px;
    background-color: #efefef;
    img {
      height: 100%;
      padding: 20px;
      padding-bottom: 30px;
    }
  }
  .pre-mobile-text {
    position: absolute;
    z-index: 600;
    color: #3b3b3b;
    bottom: 5px;
    font-size: 10px;
    display: ${showMobile ? "none" : "flex"};
  }
  canvas {
    display: ${!mobileQr ? "none" : ""};
  }
  video {
    display: ${mobileQr ? "none" : ""};
    width: 140px;
    z-index: ${showMobile && 200};
  }
`;
