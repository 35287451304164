/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import i18n from "../../../locales/i18n";

export const SubRectangleButton = ({ label, onClick }) => {
  const language = i18n.language;
  return (
    <button onClick={onClick} css={recButton({ language })}>
      {label}
    </button>
  );
};

const recButton = ({ language }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${language === "KOR" ? "100px" : "110px"};
  height: 35px;
  border: none;
  cursor: pointer;
  background-color: #676666;
  color: white;
  font-size: 14px;
`;
