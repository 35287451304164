import apiClient from "../api/apiClient";
import { useEffect } from "react";
import {
  ALERT_MODAL_ON,
  DUPLICATE_MODAL,
  ERROR_MODAL_ON,
} from "../_reducers/modalAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRoomConnection } from "../adapters/useRoomConnection";
import { SOCKET_EVENT } from "../adapters/socket.enum";

const useAxiosInterceptor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sendToExamTopic } = useRoomConnection();
  const { roomUseYN } = useSelector((state) => state.userAction.data);
  const socketData = useSelector((state) => state.socketAction);
  const { socket } = useRoomConnection();

  const errorHandler = (error) => {
    const code = error.code;
    const status = error.response?.status;

    if (code === "ECONNABORTED") {
      dispatch({
        type: ALERT_MODAL_ON,
        data: t("error.timeOutAndNextPage"),
      });
    }

    if (status === 401) {
      dispatch({ type: DUPLICATE_MODAL });
    }
    //다음 페이지 에러 발생 시
    if (
      status === 500 &&
      error.response.config.url.split("?")[0] === "/exam/start"
    ) {
      if (roomUseYN === "Y") {
        dispatch({
          type: ERROR_MODAL_ON,
          data: t("error.timeOutAndNextPage"),
        });
        sendToExamTopic(socketData?.viewerSocket?.viewerSocketId, "PAUSE");
        socket?.off(SOCKET_EVENT.EXAM);
      } else {
        dispatch({
          type: ALERT_MODAL_ON,
          data: t("error.timeOutAndNextPage"),
        });
      }
    }

    //답안 체크 에러 발생 시
    if (
      status === 500 &&
      (error.response.config.url === "/exam/update" ||
        error.response.config.url === "/exam/update?updateType=mark")
    ) {
      if (roomUseYN === "Y") {
        dispatch({
          type: ERROR_MODAL_ON,
          data: t("error.saveAnswer"),
        });
        sendToExamTopic(socketData?.viewerSocket?.viewerSocketId, "PAUSE");
        socket?.off(SOCKET_EVENT.EXAM);
      } else {
        dispatch({
          type: ALERT_MODAL_ON,
          data: t("error.saveAnswer"),
        });
      }
    }

    return Promise.reject(error);
  };

  const responseHandler = (response) => {
    return response;
  };

  const requestInterceptor = apiClient.interceptors.request.use(
    async (config) => {
      const accessToken = sessionStorage.getItem("accessToken");
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      if (config.url === "/exam/update" || config.url.includes("exam/start")) {
        config.timeout = 15000;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const responseInterceptor = apiClient.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  useEffect(() => {
    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, requestInterceptor]);
};

export { useAxiosInterceptor, apiClient };
