/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ConnectBadge = ({ connect }) => {
  return (
    <div css={icon({ connect })}>
      <span className="recIcon">{connect ? "연결 완료" : "연결 끊김"}</span>
    </div>
  );
};

const icon = ({ connect }) => css`
  position: absolute;
  bottom: 6px;
  left: 0px;
  width: 60px;
  font-size: 0.625rem;
  cursor: auto;
  .recIcon {
    display: flex;
    justify-content: center;
    border: 1px solid ${connect ? "#00f6ff" : "#FF004E"};
    border-radius: 0.5rem;
    font-weight: 500;
    color: ${connect ? "#00f6ff" : "#FF004E"};
    line-height: 1.1;
    text-align: center;
    padding: 0.5em 0.4em;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 500;
    width: 60px;
    margin: 0 6px;
  }
`;
