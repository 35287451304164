import produce from "immer";
import { PURGE } from "redux-persist";

export const TIMING_NOW_BEFORE_EXAM = "TIMING_NOW_BEFORE_EXAM";
export const TIMING_NOW_PRACTICE_EXAM = "TIMING_NOW_PRACTICE_EXAM";
export const TIMING_NOW_MAIN_EXAM = "TIMING_NOW_MAIN_EXAM";
export const TIMING_NOW_MAX_SUBEXAM = "TIMING_NOW_MAX_SUBEXAM";
export const TIMING_NOW_LAST_EXAM = "TIMING_NOW_LAST_EXAM";

export const TIME_CURRENT_TIME = "TIME_CURRENT_TIME";
export const TIME_STANDARD_TIME = "TIME_STANDARD_TIME";
export const TIME_REMAINING_TIME = "TIME_REMAINING_TIME";

export const TIME_REMAINING_TIME_ZERO = "TIME_REMAINING_TIME_ZERO";

export const TIME_OUT = "TIME_OUT";
export const TIME_STOP = "TIME_STOP";

export const TIME_CHANGE = "TIME_CHANGE";

const initialState = {
  isStop: false,
  timing: null,
  time: {
    standardTime: null,
    syncCurrentTime: null,
    remainingSeconds: null,
    timerChange: null,
    timeOut: null,
  },
};

const timeReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      //timing : 검사 전 대기, 예제 문항, 본검사, 검사 종료
      case TIMING_NOW_BEFORE_EXAM:
        draft.timing = TIMING_NOW_BEFORE_EXAM;
        break;
      case TIMING_NOW_PRACTICE_EXAM:
        draft.timing = TIMING_NOW_PRACTICE_EXAM;
        break;
      case TIMING_NOW_MAIN_EXAM:
        draft.timing = TIMING_NOW_MAIN_EXAM;
        break;
      case TIMING_NOW_MAX_SUBEXAM:
        draft.timing = TIMING_NOW_MAX_SUBEXAM;
        break;
      case TIMING_NOW_LAST_EXAM:
        draft.timing = TIMING_NOW_LAST_EXAM;
        break;
      //time : 현재시간, 남은 시간, 기준 시간
      case TIME_CURRENT_TIME:
        draft.time.syncCurrentTime = action.data;
        break;
      case TIME_REMAINING_TIME:
        draft.time.remainingSeconds = action.data;
        break;
      case TIME_STANDARD_TIME:
        draft.time.standardTime = action.data;
        break;
      case TIME_REMAINING_TIME_ZERO:
        draft.time.remainingSecondZero = action.data;
        break;
      case TIME_OUT:
        draft.time.timeOut = action.data;
        break;
      case TIME_STOP:
        draft.isStop = action.data;
        break;
      case TIME_CHANGE:
        draft.time.timerChange = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        return state;
    }
  });
};

export default timeReducer;
