/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CloseIcon } from "../system/icon/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_CHAT } from "../../_reducers/showUtilsAction";
import { useTranslation } from "react-i18next";

const CustomButton = ({ children, onClick }) => {
  return (
    <Button type="primary" onClick={onClick} size="small">
      <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
    </Button>
  );
};
const getLocation = (location) => {
  if (location === "top") {
    return {
      top: "0px",
      transform: "rotate(28deg) skewY(35deg)",
      left: "50px",
    };
  } else if (location === "bottom") {
    return {
      bottom: "-6px",
      transform: "rotate(28deg) skewY(35deg)",
      left: "50px",
    };
  } else if (location === "right") {
    return {
      bottom: "38px",
      left: "334px",
      transform: "rotate(297deg) skewY(36deg)",
    };
  } else if (location === "left") {
    return {
      transform: "rotate(297deg) skewY(36deg)",
      bottom: "38px",
      left: "-6px",
    };
  }
};

export const ToolTip = ({
  top,
  right,
  left,
  bottom,
  location,
  setIndex,
  start = false,
  end = false,
  children,
}) => {
  const { t } = useTranslation();

  const locationValue = getLocation(location);
  const { showChat } = useSelector((state) => state.showUtilsAction);
  const dispatch = useDispatch();

  const changeNextStep = (type) => {
    if (type === "end") {
      setIndex(-1);
    }

    if (type === "next") {
      setTimeout(() => {
        setIndex((prev) => prev + 1);
      }, 300);
    }
    if (type === "prev") {
      setTimeout(() => {
        setIndex((prev) => prev - 1);
      }, 300);
    }

    //툴팁 다음 버튼을 눌렀을 때 채팅이 켜져있으면 닫아주기
    if (showChat) {
      dispatch({ type: SHOW_CHAT, data: false });
    }
  };

  return (
    <div css={totalWrapper({ top, right, left, bottom, locationValue })}>
      <div className="tooltip-wrapper">
        <CloseIcon onClick={() => changeNextStep("end")} fontSize="20px" />
        <div className="tooltip-text">
          {children}
          <div className="tooltip-button-wrap">
            {!start && (
              <CustomButton onClick={() => changeNextStep("prev")}>
                <LeftOutlined />
                {t(`tooltip.button.prev`)}
              </CustomButton>
            )}
            {end ? (
              <CustomButton onClick={() => changeNextStep("end")}>
                {t(`tooltip.button.end`)} <RightOutlined />
              </CustomButton>
            ) : (
              <CustomButton onClick={() => changeNextStep("next")}>
                {t(`tooltip.button.next`)}
                <RightOutlined />
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const boxFade = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const totalWrapper = ({ top, right, left, bottom, locationValue }) => css`
  animation: ${boxFade} 0.4s linear alternate;
  z-index: 999;
  position: absolute;
  top: ${top};
  right: ${right};
  left: ${left};
  bottom: ${bottom};

  .tooltip-wrapper {
    display: flex;
    position: relative;
    text-align: center;
    border-radius: 10px;
    width: 350px;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    box-shadow: 0px 9px 76px -10px rgba(0, 0, 0, 0.51);
    padding: 10px 20px;
    font-size: 16px;
    word-break: keep-all;
  }

  .tooltip-text {
    width: 100%;
    z-index: 10;
    padding: 30px 10px 11px 10px;
    text-align: left;
  }

  .tooltip-button-wrap {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
  }

  .tooltip-wrapper::before {
    position: relative;
    background-color: #ffff;
    display: block;
    position: absolute;
    bottom: ${locationValue.bottom};
    left: ${locationValue.left};
    top: ${locationValue.top};
    width: 20px;
    height: 20px;
    content: "";
    transform: ${locationValue.transform};
    z-index: 1;
  }

  .tooltip-wrapper::after {
    position: relative;
    background-color: #ffff;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    border-radius: inherit;
    width: inherit;
    height: inherit;
    content: "";
    z-index: 1;
  }
`;
