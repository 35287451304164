/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { getTextColorByBackgroundColor } from "../../../utils/color";
import { useNowExamData } from "../../../hooks/useNowExamData";
import {
  MAIN_EXAM_REQUEST,
  SET_EXAMPLE_PAGE,
} from "../../../_reducers/mainExamAction";
import { updateApi } from "../../../api/update/updateApi";
import { SET_PRETEST_REQUEST } from "../../../_reducers/pretestAction";
import { examApi } from "../../../api/exam/examApi";

export const PagiNationButton = ({ markInfosArray, isLoading, setLoading }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.subColor);
  const nowExamData = useNowExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const { completer } = useSelector((state) => state.userAction);
  const containerNav = useRef({});
  const examDataTestStatus = nowExamData?.testerStatus;

  //사전점검 주관식
  const isPretestReply =
    nowExamData?.questionInfoData?.questionList[0]?.questionEx.length === 0;

  //본검사 주관식
  const isMainTestReply =
    nowExamData?.exam?.questionInfoData?.questionList[0]?.questionEx.length ===
    0;

  //본검사 예제 주관식
  const isMainExampleTestReply =
    nowExamData?.example?.questionInfoData?.questionList[0]?.questionEx
      .length === 0;

  const getNowPage = useCallback(() => {
    if (!completer) {
      return nowExamData.pageNo;
    } else if (nowExamData?.exam) {
      return examDataTestStatus?.savePage;
    } else {
      return exampleIndex + 1;
    }
  }, [
    exampleIndex,
    examDataTestStatus?.savePage,
    nowExamData.pageNo,
    nowExamData,
  ]);

  const getEndPage = () => {
    if (!completer) {
      return nowExamData.pageNoMax;
    } else if (nowExamData?.exam) {
      return examDataTestStatus?.totalPage;
    } else {
      return nowExamData?.example?.questionInfoData?.lastNo;
    }
  };
  const endPage = getEndPage();

  const scrollGoLeft = () => {
    if (containerNav.current.scrollLeft !== undefined) {
      containerNav.current.scrollLeft -= 680;
    }
  };
  const scrollGoRight = () => {
    if (containerNav.current.scrollLeft !== undefined) {
      containerNav.current.scrollLeft += 680;
    }
  };

  useEffect(() => {
    if (!containerNav.current) {
      return;
    }
    containerNav.current.scrollLeft = getNowPage() * 30 - 90;
  }, [getNowPage]);

  const updateMarkInfosArrayColor = (idx) => {
    return markInfosArray?.map((a, j) =>
      a === true && j === idx ? checkAns({ theme, fontColor }) : null
    );
  };

  const handlePageClick = async (idx) => {
    const examNo = completer ? examDataTestStatus?.examNo : nowExamData?.examNo;
    const pageNo = idx + 1;
    const examSubNo = completer
      ? examDataTestStatus?.examSubNo
      : nowExamData?.examSubNo;

    //본검사 예제가 아니고, 본검사 주관식이 아닐때
    if (
      !isMainTestReply &&
      completer &&
      examDataTestStatus?.examSubStatus === 1
    ) {
      updateApi.updateTime(examDataTestStatus);
    }

    // 동일한 페이지에서 api 불러오기 방지
    if (getNowPage() !== pageNo) {
      if (completer && nowExamData?.example) {
        dispatch({ type: SET_EXAMPLE_PAGE, data: idx });
        return;
      }

      if (completer) {
        if (isLoading) return;
        setLoading(true);
        await examApi
          .examStart(
            pageNo,
            examDataTestStatus?.examSubStatus,
            examDataTestStatus
          )
          .then((response) => {
            dispatch({
              type: MAIN_EXAM_REQUEST,
              data: response.data.data,
            });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            mode: "MOVE",
            examNo: examNo,
            pageNo: pageNo,
            examSubNo: examSubNo,
          },
        });
      }
    }
  };

  return (
    <div css={totalWrapper({ endPage })}>
      {endPage > 20 ? (
        <div onClick={scrollGoLeft}>
          <CaretLeftOutlined css={iconLeft} />
        </div>
      ) : null}
      <div css={contentWrapper} ref={containerNav}>
        <div css={paginationWrapper}>
          {[...Array(endPage)].map((data, idx) => (
            <div
              css={[
                page,
                () => {
                  let storageKey = `${
                    completer ? examDataTestStatus?.examNo : nowExamData?.examNo
                  }-${
                    completer
                      ? examDataTestStatus?.examSubNo
                      : nowExamData?.examSubNo
                  }-${idx + 1}`;

                  if (completer && examDataTestStatus?.examSubStatus === 1) {
                    return updateMarkInfosArrayColor(idx);
                  } else {
                    if (sessionStorage.getItem(storageKey)) {
                      return checkAns({ theme, fontColor });
                    }
                  }
                },

                getNowPage() === idx + 1 ? checkNowPage : null,
              ]}
              key={idx}
              onClick={() => {
                handlePageClick(idx);
              }}
            >
              {idx + 1}
            </div>
          ))}
        </div>
      </div>
      {endPage > 20 ? (
        <div onClick={scrollGoRight}>
          <CaretRightOutlined css={iconRight} />
        </div>
      ) : null}
    </div>
  );
};

const totalWrapper = ({ endPage }) => css`
  display: flex;
  justify-content: ${endPage > 20 && "center"};
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;
const contentWrapper = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: baseline;
  height: 100%;
  width: 680px;
  overflow: hidden;
  scroll-behavior: smooth;
  justify-content: "flex-start";
`;
const paginationWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  gap: 4px;
`;

const page = css`
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 2px solid #999;
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
`;
const checkAns = ({ theme, fontColor }) => css`
  background-color: ${theme.subColor};
  color: ${fontColor};
`;
const checkNowPage = css`
  border: 2px solid #131313;
`;

const iconLeft = css`
  position: absolute;
  bottom: 20px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #555;
  left: 0;
  bottom: 15px;
  cursor: pointer;
`;

const iconRight = css`
  position: absolute;
  bottom: 20px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #555;
  right: 0;
  bottom: 15px;
  cursor: pointer;
`;
