/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_MODAL_OFF, ERROR_MODAL_OFF } from "../../_reducers/modalAction";
import { BasicButton } from "../../components/system/button/BasicButton";
import { ModalTemplate } from "../base/ModalTemplate";
import { useTranslation } from "react-i18next";

export const ConfirmModal = ({ children }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { alertModal } = useSelector((state) => state.modalAction);

  return (
    <ModalTemplate>
      <div css={confirmModalMessage}>{children}</div>
      <div css={buttonGroup}>
        <BasicButton
          wid="s"
          hei="s"
          onClick={() => {
            if (alertModal) {
              dispatch({ type: ALERT_MODAL_OFF });
            } else {
              dispatch({ type: ERROR_MODAL_OFF });
            }
          }}
          label={t(`exam.button.confirm`)}
        />
      </div>
    </ModalTemplate>
  );
};

const confirmModalMessage = css`
  margin: 40px 20px 10px;
  white-space: pre;
  text-align: center;
  font-size: 1rem;
`;

const buttonGroup = css`
  display: flex;
  justify-content: center;
  padding: 20px;
  left: 30%;
`;
