import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TIMING_NOW_BEFORE_EXAM,
  TIMING_NOW_LAST_EXAM,
  TIMING_NOW_MAIN_EXAM,
  TIMING_NOW_PRACTICE_EXAM,
} from "../_reducers/timeAction";

export const useTiming = () => {
  const dispatch = useDispatch();
  const examData = useSelector((state) => state.mainExamAction.data);

  //examSubStatus를 비교하여 현재 타이밍을 바꿔줌
  useEffect(() => {
    const { examSubStatus, maxExamSubNo, examSubNo, maxExamNo, examNo } =
      examData?.testerStatus || {};

    if (examSubStatus === null) {
      dispatch({ type: TIMING_NOW_BEFORE_EXAM });
    } else if (examSubStatus === 0) {
      dispatch({ type: TIMING_NOW_PRACTICE_EXAM });
    } else if (examSubStatus === 1) {
      dispatch({ type: TIMING_NOW_MAIN_EXAM });

      if (maxExamSubNo === examSubNo && maxExamNo === examNo) {
        dispatch({ type: TIMING_NOW_LAST_EXAM });
      }
    }
  }, [examData?.testerStatus?.examSubStatus]);
};
