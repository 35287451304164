// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.App {
  width: 100%;
  font-family: "NanumSquare";
  -webkit-user-select :none;
          user-select :none;
}


.ant-tooltip-inner{
  word-break: keep-all;
}
/* antd 라이브러리 툴팁 radius  */
.ant-tooltip-red .ant-tooltip-inner {
  white-space: pre-line;
  border-radius: 4px;
  background-color: #aa0000 !important;
}


/* antd 라이브러리 툴팁 제거  */
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background-color: #aa0000 !important;
  background: inherit;
}

.ant-select-item-option-content {
  font-family: "NanumSquare" !important; 
}

.ant-tooltip{
  z-index: 998 !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;EAIE,WAAW;EACX,0BAA0B;EAC1B,yBAAiB;UAAjB,iBAAiB;AACnB;;;AAGA;EACE,oBAAoB;AACtB;AACA,0BAA0B;AAC1B;EACE,qBAAqB;EACrB,kBAAkB;EAClB,oCAAoC;AACtC;;;AAGA,sBAAsB;AACtB;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["html,\nbody,\n#root,\n.App {\n  width: 100%;\n  font-family: \"NanumSquare\";\n  user-select :none;\n}\n\n\n.ant-tooltip-inner{\n  word-break: keep-all;\n}\n/* antd 라이브러리 툴팁 radius  */\n.ant-tooltip-red .ant-tooltip-inner {\n  white-space: pre-line;\n  border-radius: 4px;\n  background-color: #aa0000 !important;\n}\n\n\n/* antd 라이브러리 툴팁 제거  */\n.ant-tooltip-red .ant-tooltip-arrow-content::before {\n  background-color: #aa0000 !important;\n  background: inherit;\n}\n\n.ant-select-item-option-content {\n  font-family: \"NanumSquare\" !important; \n}\n\n.ant-tooltip{\n  z-index: 998 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
