/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataApi } from "../../api/data/dataApi";
import { getOpacityColor } from "../../utils/color";
import { useNavigate } from "react-router-dom";
import { UPDATE_ATTENDANCE } from "../../_reducers/examUserDataAction";
import { RecButton } from "../../components/system/button/RecButton";
import { useTranslation } from "react-i18next";
import { TIME_STOP } from "../../_reducers/timeAction";
import i18n from "../../locales/i18n";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { BasicButton } from "../../components/system/button/BasicButton";
import { useRecord } from "../../hooks/useRecord";

export const TimeTablePage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const [timeTableData, setTimeTableData] = useState();
  const tableColor = getOpacityColor(theme.mainColor);
  const { roomUseYN } = useSelector((state) => state.userAction.data);
  const [isNextTest, setNextTest] = useState(false);
  const { startRecord } = useRecord();

  //오프라인일 결우 검사 바로 시작 함수
  const examStart = async () => {
    //출석 확인
    dispatch({ type: UPDATE_ATTENDANCE, data: "Y" });
    startRecord();
    //검사 페이지 이동
    navigate("/test/maintest", { replace: true });
    dispatch({ type: TIME_STOP, data: false });
  };

  useEffect(() => {
    async function fetchTimeTableData() {
      await dataApi.timetableData().then((response) => {
        setTimeTableData(response.data.data);
      });
    }
    fetchTimeTableData();
  }, [dispatch]);

  return (
    <>
      {isNextTest && (
        <ModalTemplate setModal={setNextTest} closeBtn={true}>
          <div css={confirmModalMessage}>
            {i18n.language === "KOR" ? (
              <>
                시작 버튼을 누르면
                <br />
                검사가 바로 시작됩니다.
              </>
            ) : (
              <>
                When you press the start button,
                <br />
                the test starts immediately.
              </>
            )}
          </div>
          <div css={buttonGroup}>
            <BasicButton
              wid="s"
              hei="s"
              label={t(`timeTable.button.start`)}
              onClick={examStart}
            />
          </div>
        </ModalTemplate>
      )}
      <div css={totalWrapper}>
        {roomUseYN === "Y" && (
          <>
            <div css={startTextBox}>{t(`timeTable.subTitle`)}</div>
          </>
        )}
        <div className="table-wrap">
          <table css={tableWrapper}>
            <thead
              css={css`
                background-color: ${tableColor};
              `}
            >
              <tr>
                <th>{t(`timeTable.table.구분`)}</th>
                <th>{t(`timeTable.table.검사명`)}</th>
                <th>{t(`timeTable.table.문항`)}</th>
                <th>{t(`timeTable.table.시간`)}</th>
              </tr>
            </thead>
            <div className="space" />
            <tbody>
              {timeTableData?.map((data, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td className="divContent">{data.examName}</td>
                    <td>
                      {data.examSubList.map((item, i) => (
                        <div className="divContent" key={i}>
                          {item.examSubName}
                        </div>
                      ))}
                    </td>
                    {(function () {
                      if (
                        data.examName === "인성검사" ||
                        data.examName === "Personality Test"
                      ) {
                        return (
                          <td>
                            {data.examSubList.map((item, i) => (
                              <div className="divContent" key={i}>
                                {item.questionSetCnt !== 1
                                  ? `${item.questionNoCount} ${t(
                                      `timeTable.table.문항`
                                    )}  (${
                                      item.questionNoCount / item.questionSetCnt
                                    }${t(`timeTable.table.문항군`)})`
                                  : `${item.questionNoCount} ${t(
                                      `timeTable.table.문항`
                                    )}`}
                              </div>
                            ))}
                          </td>
                        );
                      } else {
                        return (
                          <td>
                            {data.examSubList.map((item, i) => (
                              <div className="divContent" key={i}>
                                {`${item.questionNoCount} ${t(
                                  `timeTable.table.문항`
                                )}`}
                              </div>
                            ))}
                          </td>
                        );
                      }
                    })()}
                    <td>
                      {data.examSubList.map((item, i) => (
                        <div className="divContent" key={i}>
                          {item.examMinutes} {t(`timeTable.table.분`)}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <React.Fragment>
                    <div className="space" />
                    <tr
                      style={{
                        display: timeTableData.length === i + 1 && "none",
                      }}
                    >
                      <td css={hrBorder} />
                      <td css={hrBorder} />
                      <td css={hrBorder} />
                      <td css={hrBorder} />
                    </tr>
                    <div className="space" />
                  </React.Fragment>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        {roomUseYN === "N" && (
          <div css={bottomNotice}>
            {i18n.language === "KOR" ? (
              <>
                ※ 본 검사에는 응답자가 성실하게 응답했는지를 확인하기 위한 여러
                장치들이 포함되어 있습니다.
                <br />
                {`ㅤ `}따라서 거짓으로 꾸며서 응답하거나 불성실하게 응답할 경우
                신뢰할 수 없는 응답으로 간주하여 검사 결과가 무효 처리 될 수
                있습니다.
              </>
            ) : (
              <>
                ※ The test is designed to have several filters to identify
                whether you have answered to all the questions honestly and
                sincerely.
                <br />ㅤ If your answers are identified to be dishonest or
                insincere, they are considered unreliable, so your test result
                may be invalidated.
              </>
            )}
          </div>
        )}
        {/* {roomUseYN === "Y" && (
          <div css={bottomNotice}>
            • 다음 페이지로 이동할 경우 이전 페이지의 응답은 수정할 수 없으니
            유의하시기 바랍니다.
          </div>
        )} */}
        {roomUseYN === "N" && (
          <div css={btnWrap}>
            <RecButton
              onClick={() => {
                setNextTest(true);
              }}
            >
              {t(`timeTable.startButton`)}
            </RecButton>
          </div>
        )}
      </div>
    </>
  );
};

const hrBorder = css`
  border-bottom: 3px #f1f1f1 solid;
  height: 3px;
`;
const startTextBox = css`
  font-size: 20px;
  font-weight: 700;
  white-space: pre;
  text-align: center;
  margin: 20px 0 30px 0;
`;
const bottomNotice = css`
  font-size: 15px;
  color: #999;
  width: 100%;
  max-width: 1000px;
`;
const noticeTitle = css`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 20px 0;
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const noticeSubTitle = css`
  text-align: left;
  font-size: 20px;
  margin: 10px 0 20px 0;
  width: 100%;
  max-width: 1000px;
  @media (max-width: 750px) {
    font-size: 15px;
  }
`;

const noticeBox = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 30px;
  width: 100%;
  max-width: 1000px;
  @media (max-width: 750px) {
    font-size: 15px;
  }
`;

const btnWrap = css`
  padding: 30px 0;
`;

const totalWrapper = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  .table-wrap {
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .space {
    height: 10px;
    background-color: #fff;
  }
  @media (max-width: 750px) {
    padding-top: 20px;
    justify-content: flex-start;
    width: 95%;
    margin: 0 auto;
  }
`;

const tableWrapper = css`
  max-width: 1000px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  background-color: #fff;
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  th:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  th:last-child {
    border-radius: 0 5px 5px 0;
  }
  td {
    text-align: center;
  }
  td:first-of-type {
    border-left: 0;
  }
  td:last-child {
    border-right: 0;
  }
  th {
    padding: 6px;
  }
  td {
    border-right: 3px #f1f1f1 solid;
  }
  .divContent {
    padding: 6px;
  }

  div:last-child {
    border-bottom: 0;
  }

  tr:last-child {
    border: 0;
  }
`;

const confirmModalMessage = css`
  margin: 40px 20px 10px;
  white-space: pre;
  text-align: center;
  font-size: 18px;
`;

const buttonGroup = css`
  display: flex;
  padding-top: 20px;
  left: 30%;
`;
