/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useDivisionExamData } from "../../../../hooks/useDivisionExamData";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

export const MaintestColumPart1 = ({
  numSetCheck,
  questionList,
  examAnswerPart1,
  setExamAnswerPart1,
  getNotCheckSetInfoPart1,
  changeNull,
  index,
}) => {
  const { t } = useTranslation();

  const initialState = useSelector((state) => state.mainExamAction.data);
  const examData = useDivisionExamData();
  const nowPageSetNum = examData?.questionInfoData?.questionList?.length; //한 페이지의 총 세트 수
  const arrayQuestionSetNo = index + 1;
  //한 세트의 문항 수
  const questionSetCount =
    examData?.questionInfoData?.questionList[index]?.questions?.length; //한 세트의 문항 수

  //FIXME: part1 해당 세트가 체크가 완료되어있는지 검사한다. 체크가 완료된 경우 api를 호출한다.
  const checkIfTargetQuestionSetIs1FinishPart1 = (setNumber) => {
    const check = getNotCheckSetInfoPart1(examAnswerPart1, setNumber);
    if (check.QsetNum === null && check.Qnum === null) {
      changeNull(examAnswerPart1, setNumber);
    }
  };

  //인성 파트 1 답안 클릭 시 배열에 업데이트
  const updateAnswerPart1 = (Qset, Qnum, Qvalue) => {
    const newArr = [...examAnswerPart1];
    if (
      newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMark === Qvalue
    ) {
      return;
    }
    const data = {
      questionNo: Qnum,
      questionMark: Qvalue,
    };

    //questionNo이 있을 경우
    if (newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionNo) {
      newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMark = Qvalue;
    } else {
      //questionNo이 없을 경우
      newArr[Qset - 1][(Qnum - 1) % questionSetCount] = data;
      newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMarkIpsative =
        null;
    }
    setExamAnswerPart1(newArr);
    initialState?.exam &&
      checkIfTargetQuestionSetIs1FinishPart1(arrayQuestionSetNo - 1);
  };

  //멀다 가깝다를 클릭 했을 경우
  const updateAnswerIpsative = (Qset, Qnum, Qvalue) => {
    const data = {
      questionNo: Qnum,
      questionMarkIpsative: Qvalue,
    };
    const newArr = [...examAnswerPart1];
    if (
      newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMarkIpsative ===
      Qvalue
    ) {
      return;
    }
    //만약 클릭한 ipasativevalue값이 이미 배열에 존재 하는지 안하는지
    const index = newArr[Qset - 1].findIndex(
      (element) => element.questionMarkIpsative === Qvalue
    );
    //questionNo이 있을 경우
    //클릭한 값이 이미 배열에 존재 하면 그 값을 넣고 존재한 위치에 null을 넣음
    //존재 하지 않으면 값을 넣음
    if (newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionNo) {
      if (index === -1) {
        newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMarkIpsative =
          Qvalue;
      } else {
        newArr[Qset - 1][index].questionMarkIpsative = null;
        newArr[Qset - 1][(Qnum - 1) % questionSetCount].questionMarkIpsative =
          Qvalue;
      }

      //questionNo이 없을 경우
    } else {
      if (index === -1) {
        newArr[Qset - 1][(Qnum - 1) % questionSetCount] = data;
      } else {
        newArr[Qset - 1][index].questionMarkIpsative = null;
        newArr[Qset - 1][(Qnum - 1) % questionSetCount] = data;
      }
    }
    setExamAnswerPart1(newArr);
    // 체크를 할 때마다 해당 세트 문항이 모두 체크됐는지 확인하는 함수를 추가로 호출한다.
    initialState.exam && checkIfTargetQuestionSetIs1FinishPart1(Qset - 1);
  };
  const language = i18n.language;
  return (
    <div css={totalWrapper({ nowPageSetNum })}>
      <div css={tableLeft}>
        <div className="table-num" css={tableNum}>
          {questionList.questionSetNo}
        </div>
      </div>
      <div css={tableRightWrapper}>
        {questionList?.questions?.map((question, i) => (
          <div
            key={i}
            css={[
              tableRight({ nowPageSetNum, language }),
              css`
                &:hover {
                  background-color: #e9e9e9;
                }
              `,
            ]}
          >
            <div className="table-question-num">{question.questionSubText}</div>
            <div className="table-question">{question.questionText}</div>
            <Tooltip
              color={"red"}
              placement="left"
              title={t(`exam.insung.tooltip.questionNum`)}
              open={
                numSetCheck.QsetNum === index + 1 && numSetCheck.Qnum === i + 1
              }
              trigger={"none"}
              key={i}
              css={css`
                background-color: ${numSetCheck.QsetNum === index + 1 &&
                numSetCheck.Qnum === i + 1 &&
                "#FFE5ED"};
              `}
            >
              <div className="table-question-standard" css={pickNum}>
                {[...Array(examData?.maxScale)].map((n, i) => {
                  return (
                    <span
                      key={i}
                      className={
                        examAnswerPart1 &&
                        examAnswerPart1[arrayQuestionSetNo - 1] &&
                        examAnswerPart1[arrayQuestionSetNo - 1][
                          (question.questionNo - 1) % questionSetCount
                        ]?.questionMark ===
                          i + 1
                          ? "picked"
                          : "pick"
                      }
                      onClick={() => {
                        updateAnswerPart1(
                          arrayQuestionSetNo,
                          question.questionNo,
                          i + 1
                        );
                      }}
                    >
                      {i + 1}
                    </span>
                  );
                })}
              </div>
            </Tooltip>
            <Tooltip
              color={"red"}
              placement="left"
              title={t(`exam.insung.tooltip.questionSet`)}
              open={
                arrayQuestionSetNo === numSetCheck.QsetNum &&
                numSetCheck.Qnum === null &&
                i === 0
              }
              trigger={"none"}
              css={css`
                background-color: ${arrayQuestionSetNo ===
                  numSetCheck.QsetNum &&
                numSetCheck.Qnum === null &&
                "#FFE5ED"};
              `}
            >
              {examData?.ipsativeYN === "Y" ? (
                <div className="table-question-far-near">
                  <div
                    className="far"
                    css={
                      examAnswerPart1 &&
                      examAnswerPart1[arrayQuestionSetNo - 1] &&
                      examAnswerPart1[arrayQuestionSetNo - 1][
                        (question.questionNo - 1) % questionSetCount
                      ]?.questionMarkIpsative === 0
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      updateAnswerIpsative(
                        arrayQuestionSetNo,
                        question.questionNo,
                        0
                      );
                    }}
                  >
                    {language === "KOR" ? "멀" : "L"}
                  </div>
                  <div
                    className="near"
                    css={
                      examAnswerPart1 &&
                      examAnswerPart1[arrayQuestionSetNo - 1] &&
                      examAnswerPart1[arrayQuestionSetNo - 1][
                        (question.questionNo - 1) % questionSetCount
                      ]?.questionMarkIpsative === 2
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      updateAnswerIpsative(
                        arrayQuestionSetNo,
                        question.questionNo,
                        2
                      );
                    }}
                  >
                    {language === "KOR" ? "가" : "M"}
                  </div>
                </div>
              ) : null}
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

const totalWrapper = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: ${nowPageSetNum === 1 ? "16px" : "14px"};
  border-bottom: 2px solid #5b5b5b;
`;

const tableLeft = css`
  display: flex;
  flex-direction: row;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
`;
const tableRightWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const tableRight = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  height: ${nowPageSetNum === 1 ? "80px" : "50px"};

  &:not(:last-child) {
    border-bottom: 1px solid #5b5b5b;
  }
  .table-question-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
  }
  .table-question {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 200px;
  }
  .table-question-standard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 380px;
    border-left: 1px solid #3b3b3b;
  }
  .table-question-far-near {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100px;
    border-left: 1px solid #3b3b3b;
    .far {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
    .near {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
  }
`;

const tableNum = () => css`
  width: 100%;
  height: 100%;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
`;

const checkBtn = () => css`
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 50%;
  height: 100%;
  transition: all 0.1s;
  &:hover {
    cursor: pointer;
    background-color: #d2d3d4;
  }
`;

const checkedBtn = css`
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  background-color: #3b3b3b;
  color: #fefefe;
`;
const pickNum = css`
  width: 20%;
  text-align: center;
  height: 100%;
  .pick {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    transition: all 0.1s;
    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #d2d3d4;
      color: #3b3b3b;
      cursor: pointer;
    }
  }
  .picked {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    background-color: #3b3b3b;
    color: #fff;

    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #3b3b3b;
      color: #fff;
      cursor: pointer;
    }
  }
`;
