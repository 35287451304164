/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_ATTENDANCE } from "../../_reducers/examUserDataAction";
import { Loading } from "../modal/Loading";
import { useRecord } from "../../hooks/useRecord";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import { ModalTemplate } from "../base/ModalTemplate";
import { BasicButton } from "../system/button/BasicButton";
import { SOCKET_EVENT } from "../../adapters/socket.enum";
import { useTranslation } from "react-i18next";

export const PendingIdCheck = ({
  isSentBack,
  setSendIdCard,
  idCardRejectHandler,
  setIsModal,
  isModal,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { startRecord } = useRecord();
  const userData = useSelector((state) => state.examUserDataAction);
  const { socket } = useRoomConnection();

  useEffect(() => {
    socket?.on(SOCKET_EVENT.USER_ID_CARD, (message) => {
      //신분증 완료
      if (message === "CONFIRM") {
        setIsModal(true);
        //감독관이 신분증을 확인 할 경우 녹화 재 시작
        // startRecord();
      }
      //반려
      else if (message === "REJECT") {
        idCardRejectHandler();
      }
    });

    return () => {
      socket.off(SOCKET_EVENT.ID_CARD);
    };
  }, []);

  const IdCheckModal = () => {
    if (isSentBack) {
      return (
        <ModalTemplate closeBtn={true} setModal={setSendIdCard} isTest={true}>
          <div className="id-check-sub-text">{t(`idCard.modal.sentBack`)}</div>
          <div
            style={{
              paddingTop: "20px",
            }}
          >
            <BasicButton
              wid="s"
              hei="s"
              label={t(`idCard.button.confirm`)}
              onClick={() => setSendIdCard(false)}
            />
          </div>
        </ModalTemplate>
      );
    } else if (isModal) {
      return (
        <ModalTemplate isTest={true}>
          <div className="id-check-sub-text">{t(`idCard.modal.confirm`)}</div>
          <div
            style={{
              paddingTop: "20px",
            }}
          >
            <BasicButton
              wid="s"
              hei="s"
              label={t(`idCard.button.confirm`)}
              onClick={() => {
                dispatch({ type: UPDATE_ATTENDANCE, data: "Y" });
                if (userData.status.examStatus === 0) {
                  navigate(`/test/timetable`, { replace: true });
                } else {
                  navigate(`/test/maintest`, { replace: true });
                }
              }}
            />
          </div>
        </ModalTemplate>
      );
    } else {
      return (
        <ModalTemplate closeBtn={false} isTest={true} loading={true}>
          <div className="id-check-sub-text">
            {t(`idCard.modal.confirmPending`)}
          </div>
          <Loading />
        </ModalTemplate>
      );
    }
  };

  return <div css={totalWrapper}>{IdCheckModal()}</div>;
};

const totalWrapper = css`
  .id-check-title {
    padding: 18px;
    font-size: 20px;
    text-align: center;
  }
  .id-check-sub-text {
    padding-top: 30px;
    white-space: pre;
    text-align: center;
  }
`;
