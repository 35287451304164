/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useModal } from "../../../hooks/useModal";
import { useLogout } from "../../../hooks/useLogout";
import { ModalTemplate } from "../../base/ModalTemplate";
import { BasicButton } from "../../system/button/BasicButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useRecord } from "../../../hooks/useRecord";
import { useRoomConnection } from "../../../adapters/useRoomConnection";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const CloseButton = () => {
  const { t } = useTranslation();

  const [modal, setModal, modalToggle] = useModal();
  const { logout } = useLogout();
  const { stopRecordHandler } = useRecord();
  const { sendToExamTopic } = useRoomConnection();
  const socketData = useSelector((state) => state.socketAction);
  const userData = useSelector((state) => state.examUserDataAction);

  return (
    <>
      <div css={totalWrapper}>
        <button onClick={modalToggle} css={closeButtonWrap}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
        </button>
      </div>
      {modal && (
        <ModalTemplate
          setModal={setModal}
          closeBtn={true}
          isTest={false}
          zIndex={6000}
        >
          <div css={modalContent}> {t(`modal.closeModal`)}</div>
          <div css={modalButton}>
            {/* 응용프로그램 연결 시 /logout 으로 이동하면 자동 종료 됨*/}
            <BasicButton
              wid="s"
              hei="s"
              className="btHover"
              label={t(`modal.yse`)}
              onClick={() => {
                stopRecordHandler();
                if (userData?.status?.examStatus === 2) {
                  sendToExamTopic(
                    socketData?.viewerSocket?.viewerSocketId,
                    "COMPLETE"
                  );
                } else {
                  sendToExamTopic(
                    socketData?.viewerSocket?.viewerSocketId,
                    "EXIT"
                  );
                }
                logout();
              }}
            />
            <BasicButton
              wid="s"
              hei="s"
              onClick={() => {
                setModal(false);
              }}
              label={t(`modal.no`)}
              className="btHover"
            />
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

const totalWrapper = css`
  position: fixed;
  right: 20px;
  padding: 10px;
  z-index: 5555;
  cursor: pointer;
`;

const closeButtonWrap = css`
  display: flex;
  padding: 6px;
  background: #3b3b3b;
  position: fixed;
  right: 0px;
  z-index: 5555;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: #474747;
    color: #ffffff;
    opacity: 0.8;
  }
`;

const modalButton = (theme) => css`
  display: flex;
  width: 100%;
  justify-content: space-around;

  .btHover {
    &:hover {
      cursor: pointer;
    }
    a {
      color: ${theme.fontColor};
    }
  }
`;

const modalContent = css`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
`;
