/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_DEVICE_AUDIO,
  SET_DEVICE_VIDEO,
  SET_DEVICE_SCREEN_STREAM,
  SET_DEVICE_CAM_STREAM,
  SET_DEVICE_VIDEO_LABEL,
} from "../../_reducers/deviceAction";
import { Inquire } from "../../components/inquire/Inquire";
import { SHOW_CAM, SHOW_SCREEN_CAM } from "../../_reducers/showUtilsAction";
import { DeviceCheckBox } from "../../components/base/DeviceCheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeLow, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import { RecButton } from "../../components/system/button/RecButton";
import { useCreateMediaStream } from "../../hooks/useCreateMediaStream";
import { ALERT_MODAL_ON, PENDING_MODAL_ON } from "../../_reducers/modalAction";
import { checkNetworkDownlink } from "../../utils/networkSpeed";
import { useRecord } from "../../hooks/useRecord";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { audioContext, audioFrequency } from "../../utils/audioContext";
import { USER_NETWORK_SPEED } from "../../_reducers/userAction";
import { useTranslation } from "react-i18next";
import "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import { SET_AI_MODEL } from "../../_reducers/aiAction";
import { PendingAi } from "../../components/pending/PendingAi";
import { UPDATE_ATTENDANCE } from "../../_reducers/examUserDataAction";
import { TIME_STOP } from "../../_reducers/timeAction";
import { LanguageSelect } from "../../components/system/LanguageSelect";
import { SET_PRETEST_REQUEST } from "../../_reducers/pretestAction";

export const DeviceCheckPage = React.memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { completer } = useSelector((state) => state.userAction);
  const { mobileShareUseYN, screenShareUseYN } = useSelector(
    (state) => state.userAction.data
  );
  const { getWebcam, getScreen } = useCreateMediaStream();
  const { startRecord } = useRecord();

  const [networkModal, setNetworkModal] = useState(false);

  const userData = useSelector((state) => state.examUserDataAction);
  const { attendanceYN, uploadIdFileUrl } = useSelector(
    (state) => state.examUserDataAction
  );
  const { networkSpeed } = useSelector((state) => state.userAction);
  const { roomUseYN } = useSelector((state) => state.userAction.data);

  const [audioStream, setAudioStream] = useState(null);
  const [videoDeviceId, setVideoDeviceId] = useState(null);
  const [audioDeviceId, setAudioDeviceId] = useState(null);

  const status = useSelector((state) => state.examUserDataAction?.status);
  const volumeTestPercent = 20;

  const { video, audio, videoLabel } = useSelector(
    (state) => state.deviceAction
  );

  const [isConnectLoading, setIsConnectLoading] = useState({
    cam: true,
    audio: true,
    screen: true,
    sound: false,
  });

  const videoRef = useRef(null);
  const screenRef = useRef(null);
  const soundMeter = useRef(null);
  const soundRef = useRef(null);

  const [device, setDevice] = useState({
    audioinput: [],
    audiooutput: [],
    videoinput: [],
  });

  const [deviceTest, setDeviceTest] = useState({
    cam: false,
    audio: false,
    screen: false,
  });

  const [vol, setVol] = useState(0);
  const [isVolCheck, setVolCheck] = useState(false);
  const [isSound, setIsSound] = useState(false);

  const [isAiWaitingModal, setAiWaitingModal] = useState(false);
  const [model, setModel] = useState(false);
  const time = useRef(null);
  const aiTimer = useRef(null);

  useEffect(() => {
    getAllDevices();
  }, []);

  useEffect(() => {
    const loadedAiCocoSsdModel = async () => {
      setAiWaitingModal(true);
      await cocoSsd
        .load()
        .then((loadedModel) => {
          setModel(loadedModel);
        })
        .catch((err) => {
          dispatch({ type: ALERT_MODAL_ON, data: err });
          setAiWaitingModal(false);
        });
    };
    loadedAiCocoSsdModel();

    return () => {
      clearTimeout(aiTimer.current);
      clearTimeout(time);
      setAiWaitingModal(false);
    };
  }, []);

  useEffect(() => {
    time.current = setTimeout(() => {
      if (model) {
        clearTimeout(time);
        setAiWaitingModal(false);
      }
    }, 5000);

    dispatch({
      type: SET_AI_MODEL,
      data: model,
    });
  }, [model]);

  const getAllDevices = async () => {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        var deviceMap = devices.reduce(
          (map, device) => {
            if (device.kind in map) {
              if (!device.label.includes("OBS")) {
                map[device.kind].push(device);
              }
            }
            return map;
          },
          {
            audioinput: [],
            audiooutput: [],
            videoinput: [],
          }
        );
        setDevice(deviceMap);
        dispatch({
          type: SET_DEVICE_VIDEO,
          data: video ? video : deviceMap.videoinput[0].deviceId,
        });
        dispatch({
          type: SET_DEVICE_VIDEO_LABEL,
          data: videoLabel ? videoLabel : deviceMap.videoinput[0].label,
        });
        dispatch({
          type: SET_DEVICE_AUDIO,
          data: audio ? audio : deviceMap.audioinput[0].deviceId,
        });
      })
      .catch((err) => {
        console.log("디바이스 목록을 가져오는 중 오류가 발생했습니다: ", err);
      });
  };

  const updateDeviceByKind = (deviceKind) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const filteredDevices = devices.filter((data) => {
        return data.kind === deviceKind && !data.label.includes("OBS");
      });
      setDevice((prevState) => ({
        ...prevState,
        [deviceKind]: filteredDevices,
      }));
      if (deviceKind === "videoinput") {
        dispatch({
          type: SET_DEVICE_VIDEO,
          data: filteredDevices[0].deviceId,
        });
        dispatch({
          type: SET_DEVICE_VIDEO_LABEL,
          data: filteredDevices[0].label,
        });
      } else if (deviceKind === "audioinput") {
        dispatch({
          type: SET_DEVICE_AUDIO,
          data: filteredDevices[0].deviceId,
        });
      } else if (deviceKind === "audiooutput") {
      }
    });
  };

  useEffect(() => {
    const netDownStr = checkNetworkDownlink();
    if (netDownStr === false) {
      setNetworkModal(true);
      dispatch({ type: USER_NETWORK_SPEED, data: netDownStr });
    } else {
      setNetworkModal(false);
    }
  }, [networkSpeed]);

  useEffect(() => {
    let myInterval;
    if (audioStream) {
      const { analyser, bufferLength, dataArray } = audioContext(audioStream);
      if (true) {
        myInterval = setInterval(() => {
          analyser.getByteFrequencyData(dataArray);
          const testV = audioFrequency(dataArray, bufferLength);
          if (Math.floor((testV / 256) * 100) >= volumeTestPercent) {
            setVolCheck(true);
          }
          setVol(Math.floor((testV / 256) * 100));
        }, 30);
      }
    }

    return () => clearInterval(myInterval);
  }, [audioStream]);

  const nextPage = useCallback(() => {
    dispatch({ type: SHOW_SCREEN_CAM, data: true });
    dispatch({ type: SHOW_CAM, data: true });
    if (attendanceYN === "Y" || userData.status.examStatus === 1) {
      startRecord();
    }
    //본검사 일 경우
    if (completer) {
      //재접속 했을 경우 녹화 시작
      if (attendanceYN === "Y" && userData?.status?.examStatus === 1) {
        startRecord();
      }
      //모바일 디바이스 사용 유무
      if (mobileShareUseYN === "Y") {
        navigate("/test/device/mobile", { replace: true });
      } else if (roomUseYN === "Y") {
        //시험 시작 전 신분증이 완료 된 상태(타임 테이블)
        if (attendanceYN === "Y") {
          if (
            parseInt(sessionStorage.getItem("remainingSeconds")) !== 0 &&
            userData?.status?.examStatus === 0
          ) {
            navigate(`/test/timetable`, { replace: true });
          } else {
            navigate(`/test/maintest`, { replace: true });
            dispatch({ type: PENDING_MODAL_ON });
          }
        } else {
          navigate("/test/idcard", { replace: true });
        }
      }
      if (roomUseYN === "N") {
        if (!uploadIdFileUrl) {
          navigate("/test/snapshot", { replace: true });
        } else {
          if (status.examStatus === 1) {
            dispatch({ type: UPDATE_ATTENDANCE, data: "Y" });
            navigate(`/test/maintest`, { replace: true });
            dispatch({ type: TIME_STOP, data: false });
          } else {
            navigate(`/test/pretest`, { replace: true });
            dispatch({
              type: SET_PRETEST_REQUEST,
              data: {
                examNo: 1,
                examSubNo: 1,
                pageNo: 1,
                mode: "MOVE",
              },
            });
          }
        }
      }
    }
    //사전 점검 일 경우
    else {
      if (mobileShareUseYN === "Y") {
        //QR 화면 공유 화면 페이지
        navigate("/test/device/mobile", { replace: true });
      } else {
        //본인 확인 페이지
        navigate(`/test/snapshot`, { replace: true });
      }
    }
  }, [completer, mobileShareUseYN, navigate, startRecord]);

  const onVideoChange = (e) => {
    dispatch({
      type: SET_DEVICE_VIDEO,
      data: e.target.value,
    });
    dispatch({
      type: SET_DEVICE_VIDEO_LABEL,
      data: e.target.options[e.target.selectedIndex].getAttribute("name"),
    });
    setVideoDeviceId(e.target.value);
  };

  const onAudioChange = (e) => {
    dispatch({
      type: SET_DEVICE_AUDIO,
      data: e.target.value,
    });
    setAudioDeviceId(e.target.value);
  };

  const onSoundChange = async (e) => {
    let audioSound = soundRef.current;
    audioSound.setSinkId(e.target.value);
  };

  //마이크 테스크
  const startAudio = () => {
    soundRef.current.play();
    setIsSound(true);
  };
  const pauseAudio = () => {
    soundRef.current.pause();
    setIsSound(false);
  };

  useEffect(() => {
    const constraints = {
      audio: {
        deviceId: audio ? { exact: audio } : true,
      },
      video: false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          if (track.kind === "audio") {
            if (track.enabled === true) {
              if (!audio && device.audioinput.length === 0) {
                updateDeviceByKind("audioinput");
                updateDeviceByKind("audiooutput");
              }
              setIsConnectLoading((prevState) => ({
                ...prevState,
                audio: false,
              }));
              setDeviceTest((prev) => ({ ...prev, audio: true }));
            }
          }
        });
        setAudioStream(stream);
      })
      .catch((err) => {
        setIsConnectLoading((prevState) => ({
          ...prevState,
          audio: false,
        }));
        setDeviceTest((prev) => ({ ...prev, audio: false }));
      });
  }, [audioDeviceId, audio, device]);

  useEffect(() => {
    const initialConstraints = {
      audio: false,
      video: true,
      facingMode: { exact: "user" },
    };

    const constraints = {
      audio: false,
      video: {
        deviceId: video,
        width: 320,
        height: 240,
        frameRate: { max: 4 },
      },
      facingMode: { exact: "user" },
    };

    navigator.mediaDevices
      .getUserMedia(video ? constraints : initialConstraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        stream.getTracks().forEach((track) => {
          if (track.kind === "video") {
            if (track.enabled === true) {
              if (!video && device.videoinput.length === 0) {
                updateDeviceByKind("videoinput");
              }
              setIsConnectLoading((prevState) => ({
                ...prevState,
                cam: false,
              }));
              setDeviceTest((prev) => ({ ...prev, cam: true }));
            }
          }
        });
      })
      .catch((err) => {
        setIsConnectLoading((prevState) => ({
          ...prevState,
          cam: false,
        }));
        setDeviceTest((prev) => ({ ...prev, cam: false }));
      });
  }, [videoDeviceId, video, device]);

  useEffect(() => {
    if (screenShareUseYN === "Y") {
      getScreen((stream) => {
        screenRef.current.srcObject = stream;
        setDeviceTest((prev) => ({ ...prev, screen: true }));
        setIsConnectLoading((prevState) => ({
          ...prevState,
          screen: false,
        }));
        dispatch({ type: SET_DEVICE_SCREEN_STREAM, data: stream });
      });
    }
  }, []);

  useEffect(() => {
    if (deviceTest.cam && deviceTest.audio) {
      getWebcam((stream) => {
        dispatch({ type: SET_DEVICE_CAM_STREAM, data: stream });
      });
    }
  }, [
    deviceTest.cam,
    deviceTest.audio,
    videoDeviceId,
    audioDeviceId,
    audio,
    video,
  ]);

  return (
    <>
      <LanguageSelect />
      <div css={totalContents}>
        {isAiWaitingModal && <PendingAi />}
        <div css={totalWrapper}>
          <p css={alertText}>
            {t(`device.text.title`)}
            <br />
            <span>{t(`device.text.describe`)}</span>
          </p>
          <div css={contentWrapper}>
            <DeviceCheckBox
              title={t(`device.deviceCheckBox.cam.text`)}
              subTitle={t(`device.deviceCheckBox.cam.subText`)}
              testSuccess={deviceTest.cam}
              loading={isConnectLoading.cam}
            >
              <div style={{ position: "relative" }}>
                <video
                  width="200"
                  height="150"
                  style={{
                    transform: "rotateY(180deg)",
                  }}
                  css={videoBox}
                  ref={videoRef}
                  autoPlay={true}
                  muted
                ></video>
              </div>
              <select
                css={[
                  selectBox,
                  css`
                    background-color: ${deviceTest?.cam
                      ? "#707070"
                      : "#efefef"};
                  `,
                ]}
                onChange={onVideoChange}
              >
                {device?.videoinput?.map((v, index) => (
                  <option key={index} value={v.deviceId} name={v.label}>
                    {v.label}
                  </option>
                ))}
              </select>
            </DeviceCheckBox>
            {screenShareUseYN === "Y" && (
              <DeviceCheckBox
                title={t(`device.deviceCheckBox.screen.text`)}
                testSuccess={deviceTest.screen}
                loading={isConnectLoading.screen}
              >
                <video
                  css={videoBox}
                  ref={screenRef}
                  muted
                  autoPlay={true}
                ></video>
              </DeviceCheckBox>
            )}
            <DeviceCheckBox
              title={t(`device.deviceCheckBox.mike.text`)}
              testSuccess={deviceTest.audio}
              loading={isConnectLoading.audio}
              isVolCheck={isVolCheck}
            >
              <div
                css={soundBarWrapper({
                  vol,
                  isVolCheck,
                  testSuccess: deviceTest.audio,
                  volumeTestPercent,
                })}
              >
                <p className="text">
                  {t(`device.deviceCheckBox.mike.subText`)}
                </p>
                <div className="mic-volume-wrapper">
                  <div className="mic-volume">
                    <div className="middle-stick" />
                    <div
                      ref={soundMeter}
                      className="mic-volume-fill audioVolumeGauge"
                    />
                  </div>
                </div>
              </div>
              <select
                css={[
                  selectBox,
                  css`
                    background-color: ${deviceTest?.audio
                      ? "#707070"
                      : "#efefef"};
                  `,
                ]}
                onChange={onAudioChange}
              >
                {device?.audioinput?.map((v, index) => (
                  <option key={index} value={v.deviceId}>
                    {v.label}
                  </option>
                ))}
              </select>
            </DeviceCheckBox>
            <DeviceCheckBox
              title={t(`device.deviceCheckBox.speaker.text`)}
              testSuccess={true}
              loading={isConnectLoading.sound}
            >
              <div css={outCheck}>
                <div css={outCheckWrap}>
                  <audio ref={soundRef} src="../sound/soundTest.mp3"></audio>
                  {isSound ? (
                    <FontAwesomeIcon
                      onClick={pauseAudio}
                      icon={faVolumeLow}
                      css={soundIcon}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={startAudio}
                      icon={faVolumeXmark}
                      css={soundIcon}
                    />
                  )}
                  <div
                    style={{
                      marginLeft: "15px",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    {t(`device.deviceCheckBox.speaker.describe`)}
                  </div>
                </div>
              </div>
              <select
                css={[
                  selectBox,
                  css`
                    background-color: ${!isConnectLoading.sound
                      ? "#707070"
                      : "#efefef"};
                  `,
                ]}
                onChange={onSoundChange}
              >
                {device?.audiooutput?.map((v, index) => (
                  <option key={index} value={v.deviceId}>
                    {v.label}
                  </option>
                ))}
              </select>
            </DeviceCheckBox>
          </div>
        </div>
        <div css={buttonWrap}>
          <RecButton
            disabled={
              isVolCheck &&
              deviceTest.cam &&
              (screenShareUseYN === "Y" ? deviceTest.screen : true) &&
              deviceTest.audio
                ? false
                : true
            }
            onClick={() => {
              if (
                isVolCheck &&
                deviceTest.cam &&
                (screenShareUseYN === "Y" ? deviceTest.screen : true) &&
                deviceTest.audio
              ) {
                nextPage();
              }
            }}
            label={t(`device.buttonLabel`)}
          />
        </div>
        {networkModal && (
          <ModalTemplate closeBtn={true} setModal={setNetworkModal}>
            {t(`error.serverAlert`)}
          </ModalTemplate>
        )}
        <Inquire />
      </div>
    </>
  );
});

const totalContents = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;
const totalWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tempContainer {
    height: 32px;
  }
  margin-bottom: 10px;
  min-width: 80%;
  height: 75vh;
`;
const alertText = (theme) => css`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.mainColor};
  }
`;

const contentWrapper = css`
  display: flex;
  justify-content: center;
`;

const videoBox = css`
  width: 200px;
  height: 150px;
  background-color: #000000;
`;

const selectBox = css`
  width: 250px;
  padding: 8px 30px 8px 12px;
  font-size: 0.775rem;
  background-color: #efefef;
  text-align: center;
  &:focus {
    outline: none;
  }
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const soundBarWrapper = ({ vol, isVolCheck, volumeTestPercent }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0 0 0;
  .text {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    width: 160px;
    text-align: center;
  }
  .middle-stick {
    display: ${isVolCheck && "none"};
    position: absolute;
    left: ${volumeTestPercent}%;
    transform: translate(-50%, -33%);
    width: 5px;
    height: 25px;
    z-index: 1;
    background-color: red;
    border-radius: 10px;
  }
  .mic-volume-wrapper {
    width: 200px;
    height: 16px;
    display: flex;
    align-items: center;
  }
  .mic-volume {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .mic-volume-fill {
    height: 100%;
    border-radius: 4px;
    background-color: #11b38d;
    transition: 0.3s ease-out;
    width: ${vol}%;
    max-width: 100%;
  }
`;

const outCheck = css`
  padding: 50px 0 60px;
  font-size: 0.875rem;
`;

const outCheckWrap = css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const soundIcon = css`
  cursor: pointer;
  padding: 10px;
  background-color: #131313;
  color: #fefefe;
  border-radius: 50px;
`;

const buttonWrap = css`
  display: flex;
  align-items: center;
  height: 150px;
`;
