import React, { useEffect } from "react";
import { MainTestInsung } from "../../components/test/insung/maintest/MainTestInsung";
import { MainTestJuksung } from "../../components/test/juksung/maintest/MainTestJuksung";
import { useDispatch, useSelector } from "react-redux";
import { SET_EXAMPLE_PAGE } from "../../_reducers/mainExamAction";
import { useDivisionExamData } from "../../hooks/useDivisionExamData";
import { Loading } from "../../components/modal/Loading";

export const MainTestPage = () => {
  const dispatch = useDispatch();
  const examData = useSelector((state) => state.mainExamAction.data);
  const examDivisionData = useDivisionExamData();
  const { loading } = useSelector((state) => state.mainExamAction);

  //예제 문항 시 페이지 초기화
  useEffect(() => {
    dispatch({ type: SET_EXAMPLE_PAGE, data: 0 });
  }, [examData?.testerStatus?.examSubNo]);

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
            background: "white",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Loading />
        </div>
      )}
      {examDivisionData ? (
        examDivisionData?.ipsativeYN === "N" &&
        examDivisionData?.likertYN === "N" ? (
          <MainTestJuksung />
        ) : (
          <MainTestInsung />
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};
