import produce from "immer";
import { PURGE } from "redux-persist";

export const EXAM_USER_DATA = "EXAM_USER_DATA";

export const UPDATE_AGREE = "UPDATE_AGREE";
export const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const UPDATE_MARK_ALL = "UPDATE_MARK_ALL";

const initialState = {
  examSAtTime: null,
  currentTime: null,
  agreeYN: "N",
  attendanceYN: "N",
  status: {},
};

const examUserDataReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EXAM_USER_DATA:
        return action.data;
      case UPDATE_AGREE:
        draft.agreeYN = action.data;
        break;
      case UPDATE_ATTENDANCE:
        draft.attendanceYN = action.data;
        break;
      case UPDATE_USER_STATE:
        draft.status.examStatus = action.data;
        break;
      case UPDATE_MARK_ALL:
        draft.status.markAll = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        return state;
    }
  });
};

export default examUserDataReducer;
