/* eslint-disable react-hooks/rules-of-hooks */

import { useSelector } from "react-redux";
import { SOCKET_EVENT } from "./socket.enum";
import { useRoomConnection } from "./useRoomConnection";

export const sendStatus = () => {
  const { socket } = useRoomConnection();
  const socketData = useSelector((state) => state.socketAction);

  const sendIdCardMessage = (message) => {
    socket?.emit(SOCKET_EVENT.ID_CARD, "to", {
      targetSocketId: socketData?.viewerSocket?.viewerSocketId,
      message: {
        peerId: socketData?.userSocket?.myPeerId,
        message: message,
      },
    });
  };
  return {
    sendIdCardMessage,
  };
};
