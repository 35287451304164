/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import {
  PRETEST_EXAM_REQUEST,
  SET_PRETEST_REQUEST,
} from "../../../_reducers/pretestAction";
import { BasicButton } from "./BasicButton";
import { ExampleModal } from "../../modal/ExampleModal";
import { practiceApi } from "../../../api/practice/practiceApi";
import { PRETEST_EXAM_END_MODAL_ON } from "../../../_reducers/modalAction";
import { QuestionTooltipIcon } from "../icon/QuestionTooltipIcon";
import { API_URL } from "../../../data/api";
import apiClient from "../../../api/apiClient";
import { useTranslation } from "react-i18next";

export const PretestNextPrevButton = ({
  setModal,
  modal,
  insungNextHandler,
  setToolTipIndex,
  toolTip,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    ipsativeYN,
    likertYN,
    examNo,
    examSubNo,
    pageNo,
    examSubNoMax,
    endPageYN,
    examNoMax,
    pageNoMax,
    questionInfoData,
    prevUseYN,
    examSubNoMin,
  } = useSelector((state) => state.pretestAction.data);
  const { roomUseYN } = useSelector((state) => state.userAction?.data);

  const juksungNextHandler = async () => {
    if (endPageYN === "N") {
      if (pageNoMax > pageNo) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            examNo,
            examSubNo,
            mode: "MOVE",
            pageNo: pageNo + 1,
          },
        });
      } else if (examSubNoMax > examSubNo) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            examNo,
            examSubNo: examSubNo + 1,
            mode: "MOVE",
            pageNo: 1,
          },
        });
      } else if (
        examNoMax > examNo &&
        examSubNoMax === examSubNo &&
        pageNoMax === pageNo
      ) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            mode: "MOVE",
            examNo: examNo + 1,
            pageNo: 1,
            examSubNo: 1,
          },
        });
      }
    } else {
      //사전점검 완료
      await apiClient.post(`${API_URL.PRACTICE_END_DATA}`).then(() => {
        dispatch({ type: PRETEST_EXAM_END_MODAL_ON });
      });
    }
  };

  const getSyncPretestDataState = async (examNo, examSubNo, pageNo, mode) => {
    const body = {
      examNo,
      examSubNo,
      pageNo,
      mode,
    };
    const result = await practiceApi.practiceData(body);
    dispatch({
      type: PRETEST_EXAM_REQUEST,
      data: result.data.data,
    });
  };

  const prevHandler = () => {
    if (pageNo > 1) {
      getSyncPretestDataState(examNo, examSubNo, pageNo - 1, "MOVE");
    } else if (pageNo === 1 && examSubNo > 1) {
      getSyncPretestDataState(examNo, examSubNo - 1, pageNo, "PREV-EXAM-SUB");
    } else if (examNo > 1) {
      getSyncPretestDataState(examNo - 1, examSubNo, pageNo, "PREV-EXAM");
    }
  };

  return (
    <div css={buttonWrapper}>
      {/* 정답 및 해설, 예제보기 버튼 */}
      <div className="example-button-wrap">
        <BasicButton
          hei="s"
          onClick={() => setModal(true)}
          label={
            ipsativeYN === "N" && likertYN === "N"
              ? questionInfoData?.questionList[0].buttonNamePopUp
              : questionInfoData?.buttonNamePopUp
          }
        />
        <div className="tool-tip-button">
          <QuestionTooltipIcon onClick={() => setToolTipIndex(0)} />
        </div>
      </div>
      <div className="next-prev-button-wrap">
        {modal === true ? (
          <ExampleModal
            setModal={setModal}
            exImage={
              likertYN === "N" && ipsativeYN === "N"
                ? questionInfoData?.questionList[0]?.popUpImg
                : questionInfoData?.popUpImg
            }
            exTitle={
              likertYN === "N" && ipsativeYN === "N"
                ? questionInfoData?.questionList[0]?.buttonNamePopUp
                : questionInfoData?.buttonNamePopUp
            }
          />
        ) : null}

        {/* 이전 버튼*/}
        {
          // 인성 or 이전버튼 X 일 경우 이전 버튼 X
          likertYN === "Y" || ipsativeYN === "Y" || prevUseYN === "N" ? (
            <div />
          ) : (
            <div className="prev-button-wrap">
              {examSubNoMin === examSubNo && pageNo === 1 ? (
                <div />
              ) : (
                <BasicButton
                  wid="s"
                  hei="s"
                  onClick={() => {
                    toolTip !== "button" && prevHandler();
                  }}
                  label={t(`exam.button.prevLabel`)}
                />
              )}
            </div>
          )
        }

        {/* 다음 버튼 */}
        <div className="next-button-wrap">
          <BasicButton
            wid="s"
            hei="s"
            label={
              endPageYN === "Y" && !roomUseYN === "N"
                ? t(`exam.button.end`)
                : t(`exam.button.nextLabel`)
            }
            onClick={() => {
              if (toolTip === "button" || toolTip === "nextButton") return;
              ipsativeYN === "N" && likertYN === "N"
                ? juksungNextHandler()
                : insungNextHandler();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const buttonWrapper = css`
  width: 100%;
  height: 70px;
  position: relative;
  .next-prev-button-wrap {
    width: 100%;
    .prev-button-wrap {
      position: absolute;
      left: 0;
    }
    .next-button-wrap {
      position: absolute;
      right: 0;
    }
  }
  .example-button-wrap {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto 0;
    display: flex;
    justify-content: center;
    .tool-tip-button {
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }
`;
