import React, { useEffect, useState } from "react";
import { PreTestJuksung } from "../../components/test/juksung/pretest/PreTestJuksung";
import { PreTestInsung } from "../../components/test/insung/pretest/PreTestInsung";
import { useSelector } from "react-redux";
import { Inquire } from "../../components/inquire/Inquire";
import { PendingLoading } from "../../components/pending/PendingLoading";
import { Loading } from "../../components/modal/Loading";
export const PreTestPage = () => {
  const examData = useSelector((state) => state.pretestAction.data);
  const [render, setRender] = useState(null);
  const [modal, setModal] = useState(false);
  const { setpretestLoading } = useSelector((state) => state?.pretestAction);

  useEffect(() => {
    console.log(examData);
  }, [examData]);

  return (
    <>
      <PendingLoading loading={setpretestLoading} />
      {examData?.examNo &&
        (examData?.ipsativeYN === "N" && examData?.likertYN === "N" ? (
          <PreTestJuksung
            setRender={setRender}
            setModal={setModal}
            modal={modal}
          />
        ) : (
          <PreTestInsung
            setRender={setRender}
            render={render}
            modal={modal}
            setModal={setModal}
          />
        ))}
      <Inquire />
    </>
  );
};
