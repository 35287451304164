/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNowExamData } from "../../hooks/useNowExamData";

export const Drawing = ({ canvasRef, contextRef, isMemoOpen }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const nowExamData = useNowExamData();
  const examNo = nowExamData?.testerStatus?.examNo;
  const examSubNo = nowExamData?.testerStatus?.examSubNo;
  const savePage = nowExamData?.testerStatus?.savePage;
  const { completer } = useSelector((state) => state.userAction);
  const { exampleIndex } = useSelector((state) => state.mainExamAction);

  const { setpretestDone } = useSelector((state) => state.pretestAction);

  useEffect(() => {
    if (!completer) {
      if (
        sessionStorage.getItem(
          `Drawing-${nowExamData?.examNo}-${nowExamData?.examSubNo}-${nowExamData?.pageNo}`
        )
      ) {
        const context = canvasRef.current.getContext("2d");
        var image = new Image();
        image.onload = function () {
          context.drawImage(image, 0, 0);
        };
        image.src = sessionStorage.getItem(
          `Drawing-${nowExamData?.examNo}-${nowExamData?.examSubNo}-${nowExamData?.pageNo}`
        );
      } else {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        contextRef.current = context;
        contextRef.current.fillStyle = "#ffffff";
        contextRef.current.fillRect(0, 0, 276, 270);
      }
    }
  }, [
    canvasRef,
    contextRef,
    nowExamData,
    setpretestDone,
    isMemoOpen,
    nowExamData,
  ]);

  useEffect(() => {
    if (completer) {
      if (
        sessionStorage.getItem(
          `Drawing-${examNo}-${examSubNo}-${
            nowExamData?.example ? exampleIndex : savePage
          }`
        )
      ) {
        const canvas = canvasRef.current;
        canvas.width = 276;
        canvas.height = 270;

        //base64을 이미지로 변환
        const context = canvas.getContext("2d");
        var image = new Image();
        image.onload = function () {
          context.drawImage(image, 0, 0);
        };
        image.src = sessionStorage.getItem(
          `Drawing-${examNo}-${examSubNo}-${
            nowExamData?.example ? exampleIndex : savePage
          }`
        );
      } else {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        contextRef.current = context;
        contextRef.current.fillStyle = "#ffffff";
        contextRef.current.fillRect(0, 0, 276, 270);
      }
    }
  }, [examSubNo, examNo, nowExamData?.example, savePage, exampleIndex]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 276;
    canvas.height = 270;

    const context = canvas.getContext("2d");
    context.lineCap = "round";
    context.strokeStyle = "#222222";
    context.lineWidth = "1";
    contextRef.current = context;
  }, [canvasRef, contextRef]);

  //마우스 눌렀을 때
  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    setIsDrawing(true);
  };

  //마우스 움직일 때
  const draw = ({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  //마우스를 뗐을 때
  const stopDrawing = () => {
    contextRef.current.closePath();
    if (!completer) {
      sessionStorage.setItem(
        `Drawing-${nowExamData?.examNo}-${nowExamData?.examSubNo}-${nowExamData?.pageNo}`,
        canvasRef.current.toDataURL()
      );
    } else {
      sessionStorage.setItem(
        `Drawing-${examNo}-${examSubNo}-${
          nowExamData?.example ? exampleIndex : savePage
        }`,
        canvasRef.current.toDataURL()
      );
    }
    setIsDrawing(false);
  };

  return (
    <canvas
      style={{
        height: 270,
        display: isMemoOpen ? "none" : "",
      }}
      css={canvasContainer}
      ref={canvasRef}
      onMouseDown={startDrawing}
      onMouseMove={draw}
      onMouseUp={stopDrawing}
      onMouseLeave={stopDrawing}
    ></canvas>
  );
};

const canvasContainer = css`
  width: calc(100% - 24px);
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  border-radius: 3px;
  cursor: crosshair;
  background-color: white;
`;
