import produce from "immer";
import { PURGE } from "redux-persist";

export const PRETEST_EXAM_REQUEST = "PRETEST_EXAM_REQUEST";
export const SET_PRETEST_REQUEST = "SET_PRETEST_REQUEST";
export const SET_PRETEST_SUCCESS = "SET_PRETEST_SUCCESS";
export const SET_PRETEST_FAILURE = "SET_PRETEST_FAILURE";

const initialState = {
  data: {},

  setpretestLoading: false,
  setpretestDone: false,
  setpretestError: null,
};

const pretestReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      //데이터 관리 만 하는 기능
      case PRETEST_EXAM_REQUEST:
        draft.data = action.data;
        break;
      case SET_PRETEST_REQUEST:
        draft.setpretestLoading = true;
        draft.setpretestDone = false;
        draft.setpretestError = null;
        break;
      case SET_PRETEST_SUCCESS:
        draft.setpretestLoading = false;
        draft.setpretestDone = true;
        draft.data = action.data;

        break;
      case SET_PRETEST_FAILURE:
        draft.setpretestLoading = false;
        draft.setpretestError = action.error;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        break;
    }
  });
};

export default pretestReducer;
