/** @jsxImportSource @emotion/react */
import React from "react";
import { css, useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const EndSteps = ({ activeStep, steps }) => {
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  const theme = useTheme();

  return (
    <div css={mainContainer}>
      <div css={stepContainer({ theme, width })}>
        {steps.map(({ step, label, status }, i) => (
          <div css={stepWrapper} key={step}>
            <div css={stepStyle({ theme, status, now: activeStep === i + 1 })}>
              {activeStep === steps.length || activeStep > step ? (
                status === "error" ? (
                  <div css={errorMark}>
                    <FontAwesomeIcon icon={faClose} />
                  </div>
                ) : (
                  <div css={checkMark}>L</div>
                )
              ) : (
                <div css={stepCount({ theme, now: activeStep === i + 1 })}>
                  {step}
                </div>
              )}
            </div>
            <div css={stepsLabelContainer}>
              <div css={stepLabel} key={step}>
                {label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mainContainer = css`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`;

const stepContainer = ({ theme, width }) => css`
  display: flex;
  justify-content: space-between;
  margin: 70px 0 100px 0;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #e7e8f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: ${theme.mainColor};
    height: 4px;
    width: ${width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const stepWrapper = css`
  position: relative;
  z-index: 1;
`;

const stepStyle = ({ theme, now, status }) => css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${now || status === "complete"
      ? theme?.mainColor
      : status === "error"
      ? "#ff4949"
      : "#e7e8f3"};

  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const stepCount = ({ theme, now }) => css`
  font-size: 19px;
  color: ${now ? theme?.mainColor : "#e7e8f3"};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const stepsLabelContainer = css`
  width: 300px;
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const stepLabel = css`
  font-size: 15px;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const errorMark = css`
  font-size: 26px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ff4949;
`;

const checkMark = (theme) => css`
  font-size: 26px;
  font-weight: 600;
  color: ${theme.mainColor};
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;
