/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";

export const InsungLayout = ({ children }) => {
  return <div css={contentWrapper()}>{children}</div>;
};

const breakpoints = [1100, 1280, 1920];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const contentWrapper = () => css`
  display: grid;
  padding-top: 15px;
  width: 90%;

  ${mq[0]} {
    width: 90%;
  }
  ${mq[1]} {
    width: 1200px;
  }
  ${mq[2]} {
    width: 1500px;
  }
  margin: 0 auto;
  position: relative;
`;
