/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { getTextColorByBackgroundColor } from "../../../utils/color";

export const RecButton = ({
  label,
  onClick,
  disabled = false,
  children,
  wid = false,
  hei = false,
}) => {
  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.mainColor);

  return (
    <button
      tabIndex={-1}
      onClick={() => {
        !disabled && onClick();
      }}
      css={[
        disabled ? disRecButton : recButton,
        css`
          width: ${wid === "s" ? "120px" : "200px"};
          height: ${hei === "s" ? "40px" : "50px"};
          color: ${!disabled && fontColor};
        `,
      ]}
    >
      {children || label}
    </button>
  );
};

const recButton = (theme) => css`
  height: 50px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: ${theme.mainColor};
  font-size: 18px;
  svg {
    padding-right: 12px;
  }
`;
const disRecButton = () => css`
  height: 44px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #e9e9e9e9;
  font-size: 18px;
  color: #131313;
`;
