import produce from "immer";
import { PURGE } from "redux-persist";

export const initialState = {
  video: false,
  audio: false,
  sound: false,
  videoLabel: false,
  videoStream: false,
  screenStream: false,
  recordPlay: false,
};

export const SET_DEVICE_VIDEO_LABEL = "SET_DEVICE_VIDEO_LABEL";
export const SET_DEVICE_VIDEO = "SET_DEVICE_VIDEO";
export const SET_DEVICE_AUDIO = "SET_DEVICE_AUDIO";
export const SET_DEVICE_SCREEN_STREAM = "SET_DEVICE_SCREEN_STREAM";
export const SET_DEVICE_CAM_STREAM = "SET_DEVICE_CAM_STREAM";

const deviceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_DEVICE_VIDEO_LABEL:
        draft.videoLabel = action.data;
        break;
      case SET_DEVICE_VIDEO:
        draft.video = action.data;
        break;
      case SET_DEVICE_AUDIO:
        draft.audio = action.data;
        break;
      case SET_DEVICE_SCREEN_STREAM:
        draft.screenStream = action.data;
        break;
      case SET_DEVICE_CAM_STREAM:
        draft.videoStream = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        break;
    }
  });

export default deviceReducer;
