/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDivisionExamData } from "../../../hooks/useDivisionExamData";
import { useLocation } from "react-router-dom";

export const JuksungQuestionText = () => {
  const location = useLocation();

  const initialState = useSelector((state) => state.mainExamAction.data);
  const { exampleIndex } = useSelector((state) => state.mainExamAction);
  const questionListIndex = initialState?.exam ? 0 : exampleIndex;
  const examData = useDivisionExamData();
  const pretestData = useSelector((state) => state.pretestAction.data);
  const pretestListData = pretestData?.questionInfoData?.questionList;
  const questionListData =
    examData?.questionInfoData?.questionList[questionListIndex];
  const { completer } = useSelector((state) => state.userAction);
  const [isSubText, setIsSubText] = useState(false);
  const url = location.pathname;

  const subText = completer
    ? questionListData?.questionSubText
    : pretestListData[exampleIndex].questionSubText;

  const returnQuestion = () => {
    if (url === "/test/pretest") {
      return pretestListData && pretestListData[questionListIndex];
    } else {
      return questionListData;
    }
  };

  const questionData = returnQuestion();
  const subQuestionBox = returnQuestion()?.questionSubUseYN;

  useEffect(() => {
    if (subQuestionBox === "Y") {
      if (subText?.includes("<img")) {
        setIsSubText("img");
      } else {
        setIsSubText("text");
      }
    }
  }, [subQuestionBox, subText]);
  return (
    <div css={contentWrapper}>
      <div style={{ display: "flex" }}>
        <div css={questionTitle}>
          {questionData?.questionNo}
          .&nbsp;&nbsp;
          <div
            dangerouslySetInnerHTML={{
              __html: `${questionData?.questionText?.replace(/\n/g, "<br/>")}`,
            }}
          />
        </div>
      </div>
      <div
        css={css`
          white-space: pre-wrap;
          line-height: 1.8;
          display: flex;
          background-color: ${subQuestionBox === "Y" && "#f4f4f4"};
          padding: ${subQuestionBox === "Y" && isSubText === "text" && "25px"};
        `}
      >
        {subQuestionBox === "Y" && (
          <div
            css={questionSubtext}
            dangerouslySetInnerHTML={{
              __html: `${questionData?.questionSubText?.replace(
                /\n/g,
                "<br/>"
              )}`,
            }}
          />
        )}
      </div>
    </div>
  );
};

const questionTitle = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 18px;
  white-space: pre-wrap;
  p,
  span {
    font-size: 18px !important;
  }
`;

const contentWrapper = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-wrap: nowrap;
  .katex {
    font-size: 18px;
    font: unset;
  }

  .katex-html {
    display: none;
  }
  p {
    margin: 0;
    word-break: keep-all;
  }
  @media (max-width: 1530px) {
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const questionSubtext = css`
  width: 100%;
`;
