export const RTCConfig = {
  iceServers: [
    {
      urls: [
        `stun:${process.env.REACT_APP_CON_WEBRTC_SERVER}:3478`,
        `stun:stun.l.google.com:19302`,
      ],
    },
    {
      urls: [
        `turn:${process.env.REACT_APP_CON_WEBRTC_SERVER}:3478?transport=udp`,
        `turn:${process.env.REACT_APP_CON_WEBRTC_SERVER}:3478?transport=tcp`,
      ],
      username: `${process.env.REACT_APP_CON_WEBRTC_USERNAME}`,
      credential: `${process.env.REACT_APP_CON_WEBRTC_CREDENTIAL}`,
    },
    {
      urls: `${process.env.REACT_APP_TURN_URL}`,
      username: `${process.env.REACT_APP_TURN_USERNAME}`,
      credential: `${process.env.REACT_APP_TURN_CREDENTIAL}`,
    },
  ],
};
