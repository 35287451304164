import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SOCKET_EVENT } from "../adapters/socket.enum";
import { useRoomConnection } from "../adapters/useRoomConnection";
import { TIME_STOP } from "../_reducers/timeAction";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const socketData = useSelector((state) => state.socketAction);
  const { sendToExamTopic, socket } = useRoomConnection();
  const { completer } = useSelector((state) => state.userAction);
  const { roomUseYN } = useSelector((state) => state.userAction.data);
  const userData = useSelector((state) => state.examUserDataAction);
  //페이지 종료 함수 모두 끝나면 logout page로

  const logout = () => {
    if (completer) {
      //검사가 완전히 종료 일때
      if (userData?.status?.examStatus === 2) {
        //모바일 연결 시 모바일 소켓 끊기(COMPLETE는 모바일 소켓끊기)
        sendToExamTopic(socketData?.mobileSocket?.mobileSocketId, "COMPLETE");
      }
      //중간에 검사를 나갔을 때
      else {
        if (roomUseYN === "N") {
          navigate(`/logout`, { replace: true });
          return;
        }
        socket?.emit(SOCKET_EVENT.USER_DISCONNECT);
      }
      dispatch({ type: TIME_STOP, data: false });
    } else {
      if (roomUseYN === "Y") {
        //사전 점검일 경우 닫기 버튼을 누르면 disconnection 됨 (모바일 연결 끊김)
        socket?.emit(SOCKET_EVENT.USER_DISCONNECT);
      }
    }
    navigate(`/logout`, { replace: true });
  };

  return {
    logout,
  };
};
