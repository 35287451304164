/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RecBadge } from "./badge/RecBadge";
import { RefreshBadge } from "./badge/RefreshBadge";
import { Volume } from "./Volume";

export const Cam = ({
  camRef,
  type,
  vol,
  showRefresh = false,
  showRec = true,
  onLoadedMetadata,
}) => {
  return (
    <div css={camWrapper({ type })}>
      <Volume vol={vol} />
      <video
        ref={camRef}
        autoPlay
        playsInline
        muted
        width="140"
        height="90"
        onLoadedMetadata={onLoadedMetadata}
      />
      {showRec && <RecBadge />}
      {/* {showRefresh && <RefreshBadge />} */}
    </div>
  );
};

const camWrapper = ({ type }) => css`
  position: relative;
  height: 90px;
  video {
    width: 140px;
    height: 100%;
    transform: ${type === "cam" ? "rotateY(180deg)" : ""};
    object-fit: cover;
  }
`;
