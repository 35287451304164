import produce from "immer";
import { PURGE } from "redux-persist";

export const SHOW_CHAT = "SHOW_CHAT";
export const SHOW_CAM = "SHOW_CAM";
export const SHOW_SCREEN_CAM = "SHOW_SCREEN_CAM";
export const SHOW_MOBILE_CAM = "SHOW_MOBILE_CAM";

const initialState = {
  showChat: false,
  showCam: false,
  showScreen: false,
  showMobile: false,
};

const showUtilsReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SHOW_CHAT:
        draft.showChat = action.data;
        break;
      case SHOW_CAM:
        draft.showCam = action.data;
        break;
      case SHOW_SCREEN_CAM:
        draft.showScreen = action.data;
        break;
      case SHOW_MOBILE_CAM:
        draft.showMobile = action.data;
        break;
      case PURGE: {
        return initialState;
      }
      default:
        return state;
    }
  });
};

export default showUtilsReducer;
