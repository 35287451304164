/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { getTextColorByBackgroundColor } from "../../../utils/color";

export const BasicButton = ({
  label,
  onClick,
  disabled = false,
  wid = false,
  hei = false,
}) => {
  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.mainColor);

  return (
    <button
      tabIndex={-1}
      onClick={!disabled ? onClick : undefined}
      css={[
        disabled ? disButton : basicButton,
        css`
          width: ${wid === "s" ? "90px" : "160px"};
          height: ${hei === "s" ? "40px" : "50px"};
          color: ${fontColor};
        `,
      ]}
    >
      {label}
    </button>
  );
};

const basicButton = (theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: ${theme.mainColor};
  font-size: 16px;
`;
const disButton = () => css`
  height: 44px;
  border-radius: 30px;
  border: none;
  background-color: #e9e9e9e9;
  color: #131313;
  font-size: 16px;
`;
