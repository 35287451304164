/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RecBadge = () => {
  return (
    <div css={icon}>
      <div>
        <span className="recIcon">
          <div className="recText"></div>
          REC
        </span>
      </div>
    </div>
  );
};

const icon = () => css`
  position: absolute;
  bottom: 6px;
  left: 0px;
  width: 100%;
  z-index: 2;
  font-size: 0.625rem;
  cursor: auto;
  .recIcon {
    display: flex;
    align-items: center;
    padding: 0.5em 0.4em;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 500;
    color: #ff004e;
    line-height: 1;
    width: 55px;
    margin: 0 6px;
    .recText {
      width: 7px;
      height: 7px;
      vertical-align: baseline;
      display: inline-block;
      margin-right: 5px;
      vertical-align: text-bottom;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0;
      animation: spinner-grow 0.75s linear infinite;
    }
  }
`;
