export const getInsungPart = (examData) => {
  if (examData?.likertYN === "N" && examData?.ipsativeYN === "Y") {
    return "part3";
  }
  if (examData?.likertYN === "Y") {
    if (examData?.ipsativeYN === "Y") {
      return "part1";
    } else {
      return "part2";
    }
  }
};
