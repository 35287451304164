/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useInterval } from "../../hooks/useInterval";
import { Loading } from "../../components/modal/Loading";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { useEffect, useState } from "react";
import { persistor } from "../../_store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export const EndPage = () => {
  const { t } = useTranslation();

  const [count, setCount] = useState(10);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    //리덕스 persist 초기화
    persistor.purge();
  }, []);

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
      }
    },
    isRunning ? 1000 : null
  );

  return (
    <div css={totalWrapper}>
      {isRunning ? (
        <>
          <div className="PendingProgramEnd">{t(`end.programEndText`)}</div>
          <div className="loading-wrapper">{<Loading size={60} />}</div>
        </>
      ) : (
        <ModalTemplate closeBtn={false} isTest={false}>
          <FontAwesomeIcon icon={faExclamationCircle} css={icon} />
          <div className="modal-sub-title">{t(`end.programEndFail`)}</div>
          <div style={{ textAlign: "center" }}>
            {t(`end.programEndFailSubText`)}
            <br />
            {window.navigator.userAgent.includes("Mac")
              ? `( Cmd+Q )`
              : `( Alt+F4 )`}
          </div>
        </ModalTemplate>
      )}
    </div>
  );
};

const icon = css`
  font-size: 36px;
  color: #aa0000;
`;

const totalWrapper = css`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .PendingProgramEnd {
    padding-bottom: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    white-space: pre;
    span {
      line-height: 60px;
      font-size: 24px;
      font-weight: 400;
    }
  }
  .modal-sub-title {
    font-size: 24px;
    padding: 20px;
  }
`;
