import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dataApi } from "../api/data/dataApi";
import { updateApi } from "../api/update/updateApi";
import i18n from "../locales/i18n";
import { testCompletedModal } from "../utils/testCompletedModal";
import { UPDATE_USER_STATE } from "../_reducers/examUserDataAction";
import {
  MAIN_EXAM_LOADING,
  MAIN_EXAM_REQUEST,
} from "../_reducers/mainExamAction";
import { ALERT_MODAL_ON, PENDING_MODAL_ON } from "../_reducers/modalAction";
import { TIME_CHANGE, TIME_OUT, TIME_STOP } from "../_reducers/timeAction";
import { useRecord } from "./useRecord";
import { useTiming } from "./useTiming";
import { useNowExamData } from "./useNowExamData";
import axios from "axios";
import { API_URL } from "../data/api";
import axiosRetry from "axios-retry";
import { baseURL } from "../api/apiClient";

export const useTimerHandler = () => {
  const navigate = useNavigate();

  useTiming();

  const dispatch = useDispatch();
  const { startRecord } = useRecord();

  const location = useLocation();

  const userData = useSelector((state) => state.examUserDataAction);
  const [countdownSeconds, setCountdownSeconds] = useState(
    sessionStorage.getItem("remainingSeconds")
  );
  const { timing, isStop } = useSelector((state) => state.timeAction);
  const examData = useSelector((state) => state.mainExamAction.data);
  const { completer } = useSelector((state) => state.userAction);
  const { roomUseYN, testerIdx } = useSelector(
    (state) => state.userAction.data
  );
  const { timerChange } = useSelector((state) => state.timeAction.time);
  const nowExamData = useNowExamData();

  const timerIdRef = useRef(null);

  //검사 시작 시간 가져오기 , 로그인 접속 시간 가져오기
  const { examSAtTime, currentTime, status } = useSelector(
    (state) => state.examUserDataAction
  );

  const socketData = useSelector((state) => state.socketAction);
  const {
    waitingModal,
    disconnectMobileModal,
    pendingModal,
    disconnectNetworkModal,
  } = useSelector((state) => state.modalAction);

  // retry 관련
  const retryInterval = 3000; // 3초
  const accessToken = sessionStorage.getItem("accessToken");

  const client = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  axiosRetry(client, {
    retries: 5,
    retryCondition: () => true,
    retryDelay: () => retryInterval,
  });

  //녹화 시작 타이밍 지정
  useEffect(() => {
    const startRecordIdx =
      testerIdx % 60 === 0 || testerIdx % 60 === 60 ? 30 : testerIdx % 60;
    // 예제로 들어왔을 경우
    if (nowExamData?.testerStatus?.examSubStatus === 0) {
      if (countdownSeconds === startRecordIdx) {
        startRecord();
      }
    }
  }, [nowExamData?.testerStatus?.examSubStatus, countdownSeconds]);

  // useEffect(() => {
  //   //대기 시간 5분 전 녹화 영상 보내기
  //   if (userData?.status?.examStatus === 0) {
  //     if (countdownSeconds === 300) {
  //       stopRecordHandler();
  //       startRecord();
  //     }
  //   }
  // }, [countdownSeconds]);

  //인성 part1 10분, 6페이지 이하일 경우 600seconds
  //인성 part1 25분, 13페이지 이하일 경우 1500seconds
  //인성 part2 10분, 5페이지 이하일 경우  600seconds
  useEffect(() => {
    const remainingSeconds = parseInt(
      sessionStorage.getItem("remainingSeconds")
    );

    const showSpeedAlertModal = (requiredPage, requiredSeconds) => {
      const totalSeconds = examData?.testerStatus?.totalSeconds;
      if (
        requiredSeconds === totalSeconds - remainingSeconds &&
        requiredPage > examData.testerStatus.savePage
      ) {
        dispatch({
          type: ALERT_MODAL_ON,
          data:
            i18n.language === "KOR"
              ? `현재 ${requiredPage}페이지 이상 풀어야 정상적인 속도입니다. \n 조금 더 빠르게 응답해 주세요.`
              : `To finish the test within the given time, you should be on page ${requiredPage} or beyond now. Please speed up your response.`,
        });
      }
    };

    const islikertY = examData?.exam?.likertYN;
    const isipsativeY = examData?.exam?.ipsativeYN;

    if (islikertY === "Y" && isipsativeY === "Y") {
      showSpeedAlertModal(6, 600);
      showSpeedAlertModal(13, 1500);
    }

    if (islikertY === "Y" && isipsativeY === "N") {
      showSpeedAlertModal(5, 600);
    }

    //적성에 이전버튼이 없을 경우 0초가 됐을 때 풀이 시간 저장
    if (
      islikertY === "N" &&
      isipsativeY === "N" &&
      examData?.testerStatus?.prevUseYN === "N" &&
      remainingSeconds === 0
    ) {
      try {
        updateApi.updateJuksungInterval(examData);
      } catch (err) {
        console.log(err);
      }
    }
  }, [examData?.testerStatus?.savePage, examData?.exam, countdownSeconds]);

  //시간이 종료 되었을 때 예제 문항을 불러오는 함수
  const getPracticeExam = async (examStatusList, nextExamSubStatus) => {
    let remainingSeconds = 0;

    if (
      location.pathname !== "/test/maintest" &&
      location.pathname !== "/test/device"
    ) {
      navigate("/test/maintest", { replace: true });
    } else {
      if (examData?.testerStatus) {
        await handleRemainingTime();
      }
    }

    dispatch({
      type: MAIN_EXAM_LOADING,
      data: true,
    });

    await client
      .post(`${API_URL.EXAM_START}?pageNo=1`, {
        examStatus: examStatusList?.examStatus,
        examIdx: examStatusList?.examIdx,
        examNo: examStatusList?.examNo,
        examSubStatus: nextExamSubStatus,
        examSubIdx: examStatusList?.examSubIdx,
        examSubNo: examStatusList?.examSubNo,
      })
      .then((response) => {
        dispatch({
          type: MAIN_EXAM_LOADING,
          data: false,
        });
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: response.data.data,
        });
        remainingSeconds = response.data.data.example.practiceMinutes * 60;
      })
      .catch((error) => {
        dispatch({
          type: MAIN_EXAM_LOADING,
          data: true,
        });
        navigate("/logout");
      });

    return remainingSeconds;
  };

  //시간이 종료 되었을 때 본 검사 문항을 불러오는 함수 (예제 -> 본검사)
  const getMainExam = async () => {
    let remainingSeconds = 0;
    dispatch({
      type: MAIN_EXAM_LOADING,
      data: true,
    });

    // 예제 요청
    await client
      .post(
        `${API_URL.EXAM_START}?pageNo=${examData?.testerStatus?.savePage ?? 1}`,
        {
          examStatus: examData?.testerStatus.examStatus,
          examIdx: examData?.testerStatus.examIdx,
          examNo: examData?.testerStatus.examNo,
          examSubStatus: 0,
          examSubIdx: examData?.testerStatus.examSubIdx,
          examSubNo: examData?.testerStatus.examSubNo,
        }
      )
      .then((response) => {
        dispatch({
          type: MAIN_EXAM_LOADING,
          data: false,
        });
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: response.data.data,
        });
        remainingSeconds = response.data.data.testerStatus.remainingSeconds;
      })
      .catch((error) => {
        dispatch({
          type: MAIN_EXAM_LOADING,
          data: true,
        });
        navigate("/logout");
      });

    return remainingSeconds;
  };

  useEffect(() => {
    if (completer) {
      let initialTime = parseInt(sessionStorage.getItem("remainingSeconds"));
      setCountdownSeconds(initialTime);
    } else {
      //사전 점검일 경우 0을 넣음
      setCountdownSeconds(0);
    }
  }, []);

  //시험 시작 전 1분마다 현재 시간 데이터 가져오기
  const handleNowBeforeExam = async () => {
    try {
      await dataApi.timeData().then((response) => {
        resetTimer(
          parseInt(
            (new Date(examSAtTime) - new Date(response.data.data.currentTime)) /
              1000
          )
        );
      });
    } catch (error) {
      console.error("에러 발생: " + error);
    }
  };

  //시험 시작 후 30초마다 남은 시간 데이터 보내기
  const handleRemainingTime = async () => {
    try {
      await updateApi.updateTime(examData?.testerStatus);
    } catch (error) {
      console.error("에러 발생: " + error);
    }
  };

  useEffect(() => {
    let saveInterval;

    if (completer) {
      if (timing === "TIMING_NOW_BEFORE_EXAM") {
        saveInterval = setInterval(handleNowBeforeExam, 60000);
      } else if (
        timing !== "TIMING_NOW_PRACTICE_EXAM" &&
        userData.status.examStatus !== 2
      ) {
        saveInterval = setInterval(handleRemainingTime, 30000);
      }
    }

    return () => {
      clearInterval(saveInterval);
    };
  }, [timing, completer, userData.status]);

  const startTimer = () => {
    const startTime = Date.now();

    function updateTimer() {
      const elapsedTime = Date.now() - startTime;
      const remainingSeconds = Math.max(
        countdownSeconds - Math.floor(elapsedTime / 1000),
        0
      );
      if (timerIdRef.current) {
        cancelAnimationFrame(timerIdRef.current);
      }
      setCountdownSeconds(remainingSeconds);
      if (remainingSeconds > 0) {
        timerIdRef.current = requestAnimationFrame(updateTimer);
      }
      sessionStorage.setItem("remainingSeconds", remainingSeconds);
    }
    if (isStop && timerIdRef.current) {
      cancelAnimationFrame(timerIdRef.current);
    }
    timerIdRef.current = requestAnimationFrame(updateTimer);
  };

  const stopTimer = () => {
    cancelAnimationFrame(timerIdRef.current);
    return;
  };

  const resetTimer = (time) => {
    const startTime = Date.now();
    function updateTimer() {
      const elapsedTime = Date.now() - startTime;
      const remainingSeconds = Math.max(
        time - Math.floor(elapsedTime / 1000),
        0
      );

      setCountdownSeconds(remainingSeconds);
      if (timerIdRef.current) {
        cancelAnimationFrame(timerIdRef.current);
      }
      if (remainingSeconds > 0) {
        timerIdRef.current = requestAnimationFrame(updateTimer);
      }
      sessionStorage.setItem("remainingSeconds", remainingSeconds);
    }

    if (timerIdRef.current) {
      cancelAnimationFrame(timerIdRef.current);
    }
    timerIdRef.current = requestAnimationFrame(updateTimer);
  };

  //타이머 멈추고 시작
  useEffect(() => {
    if (isStop) {
      stopTimer();
    } else {
      startTimer();
    }

    if (timerChange) {
      dispatch({ type: TIME_STOP, data: true });
      sessionStorage.setItem("remainingSeconds", timerChange);
      resetTimer(timerChange);

      if (countdownSeconds === timerChange) {
        dispatch({ type: TIME_STOP, data: false });
        dispatch({
          type: TIME_CHANGE,
          data: null,
        });
      }
    }
  }, [timerChange, isStop, countdownSeconds]);

  //0초가 됐을 때 다음 검사정보 및 시간 세팅
  useEffect(() => {
    const getExamInfoData = async (timerCurrentTime) => {
      if (examData.examStatus === 2) return;
      //검사를 불러올 때 환경점검 페이지 이면, (미실시 상태에서 예제 불러오는 경우)
      let initialTime = parseInt(
        (new Date(examSAtTime) - new Date(currentTime)) / 1000
      );
      if (
        initialTime < 0 &&
        location.pathname !== "/test/maintest" &&
        location.pathname !== "/test/timetable"
      ) {
        dispatch({ type: TIME_STOP, data: true });
        return;
      }
      //타이머가 0보다 작을 경우 0으로 세팅
      if (completer && timerCurrentTime <= 0) {
        let time;
        if (roomUseYN === "Y") {
          //검사 대기에서 감독관과 연결이 끊겼을 경우 펜딩
          if (
            location.pathname === "/test/timetable" &&
            status.examStatus === 0 &&
            userData.attendanceYN === "Y" &&
            !socketData?.viewerSocket?.viewerSocketId
          ) {
            dispatch({ type: PENDING_MODAL_ON });
            dispatch({ type: TIME_STOP, data: true });
            return;
          }
          //감독관이 출석을 안했을 경우,모바일이 연결되지 않았을 경우 타이머 멈춤
          if (
            userData.agreeYN === "N" ||
            userData.attendanceYN === "N" ||
            pendingModal ||
            disconnectMobileModal ||
            disconnectNetworkModal
          ) {
            dispatch({ type: TIME_STOP, data: true });
            return;
          }
          //감독관이 출석을 완료 하면 타이머 시작
          else {
            dispatch({
              type: UPDATE_USER_STATE,
              data: 1,
            });
            dispatch({ type: TIME_STOP, data: false });
          }
        }
        //감독관이 없는 경우
        else {
          //검사 시작 버튼 누르기 전
          if (userData.attendanceYN === "N") {
            dispatch({ type: TIME_STOP, data: true });
            return;
          }
        }

        //인성검사에서 본문항에서 0초가 됐을 때 못푼 문제를 저장하기 위한 부분
        if (
          examData?.exam?.likertYN === "Y" ||
          examData?.exam?.ipsativeYN === "Y"
        ) {
          dispatch({ type: TIME_OUT, data: "end" });
        }

        if (timing === "TIMING_NOW_BEFORE_EXAM") {
          dispatch({
            type: UPDATE_USER_STATE,
            data: 1,
          });
          //현재 타이밍이 TIMING_NOW_BEFORE_EXAM(시험 시작 전 타임테이블 까지) 일 경우 0초가 됐을 때 예제문항 불어오기
          time = await getPracticeExam(userData?.status, null);
        } else if (timing === "TIMING_NOW_MAIN_EXAM") {
          //현재 타이밍이 TIMING_NOW_MAIN_EXAM,TIMING_NOW_MAX_SUBEXAM (본검사 문항 , 마지막 ) 일 경우  0초가 됐을 때 예제문항 불어오기
          time = await getPracticeExam(examData?.testerStatus, 2);
        } else if (timing === "TIMING_NOW_PRACTICE_EXAM") {
          //현재 타이밍이 TIMING_NOW_PRACTICE_EXAM(예제 문항) 일 경우 0초가 됐을 때 본 문항 불어오기
          time = await getMainExam();
        } else if (timing === "TIMING_NOW_LAST_EXAM") {
          //현재 타이밍이 TIMING_NOW_LAST_EXAM 일 경우  0초가 됐을 때 examSubStatus: 2 로 응시 종료 후 status 2로 업데이트 하기
          time = 0;
          testCompletedModal(dispatch, waitingModal);
          return;
        }
        sessionStorage.setItem("startRemainingSeconds", time);
        sessionStorage.setItem("remainingSeconds", time);
        resetTimer(time);
      }
    };
    getExamInfoData(countdownSeconds);
  }, [
    countdownSeconds,
    userData?.attendanceYN,
    waitingModal,
    disconnectMobileModal,
    pendingModal,
    socketData?.viewerSocket,
  ]);

  return {
    countdownSeconds,
  };
};
