import { useSelector } from "react-redux";

export const useNowExamData = () => {
  const { completer } = useSelector((state) => state.userAction);

  const preTestData = useSelector((state) => state.pretestAction.data);
  const mainTestData = useSelector((state) => state.mainExamAction.data);

  const getNowExamData = () => {
    if (!completer) {
      return preTestData;
    } else return mainTestData;
  };
  const nowExamData = getNowExamData();
  return nowExamData;
};
