/* @jsxImportSource @emotion/react */
import React, { useRef, useCallback, useEffect, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useInput } from "../../hooks/useInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  getOpacityColor,
  getTextColorByBackgroundColor,
} from "../../utils/color";
import { SHOW_CHAT } from "../../_reducers/showUtilsAction";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getDateFormatAMPM } from "../../utils/getDateFormat";
import { chatApi } from "../../api/chat/chatApi";
import { CloseIcon } from "./icon/CloseIcon";
import { chatMessage } from "../../adapters/chatMessage";
import { SOCKET_EVENT } from "../../adapters/socket.enum";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";
import { useLocation } from "react-router-dom";

export const Chat = React.memo(({ viewerRef }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();
  const scrollRef = useRef();
  const theme = useTheme();
  const { socket } = useRoomConnection();
  const {
    sendToChat,
    consumerChat,
    consumerChatRead,
    consumerUserAvatar,
    consumerUserVoiceChat,
  } = chatMessage();
  const { completer } = useSelector((state) => state.userAction);
  const { testerName } = useSelector((state) => state.userAction.data);
  const { showChat } = useSelector((state) => state.showUtilsAction);
  const { chatWarningMessage } = useSelector((state) => state.chatAction);
  const [chatText, onChangeChatText, setChatText] = useInput("");

  const fontColor = getTextColorByBackgroundColor(theme.subColor);
  const opacityColor = getOpacityColor(theme.mainColor);

  //룸 정보 가져오기
  const { roomIdx, roomUseYN } = useSelector((state) => state.userAction.data);
  const socketData = useSelector((state) => state.socketAction);
  const [chatMessageArray, setChatMessageArray] = useState([]); //채팅 매세지 배열
  const [coverManagerAvatar, setCoverManagerAvatar] = useState(false); //감독관 화면 숨김 여부
  const [readRender, setReadRender] = useState(0); //감독관이 채팅을 읽었을 경우 렌더링

  let chatOnBlurTime;

  //응시자 경고 메세지를 받을 경우 리덕스에 저장된 값을 채팅 리스트에 추가
  useEffect(() => {
    if (chatWarningMessage !== null) {
      if (completer) {
        sendToChat(chatWarningMessage?.message, "warning");
      }
      setChatMessageArray([...chatMessageArray, chatWarningMessage]);
    }
    return () => {
      if (Object.keys(socket).length === 0) return;
      socket.off(SOCKET_EVENT.CHAT);
    };
  }, [chatWarningMessage?.logAt]);

  //튕겼다가 재 접속 시 이전 채팅 로그 가져오기
  useEffect(() => {
    let isCancelled = false;
    if (roomIdx) {
      const fetchInfoData = async () => {
        const result = await chatApi.getChatMessage(roomIdx);
        if (!isCancelled) {
          setChatMessageArray(result.data.data);
        }
      };
      fetchInfoData();
    }
    return () => {
      isCancelled = true;
    };
  }, [dispatch, roomIdx]);

  const onFocusLeaveEvent = () => {
    clearTimeout(chatOnBlurTime);
    chatOnBlurTime = setTimeout(() => {
      dispatch({ type: SHOW_CHAT, data: false });
    }, 60000);
  };

  //감독관이 채팅을 읽었을 때마다 1을 0으로 바꿔줘야함
  useEffect(() => {
    //감독관이 메세지를 읽었을 경우 1 없애기
    let tmp = chatMessageArray;
    const tmpArray = tmp.map((chat, index) => {
      return chat.readYN === "N" ? { ...chat, readYN: "Y" } : chat;
    });
    setChatMessageArray(tmpArray);
  }, [readRender]);

  //채팅 스크롤 맨 아래로 위치
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [showChat, chatMessageArray]);

  useEffect(() => {
    //감독관 화면 임시 아바타로 숨김 여부
    consumerUserAvatar((message) => {
      if (message === "OFF") {
        setCoverManagerAvatar(false);
      } else {
        setCoverManagerAvatar(true);
      }
    });

    //음성 대화 연결 메세지 전송
    consumerUserVoiceChat((message) => {
      if (message === "ON") {
        setChatMessageArray([
          ...chatMessageArray,
          {
            fromId: message.peerId,
            toId: socketData?.userSocket?.myPeerId,
            fromName: "감독관",
            message: t(`chat.connectVoiceChat`),
            logAt: new Date(),
          },
        ]);
        viewerRef.current.muted = false;
        dispatch({ type: SHOW_CHAT, data: true });
      } else {
        setChatMessageArray([
          ...chatMessageArray,
          {
            fromId: message.peerId,
            toId: socketData?.userSocket?.myPeerId,
            fromName: "감독관",
            message: t(`chat.disconnectVoiceChat`),
            logAt: new Date(),
          },
        ]);
        viewerRef.current.muted = true;
        dispatch({ type: SHOW_CHAT, data: true });
      }
    });

    //감독관 채팅 읽음
    consumerChatRead((mode, message) => {
      setReadRender((prev) => prev + 1);
    });
    //감독관이 메세지 전송
    consumerChat((mode, message) => {
      if (mode === "to") {
        //1:1 채팅
        setChatMessageArray([
          ...chatMessageArray,
          {
            fromId: message.peerId,
            toId: socketData?.userSocket?.myPeerId,
            fromName: "감독관",
            message: message.message,
            logAt: new Date(),
          },
        ]);
      } else {
        //전체공지
        setChatMessageArray([
          ...chatMessageArray,
          {
            fromId: message.peerId,
            toId: socketData?.userSocket?.myPeerId,
            fromName: "공지사항",
            message: message.message,
            logAt: new Date(),
          },
        ]);
      }
      dispatch({ type: SHOW_CHAT, data: true });
    });

    return () => {
      if (Object.keys(socket).length === 0) return;
      socket.off(SOCKET_EVENT.USER_AVATAR);
      socket.off(SOCKET_EVENT.USER_CHAT_READ);
      socket.off(SOCKET_EVENT.USER_CHAT);
      socket.off(SOCKET_EVENT.USER_SOUND);
    };
  }, [chatMessageArray]);

  //응시자 -> 감독관 채팅 보냄
  const onClickSend = useCallback(async () => {
    if (chatText.trim() === "") {
      return;
    }

    if (socketData?.viewerSocket?.viewerSocketId) {
      if (completer) {
        sendToChat(chatText, "chat");
      }
    } else {
      const data = {
        fromId: socketData?.userSocket?.myPeerId,
        toId: `${socketData?.userSocket?.myRoomId}-MANAGER`,
        fromName: testerName,
        message: chatText,
        readYN: "Y",
      };
      if (completer && roomUseYN === "Y") {
        chatApi.sendChatMessage(roomIdx, data);
      }
    }

    const newChatMessage = {
      fromId: socketData?.userSocket?.myPeerId,
      toId: socketData?.viewerSocket?.viewerPeerId,
      fromName: testerName,
      message: chatText,
      logAt: new Date(),
      readYN: "N",
    };

    setChatMessageArray((prevChatMessageArray) => [
      ...prevChatMessageArray,
      newChatMessage,
    ]);
    setChatText("");
  }, [chatText, socketData?.viewerSocket?.viewerSocketId, completer]);

  const onEnterSend = useCallback(
    async (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onClickSend();
      }
    },
    [onClickSend]
  );

  return (
    <div css={showChat ? showWrapper : hideChatWrapper}>
      <div
        css={chatWrapper}
        onClick={() => dispatch({ type: SHOW_CHAT, data: !showChat })}
      >
        {showChat ? (
          <FontAwesomeIcon icon={faChevronRight} />
        ) : (
          <FontAwesomeIcon icon={faCommentDots} />
        )}
      </div>
      <div css={chatHeader}>
        {completer ? (
          <div className="manager-img-wrap">
            {coverManagerAvatar && (
              <img alt="" src={`/images/manager.png`} style={{ zIndex: 1 }} />
            )}
            {!socketData?.viewerSocket?.viewerSocketId && (
              <img
                alt=""
                src={`/images/no_manager.png`}
                style={{
                  zIndex: 1,
                }}
              />
            )}
            <video
              className="viewer-cam"
              ref={viewerRef}
              autoPlay
              muted={true}
            />
          </div>
        ) : (
          <img
            alt=""
            src={`/images/manager.png`}
            className="viewer-cam-image"
          />
        )}
        <CloseIcon
          fontSize={"20px"}
          onClick={() => dispatch({ type: SHOW_CHAT, data: !showChat })}
        />

        {roomUseYN === "N" || !completer ? (
          <div>{t(`chat.pretestViewerConnectionNotice`)}</div>
        ) : (
          !socketData?.viewerSocket?.viewerSocketId && (
            <div>{t(`chat.disconnectViewerNotice`)}</div>
          )
        )}
      </div>

      <div css={chatBody({ theme, fontColor, opacityColor })} ref={scrollRef}>
        {chatMessageArray?.map((v, index) => {
          if (
            v.message.includes("Mac OS 사용 응시자") ||
            v.message.includes("터치기능 PC 사용 응시자") ||
            v.message.includes("[알림]\n 중복 로그인을 시도한 내용이") ||
            v.message.includes("[AI감독]") ||
            v.message.includes("응시자의 네트워크 연결 상태가")
          ) {
            return;
          } else if (v.fromName === "감독관") {
            return (
              <div className="chat-to-wrap" key={index}>
                <div className="chat-position"> {t(`chat.viewer`)}</div>
                <div className="chat-to">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${v.message}`,
                    }}
                    className="chat-to-box"
                  ></div>
                  <div className="chant-to-time">
                    {getDateFormatAMPM(new Date(v.logAt), i18n.language)}
                  </div>
                </div>
              </div>
            );
          } else if (v.fromName === "공지사항") {
            return (
              <div key={index}>
                <div className="chat-notice-form">
                  <div className="chat-notice-header">
                    <div className="chat-notice-title">
                      {t(`chat.noticeAll`)}
                    </div>
                    <div className="chat-notice-time">
                      {getDateFormatAMPM(new Date(v.logAt), i18n.language)}
                    </div>
                  </div>
                  <div className="chat-notice-message">{v.message}</div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index}>
                <div className="chat-from">
                  <div className="chat-readYN">
                    {v.readYN === "N" ? t(`chat.unread`) : null}
                  </div>
                  <div className="chat-from-time">
                    {getDateFormatAMPM(new Date(v.logAt), i18n.language)}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${v.message}`,
                    }}
                    className="chat-from-box"
                  ></div>
                </div>
              </div>
            );
          }
        })}
      </div>

      <div css={chatFooter}>
        <div className="chat-textarea">
          <textarea
            rows="5"
            value={chatText || ""}
            onChange={onChangeChatText}
            onKeyPress={onEnterSend}
            style={{ color: "#000" }}
            onBlur={onFocusLeaveEvent}
            placeholder={
              roomUseYN === "N" || !completer
                ? t(`chat.pretestPlaceholder`)
                : undefined
            }
          />
        </div>
        <div className="chat-button-wrap">
          <button className="chat-send-button" onClick={onClickSend}>
            {t(`chat.sendButton`)}
          </button>
        </div>
      </div>
    </div>
  );
});

const showWrapper = css`
  position: fixed;
  display: grid;
  height: 600px;
  width: 350px;
  top: 110px;
  right: 0px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 14%);
  border-radius: 6px;
  background-color: #f0f0f1;
  transition: 0.3s ease-out;
`;

const hideChatWrapper = css`
  position: fixed;
  display: grid;
  height: 600px;
  width: 350px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 14%);
  border-radius: 6px;
  background-color: #f0f0f1;
  top: 110px;
  right: -350px;
  transition: 0.3s ease-in;
`;

const chatWrapper = (theme) => css`
  position: absolute;
  top: 20px;
  right: 350px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.mainColor};
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  font-size: 24px;
`;

const chatHeader = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #676666;

  .viewer-cam-image {
    display: flex;
    height: 73px;
    width: 110px;
    background-color: #676666;
  }

  .cam-outline {
    background-color: #777;
    color: #fefefe;
    font-weight: 300;
  }
  .manager-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .viewer-cam {
      background-color: #676666;
      height: 83px;
      width: 110px;
      transform: rotateY(180deg);
    }
    img {
      position: absolute;
      height: 83px;
      background-color: #676666;
    }
  }
`;

const chatBody = ({ theme, fontColor, opacityColor }) => css`
  display: flex;
  flex-direction: column;
  height: 380px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  .chat-to-wrap {
    margin: 4px 0;
  }
  .chat-position {
    color: #131313;
    font-size: 12px;
  }
  .chat-to {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    word-break: keep-all;
    .chat-to-box {
      max-width: 80%;
      background-color: #fff;
      border-radius: 5px;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #000;
      box-shadow: #cccccc 0 0 8px 0px;
      white-space: pre-line;
    }
    .chant-to-time {
      display: flex;
      max-width: 100%;
      flex-grow: 1;
      flex-basis: 0;
      align-items: flex-end;
      justify-content: flex-start;
      font-size: 10px;
      color: #000;
      margin-left: 4px;
    }
  }
  .chat-from {
    display: flex;
    justify-content: flex-end;
    flex-wrap: no-wrap;
    flex-direction: row;
    height: 100%;
    padding: 4px 0;
    word-break: keep-all;
    .chat-readYN {
      display: flex;
      justify-content: flex-start;
      color: #8a8a8a;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 10px;
      padding-right: 8px;
    }
    .chat-from-time {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      max-width: 100%;
      font-size: 10px;
      color: #000;
    }

    .chat-from-box {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      max-width: 70%;
      background-color: ${theme.subColor};
      border-radius: 5px;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      margin-left: 4px;
      color: ${fontColor};
      box-shadow: #cccccc 0 0 8px 0px;
    }
  }

  .chat-notice-form {
    display: flex;
    flex-direction: column;
    background-color: ${opacityColor};
    position: relative;
    box-shadow: #cccccc 0 0 8px 0px;
    border-radius: 5px;
    margin: 12px 0;
    .chat-notice-header {
      display: flex;
      flex-direction: row;
      padding: 5px 0 5px 0;
      justify-content: center;
      align-items: center;

      .chat-notice-title {
        display: flex;
        color: ${theme.mainColor};
        font-weight: 600;
      }
      .chat-notice-time {
        position: absolute;
        right: 0;
        padding-right: 8px;
        color: #202121;
        font-size: 10px;
      }
    }
    .chat-notice-message {
      color: #202121;
      padding: 0 15px 15px 15px;
      white-space: pre-line;
    }
  }
`;

const chatFooter = (theme) => css`
  width: 350px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: #cccccc 0 0 8px 0px;
  padding: 14px;
  border-radius: 0 0 6px 6px;
  .chat-textarea {
    height: 100%;
    textarea {
      font-size: 14px;
      height: 100%;
      background-color: #fff;
      width: 260px;
      border: none;
      resize: none;
      padding: 0;
      padding-right: 10px;
    }
    &::placeholder {
      white-space: pre-line;
    }
  }
  .chat-button-wrap {
    display: flex;
    height: 100%;
    width: 20%;
    align-items: flex-end;
    .chat-send-button {
      width: 100%;
      height: 100%;
      background-color: #4d4d4d;
      color: #fff;
      border-radius: 6px;
      font-size: 14px;
    }
  }
`;
