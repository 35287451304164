/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { getStorageValue } from "../../../../utils/getStorageValue";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

export const PretestColumPart1 = ({
  questionList,
  noCheckNum,
  noCheckSet,
  clickNumHandler,
  clickCheckHandler,
  index,
}) => {
  const { t } = useTranslation();

  const examData = useSelector((state) => state.pretestAction.data);
  const questionListSetNo = examData.questionInfoData.questionList.length;
  const lastSetNo = examData?.questionInfoData?.lastNo / questionListSetNo; //한 세트의 문항 수
  const nowPageSetNum = examData?.questionInfoData?.questionList?.length;
  const arrayQuestionSetNo = index + 1;

  const language = i18n.language;

  return (
    <div css={totalWrapper({ nowPageSetNum })}>
      <div css={tableLeft}>
        <div className="table-num">{arrayQuestionSetNo}</div>
      </div>
      <div css={tableRightWrapper}>
        {questionList?.questions?.map((question, i) => (
          <div
            css={[
              tableRight({ nowPageSetNum }),
              css`
                &:hover {
                  background-color: #e9e9e9;
                }
              `,
            ]}
            key={i}
          >
            <div className="table-question-num">{question.questionSubText}</div>
            <div className="table-question">{question.questionText}</div>
            <Tooltip
              color={"red"}
              placement="left"
              title={t(`exam.insung.tooltip.questionNum`)}
              open={
                noCheckNum.QsetNum === index + 1 && noCheckNum.Qnum === i + 1
              }
              trigger={"none"}
              key={i}
              css={css`
                background-color: ${noCheckNum.QsetNum === index + 1 &&
                noCheckNum.Qnum === i + 1 &&
                "#FFE5ED"};
              `}
            >
              <div className="table-question-standard" css={pickNum}>
                {[...Array(examData?.maxScale)].map((value, i) => {
                  return (
                    <span
                      key={i}
                      className={
                        i + 1 ===
                        getStorageValue(
                          `Insung : [${
                            examData?.examSubNo
                          }-${arrayQuestionSetNo}-${
                            ((question.questionNo - 1) % lastSetNo) + 1
                          }]`
                        )?.answer
                          ? "picked"
                          : "pick"
                      }
                      onClick={() => {
                        clickNumHandler(
                          arrayQuestionSetNo,
                          ((question.questionNo - 1) % lastSetNo) + 1,
                          i + 1
                        );
                      }}
                    >
                      {i + 1}
                    </span>
                  );
                })}
              </div>
            </Tooltip>
            <Tooltip
              color={"red"}
              placement="left"
              title={t(`exam.insung.tooltip.questionSet`)}
              open={arrayQuestionSetNo === noCheckSet && i === 0}
              trigger={"none"}
              css={css`
                background-color: ${arrayQuestionSetNo === noCheckSet &&
                "#FFE5ED"};
              `}
            >
              {examData?.ipsativeYN === "Y" ? (
                <div className="table-question-far-near">
                  <div
                    className="far"
                    css={
                      getStorageValue(
                        `Insung : [${examData?.examSubNo}-check-${arrayQuestionSetNo}]`
                      )?.checkDistantId ===
                      i + 1
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      clickCheckHandler("distant", arrayQuestionSetNo, i + 1);
                    }}
                  >
                    {language === "KOR" ? "멀" : "L"}
                  </div>

                  <div
                    className="near"
                    css={
                      getStorageValue(
                        `Insung : [${examData?.examSubNo}-check-${arrayQuestionSetNo}]`
                      )?.checkNearId ===
                      i + 1
                        ? checkedBtn
                        : checkBtn
                    }
                    onClick={() => {
                      clickCheckHandler("near", arrayQuestionSetNo, i + 1);
                    }}
                  >
                    {language === "KOR" ? "가" : "M"}
                  </div>
                </div>
              ) : null}
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

const totalWrapper = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: ${nowPageSetNum === 1 ? "16px" : "14px"};
  border-bottom: 2px solid #5b5b5b;
`;

const tableLeft = css`
  display: flex;
  flex-direction: row;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
`;
const tableRightWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const tableRight = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  height: ${nowPageSetNum === 1 ? "80px" : "50px"};

  &:not(:last-child) {
    border-bottom: 1px solid #5b5b5b;
  }
  .table-question-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
  }
  .table-question {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 200px;
  }
  .table-question-standard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 380px;
    border-left: 1px solid #3b3b3b;
  }
  .table-question-far-near {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100px;
    border-left: 1px solid #3b3b3b;
    .far {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
    .near {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        width: 100%;
        position: absolute;
        text-align: center;
      }
      &:active {
        box-shadow: 0 0 0 3px #fefefe inset;
      }
    }
  }
`;

const checkBtn = () => css`
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 50%;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: #d2d3d4;
  }
`;

const checkedBtn = css`
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  background-color: #3b3b3b;
  color: #fefefe;
`;
const pickNum = css`
  width: 20%;
  text-align: center;
  height: 100%;
  .pick {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    transition: all 0.1s;
    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #d2d3d4;
      color: #3b3b3b;
      cursor: pointer;
    }
  }
  .picked {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    background-color: #3b3b3b;
    color: #fff;

    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #3b3b3b;
      color: #fff;
      cursor: pointer;
    }
  }
`;
