// userSaga.js

import { takeEvery, put, fork } from "redux-saga/effects";
import { all } from "redux-saga/effects";
import {
  ERROR_MODAL_OFF,
  ERROR_MODAL_ON,
  PENDING_MODAL_ON,
} from "../_reducers/modalAction";
import { TIME_STOP } from "../_reducers/timeAction";
import { delay } from "redux-saga/effects";

function* errorModal() {
  yield put({ type: TIME_STOP, data: true });
  yield delay(1000);
  yield put({ type: PENDING_MODAL_ON });
  yield put({ type: ERROR_MODAL_OFF });
}

function* watchErrorModal() {
  yield takeEvery(ERROR_MODAL_ON, errorModal);
}

export default function* errorModalDataSaga() {
  yield all([fork(watchErrorModal)]);
}
