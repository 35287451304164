/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { ModalTemplate } from "../../base/ModalTemplate";
import { SendOutlined } from "@ant-design/icons";
import { useInput } from "../../../hooks/useInput";
import { FormLabelGroup } from "../../system/form/FormLabelGroup";
import { dataApi } from "../../../api/data/dataApi";
import { RecButton } from "../../system/button/RecButton";
import { CheckButton } from "../../system/button/CheckButton";
import { ActiveInput } from "../../system/form/ActiveInput";
import { ActiveSelector } from "../../system/form/ActiveSelector";
import { validationPhoneCheck } from "../../../utils/validate";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

export const QnAModal = ({ setContactModal, bgColor }) => {
  const { t } = useTranslation();

  const emailAddressOptions = [
    { label: "naver.com", value: "naver.com" },
    { label: "gmail.com", value: "gmail.com" },
    { label: "daum.net", value: "daum.net" },
    { label: "kakao.com", value: "kakao.com" },
    { label: t(`qna.emailInputAddress`), value: "" },
  ];

  const category = [
    { text: t(`qna.category.loginError`), value: "LOGIN" },
    { text: t(`qna.category.deviceError`), value: "AROUND" },
    { text: t(`qna.category.idcardError`), value: "ID" },
    { text: t(`qna.category.ETCError`), value: "ETC" },
  ];

  const [checkCategory, setCheckCategory] = useState(0);
  const { companyCode } = useSelector((state) => state.userAction);

  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userName, changeUserName, setUserName] = useInput("");
  const [userEmailId, changeUserEmailId, setUserEmailId] = useInput("");
  const [userEmailAddress, changeUserEmailAddress, setUserEmailAddress] =
    useInput("");
  const [userCell, changeUserCell, setUserCell] = useInput("");
  const [contentText, changeContentText] = useInput("");
  const [validationMessage, setValidationMessage] = useState("");
  const { testerIdx, testerName, testerCell, testerEmail } = useSelector(
    (state) => state.userAction.data
  );

  useEffect(() => {
    if (testerName) {
      setUserName(testerName);
      setUserCell(testerCell);

      //이메일을 골뱅이 기준으로 나눔
      if (testerEmail) {
        const email = testerEmail?.split("@");
        const emailId = email[0];
        const emailAddress = email[1];

        setUserEmailId(emailId);
        setUserEmailAddress(emailAddress);
      }
    }
  }, [
    setUserCell,
    setUserEmailAddress,
    setUserEmailId,
    setUserName,
    testerCell,
    testerEmail,
    testerName,
  ]);

  const data = useMemo(
    () => ({
      testerIdx: testerIdx,
      companyCode: companyCode,
      category: category[checkCategory].value,
      testerName: userName,
      testerEmail: `${userEmailId}@${userEmailAddress}`,
      testerCell: userCell,
      language: i18n.language,
      text: contentText,
    }),
    [
      checkCategory,
      companyCode,
      contentText,
      testerIdx,
      userCell,
      userEmailAddress,
      userEmailId,
      userName,
      i18n,
    ]
  );

  const sendQnA = useCallback(() => {
    if (userName === "") {
      setValidationMessage(t(`qna.validation.userName`));
    } else if (contentText === "") {
      setValidationMessage(t(`qna.validation.contentText`));
    } else if ((userEmailId === "") | (userEmailAddress === "")) {
      setValidationMessage(t(`qna.validation.userEmailId`));
    } else if (userCell === "") {
      setValidationMessage(t(`qna.validation.userCell`));
    } else {
      setLoading(true);
      testerIdx
        ? dataApi
            .testerQna(data)
            .then((response) => {
              setSend(true);
            })
            .catch((error) => {
              setValidationMessage(error.response.data.message);
              setLoading(false);
            })
        : dataApi
            .qna(data)
            .then((response) => {
              setSend(true);
              setLoading(false);
            })
            .catch((error) => {
              setValidationMessage(error.response.data.message);
              setLoading(false);
            });
    }
  }, [
    contentText,
    data,
    testerIdx,
    userCell,
    userEmailAddress,
    userEmailId,
    userName,
  ]);

  if (send === false) {
    return (
      <ModalTemplate
        setModal={setContactModal}
        closeBtn={true}
        isTest={false}
        title={t(`qna.modal.modalTitle`)}
      >
        <div css={boxWrapper}>
          <FormLabelGroup
            title={t(`qna.modal.userName`)}
            placeholder={t(`qna.modal.userNamePlaceholder`)}
            onChange={changeUserName}
            value={userName}
          />
          <FormLabelGroup title={t(`qna.modal.category`)}>
            {category.map((data, i) => (
              <div css={checkLabel} key={i}>
                <span>{data.text}</span>
                <CheckButton
                  onClick={() => setCheckCategory(i)}
                  checked={i === checkCategory}
                  value={"EMAIL"}
                  size="s"
                />
              </div>
            ))}
          </FormLabelGroup>
          <FormLabelGroup
            title={t(`qna.modal.content`)}
            placeholder={t(`qna.modal.contentPlaceholder`)}
            textarea={true}
            onChange={changeContentText}
            value={contentText}
          />
          <FormLabelGroup title={t(`qna.modal.email`)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ActiveInput
                  value={userEmailId}
                  onChange={changeUserEmailId}
                  placeholder={"example"}
                />
                <span
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  @
                </span>

                <ActiveInput
                  value={userEmailAddress}
                  onChange={changeUserEmailAddress}
                  placeholder={"example.com"}
                />
                <ActiveSelector
                  options={emailAddressOptions}
                  changeValue={setUserEmailAddress}
                />
              </div>
            </div>
          </FormLabelGroup>
          <FormLabelGroup
            title={t(`qna.modal.userCell`)}
            value={validationPhoneCheck(userCell)}
            onChange={changeUserCell}
            placeholder={t(`qna.modal.userCellPlaceholder`)}
            maxLength={13}
          ></FormLabelGroup>
        </div>

        <div css={buttonWrapper}>
          <div css={validationMessageDiv}>
            {validationMessage ? validationMessage : null}
          </div>
          {loading ? (
            <RecButton label={t(`qna.modal.Submitting`)} />
          ) : (
            <RecButton onClick={sendQnA} label={t(`qna.modal.Submit`)} />
          )}
        </div>
      </ModalTemplate>
    );
  } else {
    return (
      <ModalTemplate
        setModal={setContactModal}
        bgColor={bgColor}
        closeBtn={true}
        isTest={false}
      >
        <div css={sendWrapper}>
          <SendOutlined />
          <div className="sub-text">{t(`qna.modal.submitComplete`)}</div>
        </div>
      </ModalTemplate>
    );
  }
};

const boxWrapper = css`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  border-bottom: 1px #707070 solid;
`;
const sendWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  text-align: center;
  white-space: pre;
  svg {
    margin: 40px;
    font-size: 30px;
  }
  .sub-text {
    margin-bottom: 30px;
    white-space: pre;
  }
`;

const checkLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-right: 10px;
  }
`;

const validationMessageDiv = css`
  display: flex;
  justify-content: center;
  height: 20px;
  font-size: 12px;
  color: #ff004e;
`;

const buttonWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
