/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTimerHandler } from "../../../hooks/useTimerHandler";

import i18n from "../../../locales/i18n";

export const MainTimer = React.memo(() => {
  const language = i18n.language;
  const { t } = useTranslation();

  const { countdownSeconds } = useTimerHandler();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div css={timerWrapper({ language })}>
      <div className="timer-text">{t(`timer.remainingSeconds`)}</div>
      <span className="timer">{formatTime(countdownSeconds)}</span>
    </div>
  );
});

export default MainTimer;

const timerWrapper = ({ language }) => css`
  .timer-text {
    width: ${language === "KOR" ? "" : "200px"};
  }
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: 24px;
  .timer {
    padding-left: 10px;
    font-size: 40px;
  }
`;
