/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ID_CARD_EVENT } from "../../data/idcardData";
import { CheckToggleButton } from "../system/button/CheckToggleButton";
import { useTranslation } from "react-i18next";

export const CheckMaskingGuide = ({ select, setSelected }) => {
  const { t } = useTranslation();
  const { roomUseYN } = useSelector((state) => state.userAction.data);

  const idCardOptions = [
    {
      label: t(`idCard.guide.registrationCard`),
      type: ID_CARD_EVENT.registrationCard.type,
    },
    {
      label: t(`idCard.guide.driverLicense`),
      type: ID_CARD_EVENT.driverLicense.type,
    },
    { label: t(`idCard.guide.passPort`), type: ID_CARD_EVENT.passPort.type },
    {
      label: t(`idCard.guide.foreignRegistrationCard`),
      type: ID_CARD_EVENT.foreignRegistrationCard.type,
    },
  ];

  const { completer } = useSelector((state) => state.userAction);
  const { idCardType } = useSelector((state) => state.examUserDataAction);

  useEffect(() => {
    if (!completer || roomUseYN === "N") {
      setSelected(ID_CARD_EVENT["registrationCard"]);
    } else {
      setSelected(ID_CARD_EVENT[idCardType]);
    }
  }, []);

  const handleCardSelect = (option) => {
    setSelected(option);
  };

  return (
    <div css={guideWrapper}>
      {!completer && (
        <div className="notice-text">{t(`idCard.guide.selectIdCard`)}</div>
      )}

      <div css={checkToggleButtonContainer}>
        {idCardOptions.map((option) => (
          <CheckToggleButton
            key={option.label}
            label={option.label}
            checked={select?.label === option?.label}
            onClick={() => {
              if (!completer || roomUseYN === "N") {
                handleCardSelect(option);
              }
            }}
          />
        ))}
      </div>
      <div css={guidImgWrap({ completer })}>
        <img alt="" src={`/images/id_${select?.type}.jpg`} />
      </div>
    </div>
  );
};

const guideWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  text-align: center;
  .notice-text {
    padding: 0 0 10px;
    font-size: 20px;
    text-align: left;
  }
`;

const checkToggleButtonContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  gap: 5px;
  font-size: 18px;
`;

const guidImgWrap = ({ completer }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${!completer ? "200px" : "250px"};
  img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #131313;
  }
`;
