/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNowExamData } from "../hooks/useNowExamData";
import {
  KICK_MODAL_ON,
  PENDING_MODAL_OFF,
  PENDING_MODAL_ON,
  WAITING_MODAL,
} from "../_reducers/modalAction";
import { TIME_STOP } from "../_reducers/timeAction";
import { SOCKET_EVENT } from "./socket.enum";
import { useRoomConnection } from "./useRoomConnection";
import { useRecord } from "../hooks/useRecord";

export const receiveStatus = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { stopRecordHandler } = useRecord();

  const nowExamData = useNowExamData();
  const { socket } = useRoomConnection();
  const { pendingModal, disconnectMobileModal } = useSelector(
    (state) => state.modalAction
  );

  //퇴장, 강제 퇴장
  useEffect(() => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_WAITING, (message) => {
      if (message === "Y") {
        //퇴장 대기 상태 waiting
        dispatch({ type: WAITING_MODAL, data: "waiting" });
      } else {
        dispatch({ type: WAITING_MODAL, data: false });
      }
    });

    socket?.on(SOCKET_EVENT.USER_KICK, (message) => {
      dispatch({
        type: KICK_MODAL_ON,
        msg: message,
      });
      dispatch({ type: TIME_STOP, data: true });
      stopRecordHandler();
    });

    return () => {
      socket.off(SOCKET_EVENT.USER_KICK);
      socket.off(SOCKET_EVENT.USER_WAITING);
    };
  }, []);

  //감독관 일시정지 재생 펜딩
  useEffect(() => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_EXAM, (message) => {
      if (disconnectMobileModal) {
        return;
      }
      if (message === "PAUSE") {
        dispatch({ type: PENDING_MODAL_ON });
        //본 검사 시작한 경우 타이머 멈춤
        if (
          nowExamData?.testerStatus?.examStatus === 1 ||
          location.pathname === "/test/maintest"
        ) {
          dispatch({ type: TIME_STOP, data: true });
        }
      } else if (message === "PLAY") {
        dispatch({ type: PENDING_MODAL_OFF });
        if (location.pathname === "/test/device/mobile") {
          dispatch({ type: TIME_STOP, data: true });
        } else {
          dispatch({ type: TIME_STOP, data: false });
        }
      }
    });

    return () => {
      socket.off(SOCKET_EVENT.USER_EXAM);
    };
  }, [
    nowExamData?.testerStatus?.examStatus,
    location.pathname,
    pendingModal,
    disconnectMobileModal,
  ]);
};
