/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ActiveInput = ({ value, onChange, placeholder, maxLength }) => {
  return (
    <div css={totalInputWrap}>
      <input
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </div>
  );
};

const totalInputWrap = ({ size }) => css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 20px 0px;
  color: #131313;

  input {
    width: 100%;
    height: 100%;
    color: #131313;
    margin-left: 2px;
    margin-right: 15px;
    text-align: center;
    ::placeholder {
      color: #bcbcbc;
    }
  }
`;
