/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useInterval } from "../../hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { TIME_STOP } from "../../_reducers/timeAction";
import { examApi } from "../../api/exam/examApi";
import { useLogout } from "../../hooks/useLogout";
import { chatMessage } from "../../adapters/chatMessage";
import { PendingTemplate } from "../base/PendingTemplate";
import { useTranslation } from "react-i18next";

export const PendingDualMonitor = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { sendToChat } = chatMessage();

  const [count, setCount] = useState(10);
  const [isRunning, setIsRunning] = useState(true);
  const { completer } = useSelector((state) => state.userAction); //사전 점검 완료자 인지 아니니지
  const token = sessionStorage.getItem("accessToken");

  //듀얼모니터 부정행위 api
  useEffect(() => {
    dispatch({ type: TIME_STOP, data: true });
    if (!completer && !token) return;
    sendToChat(
      "<strong>[듀얼모니터 사용이 감지되었습니다. 부정행위 처리 되었습니다.]</strong>",
      "chat"
    );
    examApi.examBan("듀얼 모니터 감지");
  }, [dispatch]);

  useInterval(
    () => {
      setCount(count - 1);
      if (count === 1) {
        setIsRunning(false);
        logout();
      }
    },
    isRunning ? 1000 : null
  );

  return (
    <>
      {isRunning && token && (
        <PendingTemplate title={t(`pending.dualMonitor.title`)}>
          <div css={pendingContent}>
            {t(`pending.dualMonitor.검사중`)}
            <div css={countWrapper}>{count}</div>
          </div>
        </PendingTemplate>
      )}

      {isRunning && !token && (
        <PendingTemplate title={t(`pending.dualMonitor.title`)}>
          <div css={pendingContent}>
            검사 진행 중 추가 모니터 연결할 경우 <br />
            프로그램이 강제 종료되며 재접속이 불가합니다. <br />
            추가 모니터 연결을 해제한 후 다시 로그인해주세요.
            <div className="title">[Connecting secondary monitor(s)]</div>
            <br />
            If you connect a secondary monitor to your computer during the test,
            <br />
            the program is terminated with no re-login permitted thereafter.
            <br />
            Please disconnect the secondary monitor, and try re-login.
            <div css={countWrapper}>{count}</div>
          </div>
        </PendingTemplate>
      )}
    </>
  );
};

const pendingContent = css`
  padding-top: 20px;
  line-height: 30px;
  white-space: pre;
  .title {
    padding-top: 60px;
    font-weight: bold;
    font-size: 32px;
  }
`;

const countWrapper = css`
  padding-top: 40px;
  font-size: 50px;
  color: #ff004e;
`;
