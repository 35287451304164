/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const ActiveSelector = ({ value, changeValue, options }) => {
  const [selectToggle, setSelectToggle] = useState(false);

  const onClickSelect = (data) => {
    changeValue(data);
    setSelectToggle(false);
  };

  return (
    <div css={totalSelectWrap}>
      <div value={value}>
        <FontAwesomeIcon
          icon={faAngleDown}
          onClick={() => setSelectToggle((prev) => !prev)}
        />
      </div>
      {selectToggle && (
        <div css={selectOptionBox}>
          {options.map((data) => {
            return (
              <div
                className="select-option"
                key={data.label}
                value={data.value}
                onClick={() => onClickSelect(data.value)}
              >
                {data.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const totalSelectWrap = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background-color: #ccc; */
  width: 5px;
  margin: 2px 0px 20px 0px;
  color: #131313;
  z-index: 1;
  svg {
    font-size: 17px;
    color: #131313;
    position: absolute;
    right: 5px;
    top: 12px;
    cursor: pointer;
  }
`;

const selectOptionBox = css`
  position: absolute;
  bottom: -142px;
  right: 0;
  width: 120px;
  height: 140px;
  overflow: auto;
  background-color: #f0f0f1;
  border-radius: 4px;
  color: #707070;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .select-option {
    height: 25px;
    width: 100%;
    :hover {
      color: white;
      background-color: #707070;
      cursor: pointer;
    }
  }
`;
