export const socketId = (roomId, peerId) => {
  const roomIDX = roomId; //름 idx
  const userIDX = peerId; //응시자 idx
  const myRoomId = process.env.REACT_APP_CON_SITEID + "-" + roomIDX;
  const myPeerId =
    process.env.REACT_APP_CON_SITEID + "-" + roomIDX + "-" + userIDX;
  const con_my_peer_type = "TESTER";
  const peerViewMode = "CAMERA";

  return { myRoomId, myPeerId, con_my_peer_type, peerViewMode };
};
