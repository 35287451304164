import { ModalTemplate } from "../base/ModalTemplate";

export const MobileFaq = ({ setModal }) => {
  return (
    <ModalTemplate
      title={"모바일 연결 오류"}
      closeBtn={true}
      setModal={setModal}
    >
      Test
    </ModalTemplate>
  );
};
