import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const awsApi = {
  preSignedUrl: (fileName, type) => {
    return apiClient.post(`${API_URL.AWS_PRESIGNED}?type=${type}`, {
      fileName: fileName,
    });
  },
};
