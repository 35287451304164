import rootReducer from "../_reducers/_rootReducer";
import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../_sagas/_rootsaga";
import logger from "redux-logger";
import persistStore from "redux-persist/es/persistStore";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  middlewares.push(logger);
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const sagaRun = () => {
  sagaMiddleware.run(rootSaga);
};

const persistor = persistStore(store);

export { sagaRun, persistor, store };
