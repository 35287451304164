/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const Capture = forwardRef(({ contextRef, hasPhoto }, ref) => {
  const { t } = useTranslation();

  const [isDrawing, setIsDrawing] = useState(false);
  const { completer } = useSelector((state) => state.userAction);

  const startDrawing = ({ nativeEvent }) => {
    if (!completer) return;
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.lineWidth = 15;
    contextRef.current.stroke();
    setIsDrawing(true);
  };

  //마우스 움직일 때
  const draw = ({ nativeEvent }) => {
    if (!completer) return;
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  //마우스를 뗐을 때
  const stopDrawing = () => {
    if (!completer) return;
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  return (
    <div css={totalWrapper({ hasPhoto })}>
      {completer && (
        <div css={noticeBox}>
          <div className="sub-text">{t(`idCard.guide.drawNotice`)}</div>
        </div>
      )}

      <div css={canvasWrapper({ completer })}>
        <canvas
          ref={ref}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </div>
    </div>
  );
});

const totalWrapper = ({ hasPhoto }) => css`
  width: 100%;
  display: ${!hasPhoto && "none"};
`;

const canvasWrapper = ({ completer }) => css`
  position: relative;
  width: 100%;
  height: 100%;
  canvas {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      cursor: ${completer ? `url(/images/brush1.cur) 10 15, auto` : ""};
    }
  }
`;

const noticeBox = () => css`
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #000000;
`;
