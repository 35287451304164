import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import RecordRTC from "recordrtc";
import { examApi } from "../api/exam/examApi";
import { getFormattedTimestamp } from "../utils/getDateFormat";
import { socketId } from "../utils/socketId";
import { useCreateMediaStream } from "./useCreateMediaStream";
import { SET_DEVICE_CAM_STREAM } from "../_reducers/deviceAction";
import { useRef, useState } from "react";
import { useNowExamData } from "./useNowExamData";
import { useNavigate } from "react-router-dom";
import { awsApi } from "../api/aws/awsApi";

let recorderVideo = null;
let recorderScreen = null;
let recordTitle = "";

export const useRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { testerIdx, roomIdx, screenShareUseYN } = useSelector(
    (state) => state.userAction.data
  );
  const { getWebcam } = useCreateMediaStream();
  const { videoStream, screenStream } = useSelector(
    (state) => state.deviceAction
  );
  const { myRoomId, myPeerId } = socketId(
    roomIdx,
    testerIdx,
    process.env.REACT_APP_CON_SITEID
  );
  const nowExamData = useNowExamData();
  const examSubStatusRef = useRef(null);

  const createFile = (blob, fileName) => {
    return new File([blob], fileName, { type: "video/webm" });
  };

  const uploadRecording = async (recorder, type) => {
    const fileName = `[${recordTitle.replaceAll(
      " ",
      ""
    )}]${type}_${getFormattedTimestamp()}.webm`;
    const blob = recorder.getBlob();
    const file = createFile(blob, fileName);

    const headers = {
      "Content-Type": "video/mp4",
    };

    await awsApi.preSignedUrl(fileName, "RECORD").then((res) => {
      console.log(res.data.data.uploadUrl);
      if (res.data.data.preSignedUrl) {
        axios
          .put(res.data.data.preSignedUrl, file, { headers })
          .then(() => {
            examApi.examRecord(type, res.data.data.uploadUrl).then((res) => {
              if (res.data.data.roomIdx !== roomIdx) {
                navigate("/logout");
              }
            });
          })
          .catch(() => {
            startScreenRecordingHandler(screenStream);
            setLoading("error");
          });
      }
    });
  };

  /** 웹캠 파일 저장 */
  const postFilesVideo = async () => {
    try {
      await uploadRecording(recorderVideo, "CAM");
      await startCamRecordingHandler(videoStream);
    } catch (error) {
      startCamRecordingHandler(videoStream);
    }
  };

  /** 스크린 파일 저장 포기 조건없이 보냄*/
  const sendAllScreenWabCamFile = async () => {
    try {
      await uploadRecording(recorderScreen, "SCREEN");

      setLoading(false);
      recorderVideo?.stopRecording(postFilesVideo);
      startScreenRecordingHandler(screenStream);
    } catch {
      startScreenRecordingHandler(screenStream);
      setLoading("error");
    }
  };
  /** 스크린 파일 저장(포기 추가)*/
  const sendScreenWebCamFile = async () => {
    try {
      await uploadRecording(recorderScreen, "SCREEN");
      //예제 시간이 아닐 경우 캠 저장 포기
      if (examSubStatusRef.current === 1) {
        //포기
        startCamRecordingHandler(videoStream);
      } else {
        recorderVideo?.stopRecording(postFilesVideo);
      }
      startScreenRecordingHandler(screenStream);
      setLoading(false);
    } catch {
      startScreenRecordingHandler(screenStream);
      setLoading("error");
    }
  };

  const startScreenRecordingHandler = async (screenStream) => {
    if (screenStream?.active) {
      recorderScreen = await RecordRTC(screenStream, {
        type: "video",
        disableLogs: true,
      });
      await recorderScreen?.startRecording();
    }
  };

  const startCamRecordingHandler = async (videoStream) => {
    recordTitle = nowExamData.example
      ? nowExamData.example?.examSubName ?? "검사 전"
      : nowExamData.exam?.examSubName ?? "검사 전";

    if (videoStream?.active) {
      recorderVideo = await RecordRTC(videoStream, {
        type: "video",
        disableLogs: true,
        videoBitsPerSecond: 10000,
      });

      await recorderVideo?.startRecording();
    }
  };

  /** 녹화 중지 */
  const stopRecordHandler = async (sendAll = false) => {
    if (!recorderScreen) {
      setLoading(false);
    }
    if (screenShareUseYN === "Y") {
      if (sendAll) {
        await recorderScreen?.stopRecording(sendAllScreenWabCamFile);
      } else {
        await recorderScreen?.stopRecording(sendScreenWebCamFile);
      }
    }
  };

  const changeDeviceStartRecord = async () => {
    //녹화 중지, 녹화 전송
    await recorderVideo?.stopRecording(() =>
      uploadRecording(recorderVideo, "CAM")
    );
    //캠 바꿈
    getWebcam(async (stream) => {
      await startCamRecordingHandler(stream);
      dispatch({ type: SET_DEVICE_CAM_STREAM, data: stream });
      return stream;
    });
  };

  /** 녹화 시작 */
  const startRecord = async () => {
    if (recorderScreen) {
      await stopRecordHandler();
    } else {
      startCamRecordingHandler(videoStream);
      startScreenRecordingHandler(screenStream);
    }
  };

  return {
    startRecord,
    stopRecordHandler,
    changeDeviceStartRecord,
    loading,
    setLoading,
  };
};
