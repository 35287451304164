import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const chatApi = {
  getChatMessage: (roomIdx) => {
    return apiClient.get(`${API_URL.CHAT_TESTER}${roomIdx}`);
  },
  sendChatMessage: (roomIdx, data) => {
    return apiClient.post(`${API_URL.CHAT_TESTER}${roomIdx}`, {
      fromId: data.fromId,
      toId: data.toId,
      fromName: data.fromName,
      message: data.message,
      readYN: data.readYN,
    });
  },
};
