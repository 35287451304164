import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { socketId } from "../utils/socketId";
import { SOCKET_USER_INFO } from "../_reducers/socketAction";
import { SOCKET_EVENT } from "./socket.enum";

let socket = {};

export const useRoomConnection = () => {
  const dispatch = useDispatch();
  const { testerIdx, roomIdx, roomUseYN } = useSelector(
    (state) => state.userAction.data
  );
  const { myRoomId, myPeerId, con_my_peer_type } = socketId(
    roomIdx,
    testerIdx,
    process.env.REACT_APP_CON_SITEID
  );

  const disconnect = useCallback(() => {
    if (socket) {
      socket.disconnect();
    }
  }, []);

  const connect = useCallback(() => {
    socket = io.connect("https://" + process.env.REACT_APP_CON_WEBRTC_SERVER, {
      transports: ["websocket"],
    });
  }, []);

  const joinRoom = useCallback(() => {
    socket?.emit(SOCKET_EVENT.JOIN_ROOM, myRoomId, myPeerId, con_my_peer_type);
    dispatch({
      type: SOCKET_USER_INFO,
      data: {
        myPeerId: myPeerId,
        myRoomId: myRoomId,
        myPeerType: con_my_peer_type,
      },
    });
  }, [dispatch, myPeerId, myRoomId, con_my_peer_type]);

  //감독관에게 소켓 메세지 전송(펜딩, 검사 완료)
  const sendToExamTopic = (targetSocketId, sendMessage) => {
    if (Object.keys(socket).length === 0) return;
    socket?.emit(SOCKET_EVENT.EXAM, "to", {
      targetSocketId: targetSocketId,
      message: { message: sendMessage, peerId: myPeerId },
    });
  };

  const socketOff = () => {
    socket.off(SOCKET_EVENT.USER_CHAT);
    socket.off(SOCKET_EVENT.USER_CHAT_READ);
    socket.off(SOCKET_EVENT.USER_VOICE_CHAT);
  };

  //소켓이 비어있으면 연결 (재연결 막기)
  if (
    Object.keys(socket).length === 0 &&
    socket.constructor === Object &&
    roomUseYN === "Y"
  ) {
    socket = io.connect("https://" + process.env.REACT_APP_CON_WEBRTC_SERVER, {
      transports: ["websocket"],
    });
  }

  return {
    socket,
    disconnect,
    connect,
    joinRoom,
    sendToExamTopic,
    socketOff,
  };
};
