/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { QnAModal } from "./qna/QnAModal";
import { FaqModal } from "./faq/FaqModal";
import { useModal } from "../../hooks/useModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  faCommentDots,
  faCircleQuestion,
} from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

export const Inquire = () => {
  const { t } = useTranslation();

  const [contactModal, setContactModal, openModal] = useModal(false);
  const [faqModal, setFaqModal, openFaqModal] = useModal(false);
  const [btModal, _, openbtModal] = useModal(false);

  return (
    <>
      <div css={btWrap({ btModal })}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={openbtModal}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </div>
        {btModal ? (
          <div css={totalWrapper}>
            <div css={faqWrapper}>
              <div css={faqItem}>
                <div css={faqTitle} onClick={openFaqModal}>
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    style={{
                      marginRight: "10px",
                      fontSize: "20px",
                    }}
                  />
                  <div>FAQ</div>
                </div>
                <div css={faqTitle} onClick={openModal}>
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    style={{
                      marginRight: "10px",
                      fontSize: "20px",
                    }}
                  />
                  <div>{t(`qna.modal.modalTitle`)}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {contactModal === true ? (
        <QnAModal setContactModal={setContactModal} />
      ) : null}
      {faqModal === true ? (
        <FaqModal setFaqModal={setFaqModal} faqModal={faqModal} />
      ) : null}
    </>
  );
};

const btWrap = (props) => css`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: ${props.btModal ? "#777" : "#131313"};
  box-shadow: ${props.btModal ? "0" : "2px 2px 4px rgba(0, 0, 0, 0.5)"};
  cursor: pointer;
  font-size: 24px;
  color: #fefefe;
  text-align: center;
`;
const totalWrapper = css`
  position: fixed;
  right: 0;
  bottom: 10px;
  margin-right: 50px;
  margin-bottom: 40px;
  padding: 10px;
  width: 160px;
  background-color: #fefefe;
  box-shadow: 0px 5px 9px #00000020;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1200;
  color: #131313;
  font-size: 14px;
  text-align: left;
`;
const faqWrapper = css`
  display: flex;
  flex-direction: column;
`;
const faqItem = css`
  padding: 10px;
`;
const faqTitle = css`
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
