/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReloadOutlined } from "@ant-design/icons";
import { useRoomConnection } from "../../../../adapters/useRoomConnection";
import { useSelector } from "react-redux";

export const RefreshBadge = () => {
  const { socket, joinRoom } = useRoomConnection();
  const { completer } = useSelector((state) => state.userAction);
  const { roomUseYN } = useSelector((state) => state.userAction.data);

  const refresh = () => {
    if (!completer || roomUseYN === "N") return;
    socket.close();
    //소켓 접속
    socket.connect();
    //룸 접속
    joinRoom();
  };

  return (
    <button css={refreshBadge} onClick={refresh}>
      <ReloadOutlined />
    </button>
  );
};
const refreshBadge = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 25px;
  border-radius: 0.5rem;
  font-weight: 500;
  border: 1px solid #dee2e6;
  background-color: #fff;
  position: absolute;
  right: 5px;
  bottom: 6px;
  color: #4e4e4e;
  font-size: 0.625rem;
  cursor: pointer;
  z-index: 2;
`;
