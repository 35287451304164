/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import { InsungLayout } from "../../../layout/InsungLayout";
import { useDispatch, useSelector } from "react-redux";
import { InsungHeader } from "../InsungHeader";
import { PretestColumPart1 } from "./PretestColumPart1";
import { PretestNextPrevButton } from "../../../system/button/PretestNextPrevButton";
import { ProgressBar } from "../../../system/ProgressBar";
import { ToolTip } from "../../../system/ToolTip";
import { PretestColumPart3 } from "./PreTestColumPart3";
import { PretestColumPart2 } from "./PretestColumPart2";
import { getStorageValue } from "../../../../utils/getStorageValue";
import { SET_PRETEST_REQUEST } from "../../../../_reducers/pretestAction";
import { useTooltip } from "../../../../hooks/useTooltip";
import { PRETEST_EXAM_END_MODAL_ON } from "../../../../_reducers/modalAction";
import { getInsungPart } from "../../../../utils/getInsungPart";
import { API_URL } from "../../../../data/api";
import apiClient from "../../../../api/apiClient";
import { SHOW_CHAT } from "../../../../_reducers/showUtilsAction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PreTestInsung = ({ setRender, render, modal, setModal }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { completer } = useSelector((state) => state.userAction);
  const { roomUseYN } = useSelector((state) => state.userAction?.data);

  //FIXME: 사전점검 toolTip 관련
  const { tooltipArray } = useTooltip();
  const [toolTipIndex, setToolTipIndex] = useState(0);
  const toolTip = tooltipArray[toolTipIndex];

  const [noCheckNum, setNoCheckNum] = useState({ QsetNum: false, Qnum: false });
  const [noCheckSet, setNoCheckSet] = useState(false);

  const {
    examNo,
    examSubNo,
    pageNo,
    examSubNoMax,
    endPageYN,
    examNoMax,
    pageNoMax,
    questionInfoData,
  } = useSelector((state) => state.pretestAction.data);
  const examData = useSelector((state) => state?.pretestAction?.data);

  const questionListSetNo = questionInfoData?.questionList?.length; //한 페이지의 세트 수
  const setQuestion = questionInfoData?.lastNo / questionListSetNo; //한 세트의 문항 수

  const maxPage = examData?.pageNoMax;
  const [completed, setCompleted] = useState(0);

  const getPercent = () => {
    setCompleted(Math.ceil((100 / maxPage) * pageNo));
  };

  const insungPart = getInsungPart(examData);

  useEffect(() => {
    //채팅 툴팀이 켜져 있을 때 채팅일 경우 채팅창 열어주기
    if (toolTipIndex !== -1) {
      if (tooltipArray[toolTipIndex] === "chat") {
        dispatch({ type: SHOW_CHAT, data: true });
      }
    }
  }, [tooltipArray, toolTipIndex]);

  //FIXME: 페이지네이션 퍼센트
  useEffect(() => {
    setCompleted(getPercent);
  }, [examSubNo, pageNo]);

  //FIXME: 클릭 시 툴팁 초기화
  useEffect(() => {
    setNoCheckSet(false);
    setNoCheckNum({ QsetNum: false, Qnum: false });
  }, [render, examSubNo]);

  //FIXME: 페이지 체크
  const checkEndPageYN = async () => {
    if (endPageYN === "N") {
      if (pageNoMax > pageNo) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            examNo: examNo,
            examSubNo: examSubNo,
            mode: "MOVE",
            pageNo: pageNo + 1,
          },
        });
        //과목번호의 max값보다 현재 과목 반호가 작을 때
      } else if (examSubNoMax > examSubNo) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            examNo: examNo,
            examSubNo: examSubNo + 1,
            mode: "MOVE",
            pageNo: 1,
          },
        });
        //검사의 최대 수 examNoMax이 현재보다 크면 다음 검사 진행으로 이동
      } else if (
        examNoMax > examNo &&
        examSubNoMax === examSubNo &&
        pageNoMax === pageNo
      ) {
        dispatch({
          type: SET_PRETEST_REQUEST,
          data: {
            mode: "MOVE",
            examNo: examNo + 1,
            pageNo: 1,
            examSubNo: 1,
          },
        });
      }
    } else {
      //인성 옵션 P 이면서 감독관이 없을 경우
      if (completer || roomUseYN === "N") {
        navigate(`/test/timetable`, { replace: true });
        return;
      }
      //사전점검 완료
      await apiClient.post(`${API_URL.PRACTICE_END_DATA}`).then(() => {
        dispatch({ type: PRETEST_EXAM_END_MODAL_ON });
      });
    }
  };

  //FIXME: 답안이 모두 체크되어 있는지 검사
  const insungNextHandler = () => {
    //인성 파트 3
    if (examData.ipsativeYN === "Y" && examData.likertYN === "N") {
      for (let i = 1; i <= questionListSetNo; i++) {
        if (
          !getStorageValue(`Insung : [${examSubNo}-check-${i}]`) ||
          getStorageValue(`Insung : [${examSubNo}-check-${i}]`)
            .checkDistantId === false ||
          getStorageValue(`Insung : [${examSubNo}-check-${i}]`).checkNearId ===
            false
        ) {
          setNoCheckSet(i);
          return;
        }
        setNoCheckSet(true);
        checkEndPageYN();
      }
    }

    //인성 파트 1
    if (examData.ipsativeYN === "Y" && examData.likertYN === "Y") {
      for (let i = 1; i <= questionListSetNo; i++) {
        for (let j = 1; j <= setQuestion; j++) {
          if (!getStorageValue(`Insung : [${examSubNo}-${i}-${j}]`)) {
            setNoCheckNum({ QsetNum: i, Qnum: j });
            return;
          }
        }
        if (
          !getStorageValue(`Insung : [${examSubNo}-check-${i}]`) ||
          getStorageValue(`Insung : [${examSubNo}-check-${i}]`)
            .checkDistantId === false ||
          getStorageValue(`Insung : [${examSubNo}-check-${i}]`).checkNearId ===
            false
        ) {
          setNoCheckSet(i);
          return;
        }
      }
      //모두 체크가 돼 있으면 마지막 페이지 인지 다음 페이지 인지 체크
      setNoCheckNum({ QsetNum: true, Qnum: true });
      setNoCheckSet(true);
      checkEndPageYN();
    }

    //인성 파트 2
    if (examData.ipsativeYN === "N" && examData.likertYN === "Y") {
      for (let i = 1; i <= questionListSetNo; i++) {
        for (let j = 1; j <= setQuestion; j++) {
          if (!getStorageValue(`Insung : [${examSubNo}-${j}-${j}]`)) {
            setNoCheckNum({ QsetNum: i, Qnum: j });
            return;
          }
        }
      }
      //모두 체크가 돼 있으면 마지막 페이지 인지 다음 페이지 인지 체크
      setNoCheckNum({ QsetNum: true, Qnum: true });
      checkEndPageYN();
    }
  };

  //FIXME: 답안 클릭 시 업데이트
  const clickNumHandler = (Qset, Qnum, data) => {
    const answerObj = {
      Qnum: Qnum,
      answer: data,
    };
    sessionStorage.setItem(
      `Insung : [${examSubNo}-${Qset}-${Qnum}]`,
      JSON.stringify(answerObj)
    );
    const checkAnswer = getStorageValue(
      `Insung : [${examSubNo}-${Qset}-${Qnum}]`
    );
    setRender(checkAnswer); //전역 렌더링 관리
  };

  //FIXME: 답안 클릭 시 업데이트 (멀다 가깝다)
  const clickCheckHandler = (string, QsetNum, Qnum) => {
    const checkAnswer = JSON.parse(
      sessionStorage.getItem(`Insung : [${examSubNo}-check-${QsetNum}]`)
    );
    //체크 되었을 때
    const checkDistantId = checkAnswer
      ? //기존에 들어있던 값 checkDistantId 값이 클릭한 값과 같을 때
        checkAnswer.checkDistantId === Qnum
        ? false
        : checkAnswer.checkDistantId
      : false;

    const checkNearId = checkAnswer
      ? checkAnswer.checkNearId === Qnum
        ? false
        : checkAnswer.checkNearId
      : false;

    const checkObj = {
      checkDistantId:
        string === "distant" ? Qnum : checkDistantId ? checkDistantId : false,
      //distant 이면 클릭한 답을 넣고 아니면 기존에 있는 값을 넣음
      //기존의 값이 없다면 null을 넣음
      checkNearId: string === "near" ? Qnum : checkNearId ? checkNearId : false,
    };
    sessionStorage.setItem(
      `Insung : [${examSubNo}-check-${QsetNum}]`,
      JSON.stringify(checkObj)
    );
    setRender(checkAnswer); // 렌더링 관리
  };

  return (
    <>
      {toolTipIndex < tooltipArray.length && (
        <div css={modalBackground({ toolTipIndex })} />
      )}
      {toolTip === "navbar" && (
        <ToolTip
          top="20px"
          left="30%"
          location="top"
          setIndex={setToolTipIndex}
          start={true}
        >
          {t(`tooltip.navbar`)}
        </ToolTip>
      )}
      <div css={totalLayout}>
        <InsungLayout>
          {toolTip === "progressbar" && (
            <ToolTip
              top="60px"
              left="30%"
              location="top"
              setIndex={setToolTipIndex}
            >
              {t(`tooltip.insung.progressbar`)}
            </ToolTip>
          )}
          <div css={progressBarToolTip({ toolTip })}>
            <ProgressBar
              completed={completed}
              nowPage={pageNo}
              totalPage={maxPage}
            />
          </div>
          {toolTip === "question" && (
            <ToolTip
              top="100px"
              right="500px"
              location="right"
              setIndex={setToolTipIndex}
            >
              {t(`tooltip.insung.question`)}
            </ToolTip>
          )}
          <div css={tableWrapper({ toolTip })}>
            <InsungHeader examData={examData} />
            {examData?.questionInfoData?.questionList.length > 0 &&
              examData?.questionInfoData?.questionList.map(
                (questionList, questionListIndex) => {
                  if (insungPart === "part1") {
                    return (
                      <PretestColumPart1
                        questionList={questionList}
                        noCheckNum={noCheckNum}
                        noCheckSet={noCheckSet}
                        clickNumHandler={clickNumHandler}
                        clickCheckHandler={clickCheckHandler}
                        key={questionList.questionSetNo}
                        index={questionListIndex}
                      />
                    );
                  } else if (insungPart === "part3") {
                    return (
                      <PretestColumPart3
                        questionList={questionList}
                        noCheckSet={noCheckSet}
                        clickCheckHandler={clickCheckHandler}
                        key={questionList.questionSetNo}
                        index={questionListIndex}
                      />
                    );
                  }
                }
              )}
            {insungPart === "part2" && (
              <PretestColumPart2
                questionList={examData?.questionInfoData?.questionList[0]}
                clickNumHandler={clickNumHandler}
                noCheckNum={noCheckNum.Qnum}
              />
            )}
          </div>
        </InsungLayout>
        <InsungLayout>
          <div css={buttonToolTip({ toolTip })}>
            {toolTip === "button" && (
              <ToolTip
                bottom="90px"
                left="40%"
                location="bottom"
                setIndex={setToolTipIndex}
                end={true}
              >
                {t(`tooltip.insung.button`)}
              </ToolTip>
            )}
            <PretestNextPrevButton
              setModal={setModal}
              modal={modal}
              insungNextHandler={insungNextHandler}
              setToolTipIndex={setToolTipIndex}
            />
          </div>
        </InsungLayout>
      </div>

      <div css={chatTooltip}>
        {toolTip === "chat" && (
          <ToolTip
            top="120px"
            right="380px"
            location="right"
            setIndex={setToolTipIndex}
          >
            {t(`tooltip.chat`)}
          </ToolTip>
        )}
      </div>
    </>
  );
};

const totalLayout = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const chatTooltip = ({ toolTip }) => css`
  z-index: ${toolTip === "chat" && 8};
  background-color: white;
`;

const progressBarToolTip = ({ toolTip }) => css`
  z-index: ${toolTip === "progressbar" && 8};
  background: white;
`;

const modalBackground = ({ toolTipIndex }) => css`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8;
  height: ${toolTipIndex !== -1 && "100vh"};
`;

const tableWrapper = ({ toolTip }) => css`
  z-index: ${toolTip === "question" && 8};
  background-color: white;
  width: 100%;
  height: 100%;
  th,
  td {
    border-top: 1px solid #444444;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  th {
    text-align: center;
  }
`;

const buttonToolTip = ({ toolTip }) => css`
  display: flex;
  z-index: ${toolTip === "button" && 8};
`;
