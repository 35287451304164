/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Loading } from "../modal/Loading";
import { useTranslation } from "react-i18next";

export const PendingTemplate = ({ title, children, loading, zIndex = 999 }) => {
  const { t } = useTranslation();

  return (
    <div
      css={totalWrapper({ zIndex })}
      //강제 퇴장 당할 시 zIndex 를 높게 주어 가장 위에 뜨게 하기
      style={title === t(`modal.kick.text`) ? { zIndex: 800 } : null}
    >
      <div className="text">{title}</div>
      {children}
      <div className="loading-wrapper">{loading && <Loading size={60} />}</div>
    </div>
  );
};

const totalWrapper = ({ zIndex }) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.4;
  user-select: none;
  z-index: ${zIndex};
  position: absolute;
  background-color: #fff;
  white-space: pre-line;
  text-align: center;
  .text {
    padding-top: 60px;
    font-weight: bold;
    font-size: 32px;
  }
  .loading-wrapper {
    padding-top: 60px;
  }
`;
