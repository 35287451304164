/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";

export const FormLabelGroup = ({
  title,
  placeholder,
  textarea = false,
  children = false,
  onChange,
  value,
}) => {
  return (
    <div css={totalWrapper}>
      <div className="input-title">{title}</div>
      <div className="content-wrapper">
        {children && <div className="children-wrapper">{children}</div>}
        {textarea && (
          <div className="textarea-contents">
            <textarea
              onChange={onChange}
              value={value}
              placeholder={placeholder}
            />
          </div>
        )}
        {!textarea && !children && (
          <div className="input-contents">
            <input
              placeholder={placeholder}
              onChange={onChange}
              maxLength={(title === "휴대 전화" && 13) || null}
              value={value}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const totalWrapper = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-top: 1px #707070 solid;
  font-size: 15px;

  .input-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    background-color: #f0f0f1;
  }
  .content-wrapper {
    width: 100%;
    height: 100%;
    width: 500px;
  }

  .children-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .input-contents {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    align-items: center;
    input {
      display: flex;
      width: 100%;
      height: 45px;
      padding: 0 20px;
      ::placeholder {
        color: #bcbcbc;
      }
    }
  }
  .textarea-contents {
    width: 100%;
    height: 150px;
    padding: 15px 20px;
    font-size: 15px;
    font-weight: 400;

    textarea {
      resize: none;
      width: 100%;
      height: 100%;
      ::placeholder {
        color: #bcbcbc;
      }
    }
  }
`;
