/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { BasicButton } from "../system/button/BasicButton";
import { Loading } from "../modal/Loading";
import { useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { useLogout } from "../../hooks/useLogout";
import { PendingTemplate } from "../base/PendingTemplate";
import { useTranslation } from "react-i18next";

export const PendingNetworkError = ({ loading }) => {
  const { t } = useTranslation();

  const LoadingModal = () => {
    return (
      <>
        <span css={noticeText}>{t(`pending.network.text.loading`)}</span>
        <Loading />
      </>
    );
  };

  const DisconnectModal = () => {
    const { logout } = useLogout();

    return (
      <>
        <span css={noticeText}>{t(`pending.network.text.disconnect`)}</span>
        <BasicButton
          wid="s"
          hei="s"
          className="btHover"
          label={t(`exam.button.end`)}
          onClick={logout}
        />
      </>
    );
  };
  const DisconnectTryModal = () => {
    return (
      <div css={contentWrap}>
        <span css={noticeText}>{t(`pending.network.text.disconnectTry`)}</span>
        <Loading size={60} />
      </div>
    );
  };

  const [second, setSecond] = useState(10);
  const [isConnect, setIsConnect] = useState(false);

  useInterval(
    () => {
      if (second > 0) {
        setSecond((sec) => sec - 1);
      }
      if (second === 0) {
        setIsConnect(true);
      }
    },
    isConnect ? null : 1000
  );

  const getPendingTitle = () => {
    if (loading) {
      return t(`pending.network.title.loading`);
    } else if (second > 0) {
      return t(`pending.network.title.connecting`);
    } else {
      return t(`pending.network.title.disconnect`);
    }
  };

  return (
    <PendingTemplate title={getPendingTitle()} zIndex={999}>
      {loading ? (
        <LoadingModal />
      ) : second === 0 ? (
        <DisconnectModal />
      ) : (
        <DisconnectTryModal />
      )}
    </PendingTemplate>
  );
};

const contentWrap = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
const noticeText = css`
  display: flex;
  margin-bottom: 20px;
  padding-top: 30px;
  font-size: 20px;
  word-break: break-all;
  white-space: pre;
`;
