/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import { Loading } from "../modal/Loading";
import { TIME_STOP } from "../../_reducers/timeAction";
import { useDispatch, useSelector } from "react-redux";

export const PendingLoading = ({ loading }) => {
  const dispatch = useDispatch();

  const { pendingModal } = useSelector((state) => state.modalAction);

  const [visibleState, setVisibleState] = useState({
    timeLoading: false,
    isScreen: false,
  });

  useEffect(() => {
    let timeTimeout, screenTimeout;

    if (loading) {
      timeTimeout = setTimeout(() => {
        setVisibleState((prev) => ({ ...prev, timeLoading: true }));
      }, 300);

      screenTimeout = setTimeout(() => {
        setVisibleState({ timeLoading: true, isScreen: true });
        dispatch({ type: TIME_STOP, data: true });
      }, 2000);
    } else {
      if (!pendingModal) {
        setVisibleState({ timeLoading: false, isScreen: false });
        dispatch({ type: TIME_STOP, data: false });
        clearTimeout(screenTimeout);
      }
    }

    return () => {
      clearTimeout(timeTimeout);
      clearTimeout(screenTimeout);
      setVisibleState({ timeLoading: false, isScreen: false });
    };
  }, [loading, pendingModal, dispatch]);

  const overlayStyle = css({
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 4001,
  });

  const Overlay = ({ children, backgroundColor }) => (
    <div css={[overlayStyle, { backgroundColor }]}>
      <div css={{ paddingBottom: "100px" }}>{children}</div>
    </div>
  );

  return (
    <>
      {visibleState.isScreen && (
        <Overlay backgroundColor="white">
          <Loading size={60} />
        </Overlay>
      )}
      {visibleState.timeLoading && !visibleState.isScreen && (
        <Overlay>
          <Loading size={60} />
        </Overlay>
      )}
    </>
  );
};
