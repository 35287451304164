import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const dataApi = {
  faqData: (language) => {
    return apiClient.get(API_URL.FAQ_DATA + `?language=${language}`);
  },
  qna: (data) => {
    return apiClient.post(
      API_URL.QNA_DATA +
        (data.companyCode !== null
          ? `/,?companyCode=${data.companyCode}&language=${data.language}`
          : `/null?language=${data.language}`),
      {
        category: data.category,
        testerName: data.testerName,
        testerEmail: data.testerEmail,
        testerCell: data.testerCell,
        text: data.text,
      }
    );
  },
  testerQna: (data) => {
    return apiClient.post(
      API_URL.QNA_DATA +
        "/" +
        data.testerIdx +
        `,?companyCode=${data.companyCode}`,
      {
        category: data.category,
        testerName: data.testerName,
        testerEmail: data.testerEmail,
        testerCell: data.testerCell,
        text: data.text,
      }
    );
  },
  layoutData: (companyCode) => {
    return apiClient.get(`${API_URL.LAYOUT_DATA}?companyCode=${companyCode}`);
  },
  validateLayoutData: (companyCode) => {
    return apiClient.post(`${API_URL.LAYOUT_DATA}/validate-code`, {
      webUrlCode: companyCode,
    });
  },
  timetableData: () => {
    return apiClient.get(`${API_URL.EXAM_USER_DATA}?standByStatus=timetable`);
  },
  agreementData: (language) => {
    return apiClient.get(`${API_URL.AGREE_DATA}?language=${language}`);
  },
  userData: () => {
    return apiClient.get(`${API_URL.EXAM_USER_DATA}`);
  },
  timeData: () => {
    return apiClient.get(`${API_URL.EXAM_CURRENT_TIME}`);
  },
};
