/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { PendingTemplate } from "../base/PendingTemplate";
import { Loading } from "../modal/Loading";
import { useTranslation } from "react-i18next";

export const PendingAi = () => {
  const { t } = useTranslation();

  return (
    <PendingTemplate>
      <span css={pendingTitle}>{t(`device.aiPending`)}</span>
      <Loading />
      <span css={downLoadText}>DOWNLOAD...</span>
    </PendingTemplate>
  );
};

const pendingTitle = css`
  padding-bottom: 60px;
  font-weight: bold;
  font-size: 28px;
`;

const downLoadText = css`
  padding-bottom: 100px;
`;
