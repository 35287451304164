/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const CheckButton = ({
  checked,
  value,
  onClick,
  onChange,
  label,
  size,
}) => {
  return (
    <div css={containerStyles}>
      <label css={checkboxContainerStyles({ size, checked })}>
        <input
          name="name"
          type="checkbox"
          checked={checked}
          value={value}
          onClick={onClick}
          onChange={onChange}
          readOnly
        />
        <span css={checkboxMarkStyles({ size, checked })} />
        {label}
      </label>
    </div>
  );
};

const containerStyles = css`
  font-size: 15px;
  display: flex;
  max-height: 47px;
`;

const checkboxContainerStyles = ({ size, checked }) => css`
  font-size: 20px;
  height: 40px;
  margin-top: ${size === "s" ? "10px" : "7px"};
  margin-bottom: 10px;
  padding-left: 40px;
  cursor: pointer;
  position: relative;
  user-select: none;
  input {
    position: "absolute";
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

const checkboxMarkStyles = ({ size, checked }) => css`
  width: ${size === "s" ? "20px" : "27px"};
  height: ${size === "s" ? "20px" : "27px"};
  top: 2px;
  left: 0;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;

  &:after {
    display: none;
    content: "";
    position: relative;
  }

  ${checked &&
  `
    &:after {
      display: block;
    }
  `}

  &:after {
    width: ${size === "s" ? "8px" : "10px"};
    height: ${size === "s" ? "17px" : "19px"};
    top: ${size === "s" ? "-1px" : "1px"};
    left: ${size === "s" ? "5px" : "8px"};
    border-style: solid;
    border-color: #145bff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;
