/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { getStorageValue } from "../../../../utils/getStorageValue";
import { useTranslation } from "react-i18next";

export const PretestColumPart2 = ({
  questionList,
  noCheckNum,
  clickNumHandler,
}) => {
  const { t } = useTranslation();

  const examData = useSelector((state) => state?.pretestAction?.data);
  const maxScale = examData?.maxScale;
  const nowPageSetNum = examData?.questionInfoData?.lastSetNo;

  const clickNumCheckCss = (question, CheckNum) => {
    if (
      CheckNum + 1 ===
      getStorageValue(
        `Insung : [${examData.examSubNo}-${question.questionNo}-${question.questionNo}]`
      )?.answer
    ) {
      return "picked";
    } else {
      return "pick";
    }
  };

  return (
    <div css={totalWrapper({ nowPageSetNum })}>
      {questionList?.length > 0 &&
        questionList?.map((question, questionSetNum) => (
          <div
            css={[
              questionWrap,
              css`
                background-color: ${noCheckNum === questionSetNum + 1 &&
                "#FFE5ED"};
              `,
            ]}
            key={question.questionNo}
          >
            {/* 문항 번호 */}
            <div css={tableLeft}>{question.questionNo}</div>
            <div
              css={[
                tableRight({ nowPageSetNum }),
                css`
                  &:hover {
                    background-color: #e9e9e9;
                  }
                `,
              ]}
            >
              <div className="question">
                {/* 문항 text */}
                {question.questionText}
              </div>
              <div className="question-standard">
                {/* 체크 번호*/}
                {[...Array(maxScale)].map((value, checkNum) => (
                  <Tooltip
                    color={"red"}
                    placement="left"
                    title={t(`exam.insung.tooltip.questionNum`)}
                    open={noCheckNum === questionSetNum + 1 && checkNum === 0}
                    trigger={"none"}
                    key={checkNum}
                  >
                    <div css={pickNum}>
                      <span
                        className={clickNumCheckCss(question, checkNum)}
                        onClick={() =>
                          clickNumHandler(
                            question.questionNo,
                            question.questionNo,
                            checkNum + 1
                          )
                        }
                      >
                        {checkNum + 1}
                      </span>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const totalWrapper = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${nowPageSetNum <= 3 ? "16px" : "14px"};
  border-bottom: 2px solid #5b5b5b;

  .question {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 10px;
  }
  .question-standard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 380px;
    border-left: 1px solid #3b3b3b;
  }
`;
const tableLeft = css`
  display: flex;
  flex-direction: row;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
`;

const tableRight = ({ nowPageSetNum }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${nowPageSetNum <= 3 ? "80px" : "45px"};
`;

const questionWrap = css`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid #5b5b5b;
  }
`;
const pickNum = css`
  width: 20%;
  text-align: center;
  height: 100%;
  .pick {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    transition: all 0.1s;
    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #d2d3d4;
      color: #3b3b3b;
      cursor: pointer;
    }
  }
  .picked {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: medium;
    text-align: center;
    background-color: #3b3b3b;
    color: #fff;

    &:active {
      box-shadow: 0 0 0 3px #fefefe inset;
    }

    &:hover {
      background-color: #3b3b3b;
      color: #fff;
      cursor: pointer;
    }
  }
`;
