import { API_URL } from "../../data/api";
import apiClient from "../apiClient";

export const authApi = {
  phoneNum: (companyCode, language, data) => {
    return apiClient.post(
      `${API_URL.LOGIN_PHONE_NUM}?companyCode=${companyCode}&language=${language}`,
      {
        testerCell: data.testerCell,
        logNetwork: data.logNetwork,
      }
    );
  },
  confirmNum: (language, testerIdx, authKey) => {
    return apiClient.post(`${API_URL.LOGIN_CONFIRM_NUM}?language=${language}`, {
      testerIdx: testerIdx,
      authKey: authKey,
    });
  },
};
