/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { convertURLtoFile, dataURLtoFile } from "../../utils/convert";

export const Canvas = forwardRef(({ url, setMergeFile }, ref) => {
  const canvasRef = useRef(null);

  const [ctx, setCtx] = useState();
  const [isDrawing, setIsDrawing] = useState(false);

  const mergeRef = useRef(null);
  const mergeCanvas = mergeRef.current;

  useEffect(() => {
    const imageCanvas = canvasRef.current;
    const drawCanvas = ref.drawRef.current;

    // 메모리에 실제 크기 설정 (픽셀 밀도를 고려하여 크기 조정)
    const dpr = window.devicePixelRatio;

    imageCanvas.width = 400 * dpr;
    imageCanvas.height = 300 * dpr;

    //캔버스 사이즈 고정
    drawCanvas.width = 400 * dpr;
    drawCanvas.height = 300 * dpr;

    const drawCtx = drawCanvas.getContext("2d");
    const imgCtx = imageCanvas.getContext("2d");

    drawCtx.globalCompositeOperation = "source-over";
    drawCtx.lineCap = "round";
    drawCtx.strokeStyle = "black";
    drawCtx.lineWidth = "15";

    // CSS에서 설정한 크기와 맞춰주기 위한 scale 조정
    drawCtx.scale(dpr, dpr);
    setCtx(drawCtx);

    //사진 -> 캔버스로 바꾸는 작업
    const inImage = new Image();
    inImage.width = 1;
    inImage.height = 1;
    inImage.id = "newPreviewImage";

    inImage.src = url ? url : `images/${url?.name}`;
    imageCanvas.width = 400;
    imageCanvas.height = 300;

    if (document.contains(document.querySelector("#newPreviewImage"))) {
      document.querySelector("#newPreviewImage").remove();
    }
    document.querySelector("body").appendChild(inImage);

    inImage.onload = function () {
      setTimeout(() => {
        imgCtx.drawImage(inImage, 0, 0, 400, 300);
      }, 100);
    };

    async function initImageConvertURLtoFile() {
      if (url) {
        const convertFile = await convertURLtoFile(url);
        setMergeFile(convertFile);
      }
    }
    initImageConvertURLtoFile();
  }, [ref.drawRef, url]);

  //마우스 눌렀을 때
  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
    setIsDrawing(true);
  };

  //마우스 움직일 때
  const draw = ({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = nativeEvent;
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  //마우스를 뗐을 때
  const stopDrawing = () => {
    setIsDrawing(false);
    getMergeUrl();
  };

  //캔버스 두개 합성 (이미지 + 드로잉)
  const getMergeUrl = () => {
    const drawCanvas = ref.drawRef?.current;

    mergeCanvas.width = 400;
    mergeCanvas.height = 300;

    const mergeCtx = mergeCanvas.getContext("2d", {
      preserveDrawingBuffer: true,
    });

    mergeCtx.drawImage(canvasRef.current, 0, 0);
    mergeCtx.drawImage(drawCanvas, 0, 0, 400, 300);

    const mergeFile = dataURLtoFile(mergeRef.current.toDataURL(), "idcard");
    setMergeFile(mergeFile);
  };

  return (
    <>
      <canvas
        ref={ref.drawRef}
        css={canvasTop}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
      />
      <canvas ref={canvasRef} css={canvasBottom} />
      <canvas ref={mergeRef} css={canvasMerge} />
    </>
  );
});

const canvasTop = css`
  z-index: 3;
  position: absolute;
  width: 400px;
  height: 300px;
  &:hover {
    cursor: url(/images/brush1.cur) 10 15, auto;
  }
`;

const canvasBottom = css`
  z-index: 1;
  position: absolute;
  width: 400px;
  height: 300px;
`;

const canvasMerge = css`
  z-index: -1;
  position: absolute;
  width: 400px;
  height: 300px;
`;
