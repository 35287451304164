import { combineReducers } from "redux";

import themeAction from "./themeAction";
import userAction from "./userAction";
import pretestAction from "./pretestAction";
import chatAction from "./chatAction";
import deviceAction from "./deviceAction";
import aiAction from "./aiAction";
import examUserDataAction from "./examUserDataAction";
import mainExamAction from "./mainExamAction";
import timeAction from "./timeAction";
import showUtilsAction from "./showUtilsAction";
import modalAction from "./modalAction";
import socketAction from "./socketAction";

import storageSession from "redux-persist/lib/storage/session";
import persistReducer from "redux-persist/es/persistReducer";

const rootReducer = combineReducers({
  themeAction,
  userAction,
  pretestAction,
  chatAction,
  deviceAction,
  examUserDataAction,
  mainExamAction,
  timeAction,
  showUtilsAction,
  modalAction,
  socketAction,
  aiAction,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [
    "themeAction",
    "userAction",
    "pretestAction",
    "chatAction",
    "deviceAction",
    "examUserDataAction",
    "mainExamAction",
    "timeAction",
    "showUtilsAction",
    "modalAction",
    "socketAction",
  ],
};

export default persistReducer(persistConfig, rootReducer);
