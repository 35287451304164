//am,pm 형식
export const getDateFormatAMPM = (date, language) => {
  try {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm =
      hours >= 12
        ? language === "KOR"
          ? "오후"
          : "PM"
        : language === "KOR"
        ? "오전"
        : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = ampm + " " + hours + ":" + minutes;
    return strTime;
  } catch (e) {
    console.log(e);
  }
};

//yyyyMMddhhmmss  형식
export const getCurrentDate = () => {
  var date = new Date();
  try {
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? "0" + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? "0" + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? "0" + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? "0" + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds;
  } catch (e) {
    console.log(e);
  }
};

export const getFormattedTimestamp = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const date = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = String(now.getMilliseconds()).padStart(4, "0");

  return `${year}-${month}-${date}_${hours}:${minutes}:${seconds}:${milliseconds}`;
};
