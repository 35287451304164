import { CHAT_WARNING_MESSAGE } from "../_reducers/chatAction";
import { checkNetworkDownlink } from "./networkSpeed";
import { USER_NETWORK_SPEED } from "../_reducers/userAction";
import { SHOW_CHAT } from "../_reducers/showUtilsAction";

export const checkNetworkHandler = (dispatch, sendToChat, language) => {
  const isNetworkSpeed = checkNetworkDownlink();
  dispatch({ type: USER_NETWORK_SPEED, data: isNetworkSpeed });
  //검사 중일 경우 응시자 네트워크 에러 숨기기
  let languageMessage =
    language === "KOR"
      ? `<strong>[알림]\n 응시자의 네트워크 연결 상태가 원활하지 않습니다.`
      : "[Alert] The test taker's network connection is not stable or fast enough.";

  if (!isNetworkSpeed) {
    sendToChat(languageMessage, "warning");
  }
};

export const sendSomeKeysHandler = (
  e,
  dispatch,
  roomId,
  testerIdx,
  completer,
  language
) => {
  let languageMessage =
    language === "KOR"
      ? `<strong>[경고]\n 검사 진행에 불필요한 특수키 사용이 확인되었습니다.</strong><br> ${
          !completer ? `본 검사에서 ` : ""
        } 특수키 입력이 반복적으로 발생하는 경우 부정행위로 간주하여 강제 퇴장될 수 있으며, 재접속이 불가한 점 유의하여 주십시오.`
      : `<strong>[Warning]\n<strong/> You have used a keyboard key that is not necessary for taking the test. Please note that repeated use of unnecessary keys is considered cheating, and the test will be forcibly terminated with no re-login permitted thereafter.`;

  if ((e.metaKey && e.shiftKey) || (e.ctrlKey && e.shiftKey)) {
    //캡쳐시도
    //소켓 메세지 전송 [알림] Ctrl+Shift(Cmd + Shift) 키 입력 감지
    //응시자 채팅 전송
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: languageMessage,
        logAt: new Date(),
        readYN: "N",
      },
    });
    sessionStorage.setItem(
      "warningCount",
      parseInt(sessionStorage.getItem("warningCount")) + 1
    );
    dispatch({ type: SHOW_CHAT, data: true });
  } else if (e.metaKey && !window.navigator.userAgent.includes("Mac")) {
    //소켓 메세지 전송 "[알림] Window 키 입력 감지",
    //응시자 채팅 전송
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: languageMessage,
        logAt: new Date(),
        readYN: "N",
      },
    });
    sessionStorage.setItem(
      "warningCount",
      parseInt(sessionStorage.getItem("warningCount")) + 1
    );
    dispatch({ type: SHOW_CHAT, data: true });
  } else if (e.altKey) {
    //소켓 메세지 전송 "[알림] option 키 입력 감지",
    //응시자 채팅 전송
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: languageMessage,

        logAt: new Date(),
        readYN: "N",
      },
    });
    sessionStorage.setItem(
      "warningCount",
      parseInt(sessionStorage.getItem("warningCount")) + 1
    );
    dispatch({ type: SHOW_CHAT, data: true });
  } else if (e.which === 123) {
    //소켓 메세지 전송 "[알림] F12 키 입력 감지",
    //응시자 채팅 전송
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: languageMessage,
        logAt: new Date(),
        readYN: "N",
      },
    });
    sessionStorage.setItem(
      "warningCount",
      parseInt(sessionStorage.getItem("warningCount")) + 1
    );
    dispatch({ type: SHOW_CHAT, data: true });
  }
};

export const sendSomeKeysUpHandler = (
  e,
  dispatch,
  roomId,
  testerIdx,
  completer,
  language
) => {
  let languageMessage =
    language === "KOR"
      ? `<strong>[경고]\n 검사 진행에 불필요한 특수키 사용이 확인되었습니다.</strong><br> ${
          !completer ? `본 검사에서 ` : ""
        } 특수키 입력이 반복적으로 발생하는 경우 부정행위로 간주하여 강제 퇴장될 수 있으며, 재접속이 불가한 점 유의하여 주십시오.`
      : `<strong>[Warning]\n<strong/> You have used a keyboard key that is not necessary for taking the test. Please note that repeated use of unnecessary keys is considered cheating, and the test will be forcibly terminated with no re-login permitted thereafter.`;

  if (e.keycode === 44) {
    //프린트스크린 키
    //소켓 메세지 전송  "[알림] PrtScn 키 입력 감지",
    //응시자 채팅 전송
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: languageMessage,
        logAt: new Date(),
        readYN: "N",
      },
    });
    dispatch({ type: SHOW_CHAT, data: true });
  }
};

//터치 사용자 일 경우 응시자에게 주의 메세지
export const isTouchDeviceHandler = (e, dispatch, roomId, testerIdx) => {
  const isTouchDevice =
    navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

  if (isTouchDevice) {
    dispatch({
      type: CHAT_WARNING_MESSAGE,
      data: {
        fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
        toId: roomId + "-MANAGER",
        fromName: "감독관",
        message: `<strong>[경고]\n 터치할 수 있는 PC입니다.</strong><br> 시험 중 터치를 사용할 경우 부정행위로 처리될 수 있다는 점 유의하시기 바랍니다.`,
        logAt: new Date(),
        readYN: "N",
      },
    });
    dispatch({ type: SHOW_CHAT, data: true });
  }
};

//터치 스크린 감지 시
export const touchHandler = (dispatch, roomId, testerIdx) => {
  //소켓 전송
  dispatch({
    type: CHAT_WARNING_MESSAGE,
    data: {
      fromId: `${process.env.REACT_APP_CON_SITEID}-${roomId}-${testerIdx}`,
      toId: roomId + "-MANAGER",
      fromName: "감독관",
      message: `<strong>[경고]\n 화면 터치 기능 사용이 확인되었습니다. </strong><br> 터치 기능 사용이 반복적으로 발생하는 경우 부정행위로 간주하여 강제 퇴장될 수 있으며, 재접속이 불가한 점 유의하여 주십시오.`,
      logAt: new Date(),
      readYN: "N",
    },
  });
  sessionStorage.setItem(
    "warningCount",
    parseInt(sessionStorage.getItem("warningCount")) + 1
  );
  dispatch({ type: SHOW_CHAT, data: true });
};

export const checkDeviceHandler = (sendToChat) => {
  const isTouchDevice =
    navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

  //맥 사용자 인지
  if (window.navigator.userAgent.includes("Mac")) {
    sendToChat(`<strong>[알림]\n Mac OS 사용 응시자 </strong>`, "chat");
  }
  //터치 가능한 기기인지
  if (isTouchDevice) {
    sendToChat(`<strong>[알림]\n 터치기능 PC 사용 응시자</strong>`, "chat");
  }
};
