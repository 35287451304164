/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RTCConfig } from "../../utils/rtcConfig";
import { getQueryObject } from "../../utils/url";
import { io } from "socket.io-client";
import { MobileDisconnectPage } from "./MobildDisconnectPage";
import { SOCKET_EVENT } from "../../adapters/socket.enum";
let myRoomId = "";
let myPeerId = "";
let my_video_stream;
let peerConnections = [];
let socket = {};

export const MobileConnectPage = () => {
  const location = useLocation();
  const mobileVideoRef = useRef();
  const url = getQueryObject(location.search);
  const navigate = useNavigate();

  const [mobileDisconnect, setMobileDisconnect] = useState(false);
  useEffect(() => {
    socket = io.connect("https://" + process.env.REACT_APP_CON_WEBRTC_SERVER, {
      transports: ["websocket"],
    });
    MyCameraLoad(url.roomIdx, url.testerIdx);
  }, []);

  const myPeerType = "MOBILE";
  //1. 캠 활성화
  //룸IDX, 응시자IDX, 응시자이름
  const MyCameraLoad = (roomIDX, testerIDX, testerName) => {
    if (
      process.env.REACT_APP_CON_SITEID !== "" &&
      roomIDX !== "" &&
      testerIDX !== ""
    ) {
      //룸ID세팅
      myRoomId = process.env.REACT_APP_CON_SITEID + "-" + roomIDX;
      //응시자ID세팅
      myPeerId =
        process.env.REACT_APP_CON_SITEID +
        "-" +
        roomIDX +
        "-" +
        testerIDX +
        "-MOBILE";

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            // facingMode: { exact: "user" },
            width: { min: 160 },
            height: { min: 90 },
          },
        })
        .then((stream) => {
          my_video_stream = stream;
          mobileVideoRef.current.srcObject = stream;
          socketConnection();
        })
        .catch((err) => {
          alert(
            "웹캠을 불러오지 못 하였습니다. 다른 장치에서 사용중이 아닌지 확인해 주세요."
          );
          console.log(err);
        });
    } else {
      alert("응시자 정보가 없습니다.");
    }
  };

  const socketConnection = () => {
    if (Object.keys(socket).length === 0) return;
    socket?.emit(SOCKET_EVENT.JOIN_ROOM, myRoomId, myPeerId, myPeerType);
  };

  useEffect(() => {
    if (Object.keys(socket).length === 0) return;
    socket?.on(SOCKET_EVENT.USER_CANDIDATE, (peerId, candidate) => {
      peerConnections[peerId].addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket?.on(SOCKET_EVENT.USER_CONNECTED, (message) => {
      if (message.peerId + "-MOBILE" === myPeerId) {
        setMobileDisconnect(false);
        socketConnection();
      }
    });

    socket?.on(SOCKET_EVENT.USER_DISCONNECTED, (peerId, peerType, socketId) => {
      if (peerId + "-MOBILE" === myPeerId) {
        setMobileDisconnect(true);
      }
    });

    //영상수신
    socket?.on(
      SOCKET_EVENT.USER_OFFER,
      async (peerId, socketId, videoAnswer, description) => {
        peerConnections[peerId] = new RTCPeerConnection(RTCConfig);
        //영상 교환일 경우에만
        if (videoAnswer === "Y") {
          //내 영상 추가
          my_video_stream
            .getTracks()
            .forEach((track) =>
              peerConnections[peerId].addTrack(track, my_video_stream)
            );
        }
        peerConnections[peerId].setRemoteDescription(description);
        const answer = await peerConnections[peerId].createAnswer();
        peerConnections[peerId].setLocalDescription(answer);
        socket?.emit(SOCKET_EVENT.ANSWER, myPeerId, socketId, answer);

        peerConnections[peerId].ontrack = (event) => {
          let stream = event.streams[0];

          if (stream) {
            if (event.track.kind === "video") {
              //응시자 영상 출력
              mobileVideoRef.current.srcObject = stream;
            }
          }
        };

        peerConnections[peerId].onicecandidate = (event) => {
          if (event.candidate) {
            socket?.emit(
              SOCKET_EVENT.CANDIDATE,
              myPeerId,
              socketId,
              event.candidate
            );
          }
        };
      }
    );

    //검사 완료 시 닫기
    socket?.on(SOCKET_EVENT.USER_EXAM, (message) => {
      if (message.message === "COMPLETE") {
        navigate("/mobile/disconnect/end", { replace: true });
      }
    });
    return () => {
      socket?.off(SOCKET_EVENT.USER_EXAM);
    };
  }, []);

  return (
    <>
      {mobileDisconnect && <MobileDisconnectPage completer={url.completer} />}
      <div css={mobileWrapper}>
        <video ref={mobileVideoRef} autoPlay playsInline={true} muted></video>
      </div>
    </>
  );
};

const mobileWrapper = css`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  video {
    width: 100%;
    height: 100%;
  }
`;
