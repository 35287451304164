/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { getTextColorByBackgroundColor } from "../../utils/color";

export const ProgressBar = ({ completed, nowPage, totalPage }) => {
  const theme = useTheme();
  const fontColor = getTextColorByBackgroundColor(theme.subColor);

  return (
    <div css={progressWrap({ fontColor, theme })}>
      <div css={progressWrapper({ completed, theme })}>
        <div className="progress-bar" />
      </div>
      <div className="label">{`${nowPage} / ${totalPage}`}</div>
    </div>
  );
};

const progressWrap = ({ theme, fontColor }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    text-align: center;
    margin: 20px 0 20px 4px;
    width: 6%;
    /* background-color: ${theme.subColor}; */
    border-radius: 10px;
    color: ${fontColor};
  }
`;
const progressWrapper = ({ theme, completed }) => css`
  width: 94%;
  height: 13px;
  text-align: right;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #d2d3d4;
  .progress-bar {
    height: 100%;
    background-color: #3b3b3b;
    border-radius: 20px;
    transition: 0.3s linear;
    width: ${completed}%;
  }
`;
