import { QRCodeCanvas } from "qrcode.react";
import { useSelector } from "react-redux";
import { PendingTemplate } from "../base/PendingTemplate";
import { baseURL } from "../../api/apiClient";

export const PendingDisconnectMobile = () => {
  const { testerIdx, roomIdx } = useSelector((state) => state.userAction.data);
  const { completer } = useSelector((state) => state.userAction);
  const encodeURL = `roomIdx=${roomIdx}&testerIdx=${testerIdx}&completer=${completer}`;

  return (
    <PendingTemplate title={"모바일 화면 연결 끊김"} zIndex={4002}>
      {`
      모바일 연결이 끊겼습니다. 
      모바일 새로고침이나 QR을 통해 재 연결 부탁드립니다.`}
      <div style={{ paddingTop: "30px" }}>
        <QRCodeCanvas
          value={`${baseURL}mobile/device?` + encodeURL}
          size={100}
        />
      </div>
    </PendingTemplate>
  );
};
