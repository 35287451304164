/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  getIsRedColor,
  getTextColorByBackgroundColor,
} from "../../utils/color";
import { validationPhoneCheck } from "../../utils/validate";
import { AuthTimer } from "../system/timer/AuthTimer";
import { useTranslation } from "react-i18next";
export const LoginTemplate = ({
  companyCode,
  confirmPhon,
  confirmId,
  isPhoneSended,
  validationMessage,
  inputChange,
  onSubmitForm,
  inputReset,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const layoutNum = companyCode ? String(theme.layoutIdx) : "1";

  const fontColor = getTextColorByBackgroundColor(theme.buttonColor);
  const IsLoginFrameColorRed = getIsRedColor(theme.frameColor);

  return (
    <Wrapper theme={theme} companyCode={companyCode}>
      {layoutNum === "1" ||
      layoutNum === "3" ||
      layoutNum === "5" ||
      layoutNum === "6" ? null : (
        <LogoOut theme={theme} companyCode={companyCode} />
      )}
      <LoginBox layoutNum={layoutNum} theme={theme} companyCode={companyCode}>
        {layoutNum === "2" ||
        layoutNum === "4" ||
        layoutNum === "7" ||
        layoutNum === "8" ? null : companyCode && theme.ciUseYN === "Y" ? (
          <LogoIn theme={theme} companyCode={companyCode} />
        ) : null}

        <Title theme={theme} companyCode={companyCode}>
          {theme.titleText && <div>{companyCode ? theme.titleText : ""}</div>}
        </Title>
        <InputWrap>
          {/* 핸드폰 */}
          {!isPhoneSended && (
            <input
              type="text"
              className="input-content"
              placeholder={t(`login.placeholder.phoneNumber`)}
              maxLength={13}
              onChange={inputChange}
              value={validationPhoneCheck(confirmPhon)}
              autoFocus
            />
          )}
          {/* 인증번호 */}
          {isPhoneSended && (
            <input
              type="text"
              className="input-content"
              placeholder={t(`login.placeholder.confirmId`)}
              maxLength={6}
              onChange={inputChange}
              value={confirmId}
              autoFocus
            />
          )}
          <AuthTimerWrap>
            <AuthTimer inputReset={inputReset} isPhoneSended={isPhoneSended} />
          </AuthTimerWrap>
        </InputWrap>
        <NoticeBox IsLoginFrameColorRed={IsLoginFrameColorRed}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${validationMessage?.replace(/\n/g, "<br/>")}`,
            }}
          />
        </NoticeBox>
        <Button
          theme={theme}
          fontColor={fontColor}
          companyCode={companyCode}
          onClick={onSubmitForm}
        >
          {isPhoneSended
            ? t(`login.button.login`)
            : t(`login.button.isSendConfirm`)}
        </Button>
      </LoginBox>
    </Wrapper>
  );
};

const NoticeBox = styled.div((props) => ({
  color: `${props.IsLoginFrameColorRed ? "#eee" : "#ff0000"}`,
  textAlign: "center",
}));

const Wrapper = styled.div((props) => ({
  width: "100vw",
  height: "100vh",
  position: "relative",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundColor: `
  ${
    props.companyCode
      ? props.theme.bgImageUseYN === "N" && props.theme.bgColor
      : null
  }`,
  backgroundImage: `${
    props.companyCode
      ? props.theme.bgImageUseYN === "Y" && `url(${props.theme.bgColor})`
      : ``
  }`,
}));

const LogoOut = styled.div((props) => ({
  position: "relative",
  zIndex: "99",
  top: "40px",
  padding: "20px",
  width: "200px",
  height: "70px",
  marginLeft: "50px",
  backgroundPosition: "left",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `${
    props.companyCode &&
    props.theme.ciUseYN === "Y" &&
    `url(${props.theme.ciFilePath})`
  }`,
}));

const LogoIn = styled.div((props) => ({
  margin: "20px",
  width: "200px",
  height: "60px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `${
    props.companyCode &&
    props.theme.ciUseYN === "Y" &&
    `url(${props.theme.ciFilePath})`
  }`,
}));

const LoginBox = styled.form((props) => ({
  position: "absolute",
  top: "0",
  bottom: "0",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 480px)": {
    width: "100%",
  },
  width: "400px",
  height: props.layoutNum === "1" || props.layoutNum === "2" ? "420px" : "100%",
  backgroundColor: `${props.companyCode ? props.theme.frameColor : "white"}`,
  borderRadius:
    props.layoutNum === "1" || props.layoutNum === "2" ? "10px" : "0px",
  left:
    props.layoutNum === "1" ||
    props.layoutNum === "2" ||
    props.layoutNum === "3" ||
    props.layoutNum === "4" ||
    props.layoutNum === "5" ||
    props.layoutNum === "7"
      ? 0
      : "",
  right:
    props.layoutNum === "1" ||
    props.layoutNum === "2" ||
    props.layoutNum === "3" ||
    props.layoutNum === "4" ||
    props.layoutNum === "6" ||
    props.layoutNum === "8"
      ? 0
      : "",
}));

const Title = styled.div((props) => ({
  fontSize: "24px",
  padding: "20px",
  textAlign: "center",
  color: `${props.companyCode ? props.theme.titleColor : "#000"}`,
  height: "auto",
}));

const InputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  input {
    width: 280px;
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }
`;

const Button = styled.button((props) => ({
  width: "280px",
  height: "40px",
  borderRadius: "8px",
  backgroundColor: `${props.companyCode ? props.theme.buttonColor : "#e9e9e9"}`,
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
  color: `${props.companyCode ? props.fontColor : "#000"}`,
}));

const AuthTimerWrap = styled.div`
  position: relative;
  bottom: 62px;
  left: 150px;
`;
