/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { BasicButton } from "../system/button/BasicButton";
import { Loading } from "../modal/Loading";
import { useEffect, useState } from "react";
import { useLogout } from "../../hooks/useLogout";
import { PendingTemplate } from "../base/PendingTemplate";
import { useTranslation } from "react-i18next";

export const PendingWebCamError = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, []);
  const { t } = useTranslation();

  const LoadingModal = () => {
    return (
      <>
        <span css={noticeText}>{t(`pending.webcam.text.loading`)}</span>
        <Loading />
      </>
    );
  };

  const DisconnectModal = () => {
    const { logout } = useLogout();
    return (
      <>
        <span css={noticeText}>{t(`pending.webcam.text.disconnect`)}</span>
        <BasicButton
          wid="s"
          hei="s"
          className="btHover"
          label={t(`exam.button.end`)}
          onClick={logout}
        />
      </>
    );
  };

  return (
    <PendingTemplate title={t(`pending.webcam.title.disconnect`)} zIndex={999}>
      <div css={contentWrap}>
        {loading ? <LoadingModal /> : <DisconnectModal />}
      </div>
    </PendingTemplate>
  );
};

const contentWrap = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
const noticeText = css`
  display: flex;
  margin-bottom: 20px;
  padding-top: 30px;
  font-size: 20px;
  word-break: break-all;
  white-space: pre;
`;
